import styled from 'styled-components';

export const Wrapper = styled.form`
  padding: 24px 32px 32px 32px;
  font-family: 'Roboto', sans-serif;
  h1 {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(0, 0, 0, 0.68);
  }
  h2 {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
    color: #254555;
  }
  h3 {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
    color: #254555;
  }

  .title {
    font-weight: 400;
  }

  .MuiSelect-select:focus {
    background-color: transparent;
  }

  .reportType {
    display: flex;
    align-items: center;
    margin: 15px 0 0;
    label {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.15000000596046448px;
      text-align: left;
      color: #254555;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .report {
    position: relative;
    margin-bottom: 25px;
  }
  .error {
    position: absolute;
    color: red;
  }
  .actions {
    display: flex;
    align-items: flex-end;

    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
      flex-direction: column;
      align-items: stretch;
    }

    button {
      &:first-child {
        margin-right: 16px;
        margin-top: 40px;

        @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
          margin: 20px 0;
        }
      }
    }
  }
`;
