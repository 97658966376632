import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import { api } from 'api';
import { axios } from 'api/api';
import { ExamCard } from 'components/ExamCard';
import { Layout } from 'components/Layout';
import { Preloader } from 'components/Preloader';
import { paths } from 'config/paths';
import { getLearner } from 'helpers/scormAPI';
import { logAppVersion } from 'helpers/utils';
import { ChartPage } from 'pages/Chart';
import { Collection } from 'pages/Collection';
import { DeckReport } from 'pages/DeckReport';
import { Error } from 'pages/Error';
import { Exam } from 'pages/Exam';
import { Fork } from 'pages/Fork';
import { Home } from 'pages/Home';
import { MockExam } from 'pages/MockExam/MockExam';
import { Quiz } from 'pages/Quiz';
import { Result } from 'pages/Result';
import { ReviewReport } from 'pages/ReviewReport';
import { StudyReviewReport } from 'pages/ReviewReport/StudyReviewReport';
import { Safmeds } from 'pages/Safmeds';

export const App: React.FC = () => {
  const [isDataComplete, setIsDataComplete] = useState(false);

  logAppVersion();

  useEffect(() => {
    api.auth
      .loginAPI(getLearner())
      .then(
        (res) => (axios.defaults.headers.common.Authorization = `Bearer ${res}`)
      )
      .then(() =>
        api.auth.getUserAPI().then((res) => {
          localStorage.setItem('fullName', `${res.firstName} ${res.lastName}`);
          localStorage.setItem('email', `${res.email}`);
          sessionStorage.setItem('role', res.type);
          setIsDataComplete(true);
        })
      )
      .catch((error) => console.error(error));
  }, []);

  return (
    <ErrorBoundary FallbackComponent={Error}>
      {isDataComplete ? (
        <BrowserRouter>
          <Switch>
            <Layout exact path={paths.quiz()} component={Quiz} />
            <Layout exact path={paths.result()} component={Result} />
            <Layout
              exact
              path={paths.reviewReport()}
              component={ReviewReport}
            />
            <Layout
              exact
              path={paths.studyReport()}
              component={StudyReviewReport}
            />
            <Layout exact path={paths.deckReport()} component={DeckReport} />
            <Layout exact path={paths.safmeds()} component={Safmeds} />
            <Layout
              isExamPage
              path={paths.safmedsExam()}
              component={ExamCard}
            />
            <Layout path={paths.safmedsCollections()} component={Collection} />
            <Layout exact path={paths.examPage()} component={Exam} />
            <Layout exact path={paths.mockExam()} component={MockExam} />
            <Layout exact path={paths.chart()} component={ChartPage} />
            <Layout exact hideNavigation path={paths.fork()} component={Fork} />
            <Layout exact hideNavigation path={paths.home()} component={Home} />
            <Layout exact hideNavigation path='*' component={Error} />
            <Redirect to={paths.home()} />
          </Switch>
        </BrowserRouter>
      ) : (
        <Preloader />
      )}
    </ErrorBoundary>
  );
};
