import { CertificationDTO } from 'interfaces/certification';

import { APIService } from './api';

export class Certification extends APIService {
  isMockedApi: boolean;

  constructor(isMockedApi: boolean = false) {
    super();
    this.isMockedApi = isMockedApi;
  }

  getCertificationsAPI(): Promise<CertificationDTO[]> {
    if (this.isMockedApi) {
      return new Promise((resolve) =>
        setTimeout(resolve, 1000, require('../mocks/certifications.json'))
      );
    }

    return this.get<CertificationDTO[]>(`/api/certification`);
  }

  async getCertificationBySlugAPI(slug: string): Promise<CertificationDTO> {
    if (this.isMockedApi) {
      return new Promise((resolve) =>
        setTimeout(resolve, 1000, require('../mocks/certification.json')[0])
      );
    }

    const data = await this.get<CertificationDTO[]>(`/api/certification`);

    const cert = data.find((cert) => cert.certificationSlug === slug);

    if (!cert) {
      throw new Error('Certification not found');
    }

    return new Promise((resolve) => resolve(cert));
  }

  getAvalibleCertificationsAPI(): Promise<CertificationDTO[]> {
    if (this.isMockedApi) {
      return new Promise((resolve) =>
        setTimeout(resolve, 1000, require('../mocks/certifications.json'))
      );
    }

    return this.get<CertificationDTO[]>(`/api/certification/all`);
  }
}
