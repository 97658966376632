import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { ReactComponent as RadioCheckedIcon } from 'assets/radio-checked.svg';
import { ReactComponent as RadioUncheckedIcon } from 'assets/radio-unchecked.svg';
import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import { QuestionFeedback } from 'components/QuestionFeedback';
import { paths } from 'config/paths';
import { useMediaQuery } from 'helpers/useMediaQuery';
import { theme } from 'theme';

import { Wrapper } from './style';

interface Props {
  id: string;
  questionsLength: number;
  number: number;
  question: string;
  certificationSlug: string;
  answers: Array<{
    id: string;
    answerText: string;
    multipleChoiceAnswer: boolean;
  }>;
  onChangeResult: (
    type: 'answer' | 'isFlagged' | 'time',
    result?: string | boolean
  ) => void;
  onFinishExam: () => void;
  result?: {
    answer: string;
    isFlagged: boolean;
  };
}

export const Question: React.FC<Props> = ({
  id,
  questionsLength,
  number,
  question,
  certificationSlug,
  answers,
  result,
  onChangeResult,
  onFinishExam
}) => {
  const [isSubmitFeedbackModalOpen, setIsSubmitFeedbackModalOpen] =
    useState<boolean>(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState<boolean>(false);

  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.mdMin})`);
  const history = useHistory();

  // @ts-ignore
  return (
    <>
      <Wrapper>
        <div className='question__info'>
          <span>
            Question <b>{number}</b> of <b>{questionsLength}</b>
          </span>
          <Button
            text='Want to submit question feedback?'
            transparent
            borderColor='transparent'
            paddingX={4}
            paddingY={3}
            onClick={() => setIsSubmitFeedbackModalOpen(true)}
          />
        </div>
        <p
          className='question__title'
          dangerouslySetInnerHTML={{ __html: question }}
        />
        {/* TODO: Debug mode */}
        <div
          style={{
            color: 'lightgray',
            textAlign: 'right'
          }}
        >
          ID: {id}
        </div>
        <div className='question__answers'>
          {answers
            .sort(
              (a, b) =>
                Number(a.multipleChoiceAnswer || 0) -
                Number(b.multipleChoiceAnswer || 0)
            )
            .map((answer) => (
              <button
                key={answer.id}
                type='button'
                className={`question__answers__answer ${
                  answer.id === result?.answer
                    ? 'question__answers__answer_result'
                    : ''
                }`}
                onClick={(event) => {
                  onChangeResult('answer', answer.id);
                  event.currentTarget.blur();
                }}
              >
                {answer.id === result?.answer ? (
                  <RadioCheckedIcon />
                ) : (
                  <RadioUncheckedIcon />
                )}

                {
                  // @ts-ignore
                  !answer.answerText && answer.answerTextwImages?.html ? (
                    <span
                      className={'question__imgAnswer'}
                      dangerouslySetInnerHTML={{
                        // @ts-ignore
                        __html: answer.answerTextwImages?.html
                      }}
                    />
                  ) : (
                    <span>
                      {answer.answerText.startsWith('[') &&
                      answer.answerText.endsWith(']')
                        ? answer.answerText.slice(1, -1)
                        : answer.answerText}
                    </span>
                  )
                }
              </button>
            ))}
        </div>
        <div className='question__actions'>
          <Button
            text={`Previous${isDesktop ? ' question' : ''}`}
            mode='prev'
            color={number === 1 ? theme.palette['light-lavender'] : undefined}
            disabled={number === 1}
            onClick={() =>
              history.push(paths.examPage(certificationSlug, `${number - 1}`))
            }
          />
          <Button
            text={`${result?.isFlagged ? 'Unflag' : 'Flag'}${
              isDesktop ? ' question' : ''
            }`}
            className='question__actions__flag-btn'
            transparent
            onClick={() => onChangeResult('isFlagged')}
          />
          <Button
            text={
              questionsLength === number
                ? 'Finish'
                : `Next${isDesktop ? ' question' : ''}`
            }
            mode={questionsLength !== number ? 'next' : undefined}
            onClick={() => {
              if (questionsLength === number) {
                onFinishExam();
              } else {
                history.push(
                  paths.examPage(certificationSlug, `${number + 1}`)
                );
              }
            }}
          />
        </div>
      </Wrapper>
      <Modal
        open={isSubmitFeedbackModalOpen}
        maxWidth='480px'
        borderRadius={4}
        hideCloseIcon={feedbackSubmitted}
        onClose={() => setIsSubmitFeedbackModalOpen(false)}
      >
        <QuestionFeedback
          onClose={() => setIsSubmitFeedbackModalOpen(false)}
          submitted={feedbackSubmitted}
          handleSetSubmited={() => setFeedbackSubmitted(true)}
          questionId={id}
          certificationSlug={certificationSlug}
        />
      </Modal>
    </>
  );
};
