import React from 'react';

import { Wrapper } from './style';

interface bigTagProps {
  text: string | number;
  color?: string;
}

export const BigTag: React.FC<bigTagProps> = ({ text, color }) => {
  return (
    <Wrapper>
      <div className='bigTag' style={{ backgroundColor: color }}>
        <p className='bigTag__text'>{text}</p>
      </div>
    </Wrapper>
  );
};
