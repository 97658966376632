import React, { useEffect, useRef } from 'react';
import { IconButton } from '@material-ui/core';
import { ReactComponent as CloseIcon } from 'assets/close.svg';
import FocusTrap from 'focus-trap-react';

import { Wrapper } from './style';

interface Props {
  open: boolean;
  width?: string;
  maxWidth: string;
  borderColor?: string;
  borderRadius?: number;
  overflowY?: boolean;
  hideCloseIcon?: boolean;
  onClose?: () => void;
}

export const Modal: React.FC<Props> = ({
  open,
  width,
  maxWidth,
  onClose,
  borderRadius,
  borderColor,
  children,
  hideCloseIcon,
  overflowY
}) => {
  const modalRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const handleClickOutside = (event: MouseEvent) => {
    if (
      !modalRef?.current?.contains(event.target as HTMLInputElement) &&
      onClose
    ) {
      onClose();
    }
  };

  const handleClose = () => {
    onClose?.();
  };

  const handleKeyClose = (e: KeyboardEvent) => {
    if (e.keyCode === 27) {
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    document.addEventListener('keydown', handleKeyClose, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
      document.removeEventListener('keydown', handleKeyClose, true);
    };
  });

  return open ? (
    <Wrapper
      width={width}
      maxWidth={maxWidth}
      borderRadius={borderRadius || 0}
      borderColor={borderColor || ''}
    >
      <FocusTrap
        focusTrapOptions={{
          allowOutsideClick: true,
          // @ts-ignore
          fallbackFocus: <div></div>
        }}
      >
        <div
          className='content'
          data-testid='modal-content'
          ref={modalRef}
          tabIndex={0}
          style={overflowY ? { overflowY: 'scroll' } : {}}
        >
          {!hideCloseIcon && (
            <IconButton
              className='close-icon'
              data-testid='modal-close'
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          )}
          {children}
        </div>
      </FocusTrap>
    </Wrapper>
  ) : (
    <></>
  );
};
