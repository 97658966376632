import { ReportRequest } from 'interfaces/report';
import { Report } from 'interfaces/result';

import { APIService } from './api';

export class Reports extends APIService {
  isMockedApi: boolean;

  constructor(isMockedApi: boolean = false) {
    super();
    this.isMockedApi = isMockedApi;
  }

  getReportAPI(id: string): Promise<Report> {
    if (this.isMockedApi) {
      return new Promise((resolve) =>
        setTimeout(resolve, 1000, require('../mocks/result.json'))
      );
    }

    return this.get<Report>(`/api/reports/${id}`);
  }

  summaryReport(body: ReportRequest): Promise<Blob> {
    if (this.isMockedApi) {
      return new Promise((resolve) =>
        setTimeout(resolve, 1000, require('../mocks/result.json'))
      );
    }

    return this.post<ReportRequest, Blob>(
      '/api/admin/attemptSummaryReport',
      body,
      {
        responseType: 'blob'
      }
    );
  }

  anylysysReport(body: ReportRequest): Promise<Blob> {
    if (this.isMockedApi) {
      return new Promise((resolve) =>
        setTimeout(resolve, 1000, require('../mocks/result.json'))
      );
    }

    return this.post<ReportRequest, Blob>(
      '/api/admin/itemAnalysisReport',
      body,
      {
        responseType: 'blob'
      }
    );
  }
}
