import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  .exam-card {
    width: 416px;
    padding: 24px 32px;
    position: relative;
    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
      width: 100%;
      padding: 0;
    }

    &__title {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      margin: 0;
      margin-bottom: 28px;

      @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        font-size: 20px;
      }
    }
    &__timer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 18px;
    }
    &__card {
      height: 544px;
      width: 416px;
      border-radius: 8px;
      border: 1px solid ${(props) => props.theme.palette['soft-gray']};
      &__main-body {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
      }
    }
    &__shortcuts {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      margin-top: 24%;
      position: absolute;
      left: 0;
      bottom: -32px;
      @media (max-width: ${(props) => props.theme.breakpoints.mdMin}) {
        display: none;
      }
      &__tooltip {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 8px 0px;
        position: relative;
        z-index: 100;
        bottom: 16px;
        width: 156px;
        background: ${(props) => props.theme.palette.white};
        box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
          0px 8px 10px 1px rgba(0, 0, 0, 0.14),
          0px 3px 14px 2px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        &__title {
          font-family: 'Roboto';
          font-weight: 500;
          font-size: 16px;
          padding: 10px 16px;
        }
        &__action {
          padding: 10px 16px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          height: 24px;
          width: 116px;
          &__button {
            background-color: ${(props) => props.theme.palette.background};
            border: none;
            height: 24px;
            // margin-left: 32px;
            border-radius: 1000px;
            padding: 0 7px;
            text-transform: none;
            .next-arr,
            .prev-arr {
              width: 12px;
              height: 12px;
              margin: 0;
              fill: ${(props) => props.theme.palette['dark-cr-gray']};
              path {
                fill: ${(props) => props.theme.palette['dark-cr-gray']};
              }
            }
          }
        }
      }
      &__keyboard {
        height: 32px;
        width: 184px;
        border-radius: 1000px;
        text-transform: none;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 7px 17px;
        &:hover,
        &:active,
        &:focus {
          background: rgb(243, 243, 243) !important;
          background-color: rgb(243, 243, 243) !important;
          color: rgb(37, 69, 85) !important;
        }
        svg {
          position: relative;
          left: -5%;
          width: 26px;
          height: 18px;
        }
        &__text {
          font-family: Roboto;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.14px;
          width: 154px;
        }
      }
    }
  }
`;
