import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 37px;

  .question {
    &__imgAnswer {
      img {
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 330px;
      }
    }
    &__info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 17px;

      @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        flex-direction: column;
        align-items: flex-start;
      }

      span {
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.15px;
      }

      button {
        font-size: 13px;
        line-height: 22px;
        letter-spacing: 0.46px;
        text-decoration-line: underline;

        @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
          margin-left: -4px;
        }

        &:hover {
          text-decoration-line: unset;
        }
      }
    }

    &__title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 16px;

      img {
        width: 100%;
        height: auto;
      }
    }

    &__answers {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid ${(props) => props.theme.palette['soft-gray']};
      padding-bottom: 36px;
      margin-bottom: 24px;

      &__answer {
        display: flex;
        align-items: center;
        padding: 27px;
        border: 1px solid ${(props) => props.theme.palette['soft-gray']};
        border-radius: 8px;
        margin-bottom: 4px;
        background-position: center;
        transition: background 0.6s, border-color 0.6s, color 0.6s;

        &_result {
          background-color: ${(props) =>
            props.theme.palette['light-lavender-opacity']};
          border-color: ${(props) =>
            props.theme.palette['light-lavender-opacity']};
        }

        &:hover,
        &:focus {
          background: ${(props) => props.theme.palette['dark-lavender-opacity']}
            radial-gradient(
              circle,
              transparent 1%,
              ${(props) => props.theme.palette['dark-lavender-opacity']} 1%
            )
            center/15000%;
        }

        &:active {
          background-color: 'transparent';
          background-size: 100%;
          transition: background 0s;
        }

        span {
          font-weight: 400;
          margin-left: 11px;
          font-size: 16px;
          line-height: 28px;
          letter-spacing: 0.15px;
          width: 100%;
          text-align: left;
        }
      }
    }

    &__actions {
      display: flex;
      justify-content: space-between;

      &__flag-btn {
        margin: 0 16px;
      }

      button {
        flex: 1;
        justify-content: center;

        @media (max-width: ${(props) => props.theme.breakpoints.md}) {
          padding: 7px 2px;
        }

        svg {
          @media (max-width: ${(props) => props.theme.breakpoints.md}) {
            display: none;
          }
        }
      }
    }
  }
`;
