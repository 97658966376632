import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { api } from 'api/index';
import { Assessment } from 'components/Assessment';
import { ChartCard } from 'components/ChartCard';
import { DeckCard } from 'components/DeckCard';
import { EmptyCard } from 'components/EmptyCard';
import { HeadingWithInput } from 'components/HeadingWithInput';
import { HeadingWithTag } from 'components/HeadingWithTag';
import { Preloader } from 'components/Preloader';
import { paths } from 'config/paths';
import { ChartCardDTO } from 'interfaces/chartCard';
import { DataGetOrganizationDto } from 'interfaces/graphOrganization';

import { theme } from '../../theme';
import { ChartsWrapper, Wrapper } from './style';

const chartLength = 2;

type ChartCardEditDTO = {
  editable?: boolean;
} & ChartCardDTO;

export const Safmeds: React.FC = () => {
  const [isPreloaderActive, setIsPreloaderActive] = useState<any>(true);
  const [organization, setOrganization] = useState<DataGetOrganizationDto>();
  const [collections, setCollections] = useState<
    DataGetOrganizationDto['collections'] | null
  >(null);
  const [charts, setCharts] = useState<ChartCardEditDTO[] | null>(null);
  const [search, setSearch] = useState<string>('');
  const [currentlyPlaying, setCurrentlyPlaying] = useState<string | null>(null);

  const handleSearch = (searchWord: string) => {
    const filtered = organization?.collections?.filter(function ({
      collectionName
    }) {
      return (
        collectionName.toLowerCase().indexOf(searchWord.toLowerCase()) >= 0
      );
    });
    setCollections(filtered || null);
  };

  const handleChange = (value: string) => {
    setSearch(value);
  };

  const handleCreate = (
    value: string = `Chart ${Number(charts?.length) + 1}`
  ) => {
    api.charts.postChartApi({ name: value }).then((res) => {
      setCharts([...(charts || []), { ...res, editable: true }]);
    });
  };

  const handleSubmit = (id: string, value: string) => {
    api.charts.putChartApi({ id, name: value }).then((res) => {
      setCharts((charts) =>
        !charts
          ? null
          : charts.map((chart) => {
              if (chart._id === id) {
                return { ...chart, name: value };
              }
              return chart;
            })
      );
    });
  };

  useEffect(() => {
    api.org
      .getOrganization()
      .then((organizations) => {
        setIsPreloaderActive(true);
        setOrganization(organizations);
        setCollections(organizations?.collections || null);
        if (organizations.settings.showProgressCharts) {
          api.charts.getChartsApi().then((charts) => {
            setCharts(charts);
          });
        }
      })
      .then((res) => setIsPreloaderActive(false))
      .catch((e) => console.error(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isPreloaderActive ? (
        <Wrapper>
          {organization?.collections?.length ? (
            <div>
              {organization.recent?.length ? (
                <>
                  <HeadingWithTag
                    tagColor='#2A47B1'
                    title='Recently opened'
                    tagText={
                      organization && organization?.recent?.length > 0
                        ? organization?.recent?.length.toString()
                        : '0'
                    }
                  />
                  <div className='safmeds__decks'>
                    {organization &&
                      organization?.recent.length > 0 &&
                      organization.recent?.map((deck) => (
                        <div key={deck.id} className='safmeds__deck--wrapper'>
                          <div className='safmeds__deck--link'>
                            <Link
                              to={paths.safmedsCollections(
                                deck.collectionId,
                                deck.sectionId
                              )}
                              {...{ name: 'section', id: 'section' }}
                            >
                              {deck.sectionName}
                            </Link>{' '}
                            from{' '}
                            <Link
                              to={paths.safmedsCollections(
                                deck.collectionId,
                                ''
                              )}
                              {...{ name: 'collection', id: 'collection' }}
                            >
                              {deck.collectionName}
                            </Link>
                          </div>
                          <DeckCard
                            backgroundColor={
                              deck.type === 'Acquisition'
                                ? theme.palette['aquisition-background']
                                : theme.palette['fluency-background']
                            }
                            cardsQty={deck.deckCardsNumber}
                            tagColor={
                              deck.type === 'Acquisition'
                                ? theme.palette.aquisition
                                : theme.palette.fluency
                            }
                            tagText={deck.type}
                            title={deck.name || ''}
                            deckId={deck.id}
                            sectionId={deck.sectionId}
                            collectionId={deck?.collectionId || ''}
                          />
                        </div>
                      ))}
                  </div>
                </>
              ) : (
                <></>
              )}

              {organization.settings.showProgressCharts && (
                <>
                  <HeadingWithTag title='Progress Charts' tagText={null} />

                  <ChartsWrapper>
                    {charts?.map((card) => (
                      <ChartCard
                        handleSubmit={handleSubmit}
                        key={card._id}
                        {...card}
                      />
                    ))}
                    {Array.from({
                      length:
                        charts?.length !== chartLength
                          ? chartLength - Number(charts?.length)
                          : 0
                    }).map((_, i) => (
                      <EmptyCard key={i} handleClick={() => handleCreate()} />
                    ))}
                  </ChartsWrapper>
                </>
              )}

              <HeadingWithInput
                tagColor='#2A47B1'
                title='Collections'
                tagText={organization?.collections.length.toString() || '0'}
                search={search}
                onChange={handleChange}
                onSubmit={handleSearch}
              />

              {collections && collections?.length
                ? collections?.map((coll) => (
                    <Assessment.Collection
                      key={coll.id}
                      title={coll.collectionName}
                      id={coll.collectionId}
                      currentlyPlaying={currentlyPlaying}
                      handleSetPlaying={setCurrentlyPlaying}
                      teaserDescription={coll.collectionTeaserDescription}
                      teaserVideo={coll.collectionTeaserVideo}
                    />
                  ))
                : 'Nothing found'}
            </div>
          ) : (
            <div className='welcome' style={{ textAlign: 'center' }}>
              No decks found. Please contact your administrator.
            </div>
          )}
        </Wrapper>
      ) : (
        <Preloader />
      )}
    </>
  );
};
