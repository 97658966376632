import { Condition } from 'interfaces/condition';

import { convertTimeToCSTFormat } from './convertTimeToCSTFormat';
import { getTrendLine } from './getChartTrendLine';
import { getNumberOfDayFromDate } from './getNumberOfDayFromDate';
import { getTrendData } from './getTrendData';

export const getListOfTrends = (
  start: Date,
  data: Array<{ y: number; x: number }>,
  conditions: Condition[],
  color: string
) => {
  const conditionsDates: Date[] = [
    start,
    ...conditions.map((c) => new Date(c.dateTime))
  ].sort(
    (a, b) =>
      new Date(convertTimeToCSTFormat(a.toDateString(), true)).getTime() -
      new Date(convertTimeToCSTFormat(b.toDateString(), true)).getTime()
  );
  const correctDates: Date[] = data.map((c) => new Date(c.x));

  const listSubArr: any = [];
  const list: any = [];

  let arrNum = 0;

  for (let i = 0; i < conditionsDates.length; i++) {
    for (let j = 0; j < correctDates.length; j++) {
      if (
        (correctDates[j].getTime() >= conditionsDates[i].getTime() &&
          !conditionsDates[i + 1]) ||
        (correctDates[j].getTime() >= conditionsDates[i].getTime() &&
          correctDates[j].getTime() < conditionsDates[i + 1]?.getTime())
      ) {
        if (listSubArr[arrNum]) {
          listSubArr[arrNum].push(data[j]);
        } else {
          listSubArr.push([data[j]]);
        }
      }
    }
    if (listSubArr.length) {
      arrNum += 1;
    }
  }

  // eslint-disable-next-line array-callback-return
  listSubArr.map((el: any) => {
    if (el.length > 2) {
      list.push(
        getTrendLine({
          color: color,
          data: getTrendData(
            el.map((c: any) => ({
              x: getNumberOfDayFromDate(new Date(el[0].x), new Date(c.x)),
              y: c.y
            })),
            getNumberOfDayFromDate(new Date(el[0].x), new Date(el[0].x)),
            getNumberOfDayFromDate(
              new Date(el[0].x),
              new Date(el[el.length - 1].x)
            ),
            el[0].x,
            el[el.length - 1].x
          )
        })
      );
    }
  });

  return list;
};

export const getListOfTrendsWithoutConditions = (
  data: Array<{ y: number; x: number }>,
  color: string
) => {
  return getTrendLine({
    color: color,
    data: getTrendData(
      data,
      data[0].x,
      data[data.length - 1].x,
      data[0].x,
      data[data.length - 1].x
    )
  });
};
