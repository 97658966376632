import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'components/Button';

import { ReactComponent as RefreshIcon } from '../../assets/refresh.svg';
import { Wrapper } from './style';

interface Props {
  flipped: boolean;
  correctEnabled: boolean;
  incorrectEnabled: boolean;
  skipEnabled: boolean;
  setSkipEnabled: any;
  setCorrectEnabled: any;
  setIncorrectEnabled: any;
  handleFlip: (bool: boolean) => void;
  handleCardAction: (actionType: string, answerId: string) => void;
  cardData: any;
  isStudyMode: boolean;
  isModalOpen: boolean;
}

export const FlipCard: React.FC<Props> = ({
  flipped,
  handleFlip,
  handleCardAction,
  cardData,
  correctEnabled,
  incorrectEnabled,
  skipEnabled,
  isStudyMode,
  setSkipEnabled,
  setCorrectEnabled,
  setIncorrectEnabled,
  isModalOpen
}) => {
  const [backText, setBackText] = useState<string>('');
  const [backFlip, setBackFlip] = useState(false);

  const handleFrontCardKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (isModalOpen) {
        return;
      }
      switch (e.code) {
        case 'ArrowRight':
          setSkipEnabled(false);
          handleCardAction('skip', cardData.id);
          setSkipEnabled(true);
          break;
        case 'Space':
          e.preventDefault();
          handleFlip(!flipped);
          setBackFlip(false);
          break;
        default:
          break;
      }
    },
    [
      isModalOpen,
      setSkipEnabled,
      handleCardAction,
      cardData.id,
      handleFlip,
      flipped
    ]
  );
  const handleBackCardKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (isModalOpen) {
        return;
      }
      switch (e.code) {
        case 'ArrowRight':
          setCorrectEnabled(false);
          handleCardAction('correct', cardData.id);
          setCorrectEnabled(true);
          break;
        case 'ArrowLeft':
          setIncorrectEnabled(false);
          handleCardAction('incorrect', cardData.id);
          setIncorrectEnabled(true);
          break;
        case 'Space':
          e.preventDefault();
          if (isStudyMode) {
            handleFlip(!flipped);
            setBackFlip(true);
          }
          break;
        default:
          break;
      }
    },
    [
      isModalOpen,
      setCorrectEnabled,
      handleCardAction,
      cardData.id,
      setIncorrectEnabled,
      isStudyMode,
      handleFlip,
      flipped
    ]
  );
  useEffect(() => {
    if (flipped) {
      document.addEventListener('keydown', handleBackCardKeyPress);
      document.removeEventListener('keydown', handleFrontCardKeyPress);
    } else {
      document.addEventListener('keydown', handleFrontCardKeyPress);
      document.removeEventListener('keydown', handleBackCardKeyPress);
    }
    return () => {
      document.removeEventListener('keydown', handleFrontCardKeyPress);
      document.removeEventListener('keydown', handleBackCardKeyPress);
    };
  }, [
    flipped,
    handleBackCardKeyPress,
    handleFrontCardKeyPress,
    handleCardAction
  ]);

  // Prevent display answer berore end of animation
  useEffect(() => {
    setTimeout(() => {
      setBackText(cardData?.response?.html);
    }, 250);
  }, [cardData]);

  return (
    <Wrapper>
      <div className='flip-card-3D-wrapper'>
        <div
          id='flip-card'
          className={backFlip ? 'back-flip' : flipped ? 'do-flip' : ''}
        >
          <div className='flip-card-front' id='flip-card-front'>
            <h1
              className='flip-card-front__text'
              dangerouslySetInnerHTML={{ __html: cardData?.stimulus?.html }}
            ></h1>
            <div className='flip-card-front__buttons'>
              <Button
                disabled={flipped}
                color='primary'
                className='exam-card__card__main-body__buttons__flip'
                id='flip-card-btn-turn-to-back'
                text=''
                onClick={() => {
                  handleFlip(true);
                  setBackFlip(false);
                }}
              >
                <div className='h-22'>
                  Flip
                  <RefreshIcon />
                </div>
              </Button>
              <Button
                disabled={!skipEnabled || flipped}
                text='Skip'
                transparent
                onClick={() => handleCardAction('skip', cardData.id)}
              />
            </div>
          </div>
          <div id='flip-card-back' className='flip-card-back'>
            <h1
              className='flip-card-back__text'
              dangerouslySetInnerHTML={{ __html: backText }}
            ></h1>

            <div className='flip-card-back__buttons'>
              {isStudyMode && (
                <Button
                  disabled={!flipped}
                  color='primary'
                  className='flip-card-back__buttons__flip'
                  id='flip-card-btn-turn-to-back'
                  text=''
                  onClick={() => {
                    handleFlip(false);
                    setBackFlip(true);
                  }}
                >
                  <div className='h-22'>
                    Flip
                    <RefreshIcon />
                  </div>
                </Button>
              )}
              <Button
                disabled={!incorrectEnabled || !flipped}
                text='Incorrect'
                transparent
                className='flip-card-back__buttons__incorrect'
                onClick={() => handleCardAction('incorrect', cardData.id)}
              />
              <Button
                disabled={!correctEnabled || !flipped}
                text='Correct'
                transparent
                className='flip-card-back__buttons__correct'
                onClick={() => handleCardAction('correct', cardData.id)}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
