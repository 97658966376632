import React, { useState } from 'react';
import { api } from 'api/index';
import { Button } from 'components/Button';
import { Preloader } from 'components/Preloader';
import { TextArea } from 'components/TextArea';
import { theme } from 'theme';

import { Wrapper } from './style';

interface Props {
  questionId: string;
  submitted: boolean;
  handleSetSubmited: () => void;
  onClose: () => void;
  certificationSlug: string;
}

export const QuestionFeedback: React.FC<Props> = ({
  onClose,
  questionId,
  certificationSlug,
  handleSetSubmited,
  submitted
}) => {
  const [isPreloaderOpen, setIsPreloaderOpen] = useState<boolean>(false);
  const [feedbackText, setFeedbackText] = useState<string>('');

  const handleSubmit = () => {
    setIsPreloaderOpen(true);

    api.feedback
      .sendFeedbackAPI({
        questionId,
        certificationSlug,
        feedback: feedbackText
      })
      .then((res) => {
        if (res) {
          handleSetSubmited();
          setIsPreloaderOpen(false);
        } else {
          setIsPreloaderOpen(false);
        }
      });
  };

  return (
    <>
      <Wrapper>
        {!submitted ? (
          <>
            <p className='title'>Submit question feedback</p>
            <p className='description'>
              Use this form to submit your feedback and questions
            </p>
            <TextArea
              label='Question feedback'
              rows={6}
              getValue={(value) => setFeedbackText(value)}
            />
            <div className='actions'>
              <Button
                text='Submit'
                disabled={!feedbackText}
                color={
                  !feedbackText ? theme.palette['light-lavender'] : undefined
                }
                onClick={handleSubmit}
              />
              <Button text='Cancel' transparent onClick={onClose} />
            </div>
          </>
        ) : (
          <div className='success-msg'>
            <p>Your feedback has been successfully submitted</p>
            <Button text='Close' transparent onClick={onClose} />
          </div>
        )}
      </Wrapper>
      {isPreloaderOpen && <Preloader />}
    </>
  );
};
