import React from 'react';

import { Wrapper } from './style';

interface Props {
  label: string;
  rows: number;
  getValue: (value: string) => void;
}

export const TextArea: React.FC<Props> = ({ label, rows, getValue }) => {
  return (
    <Wrapper>
      <textarea
        rows={rows}
        placeholder={label}
        onChange={(event) => getValue(event.target.value)}
        data-testid='feedback-textarea'
      ></textarea>
      <label>{label}</label>
    </Wrapper>
  );
};
