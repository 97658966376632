import styled from 'styled-components';

export const Wrapper = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 100%;
  }
  .deck-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    width: 240px;
    min-height: 108px;
    border-radius: 8px;
    margin-right: 32px;
    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
      width: auto;
      margin-right: 0;
    }
    cursor: pointer;
    padding: 4px 10px 13px 16px;
    &__upperblock {
      font-family: Roboto, sans-serif;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      &__text {
        text-align: start;
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: 0.15px;
      }
    }
    &__lowerblock {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px 0 0;
      width: 100%;

      .tag {
        margin-left: 0;
      }
    }
  }
`;
