import { FC } from 'react';
import { IconButton } from '@material-ui/core';
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import { ReactComponent as EditIcon } from 'assets/edit.svg';
import { Measurement } from 'interfaces/measurment';
import moment from 'moment';

import { TRow } from './style';

type Props = {
  item: Measurement;
  handleEdit: () => void;
  handleDelete: () => void;
};

export const TableRow: FC<Props> = ({ item, handleEdit, handleDelete }) => {
  function fmtMSS(s: number) {
    return new Date(s * 1000).toISOString().substr(11, 8);
  }
  return (
    <TRow type='measurment'>
      <td>{item.correct}</td>
      <td>{item.incorrect}</td>
      <td>{item.custom}</td>
      <td>{fmtMSS(item.countingTime)}</td>
      <td>{moment(new Date(item.dateTime)).format('MM/DD/YYYY hh:mm A')}</td>
      <td>
        <IconButton name='edit' aria-label='edit' onClick={handleEdit}>
          <EditIcon />
        </IconButton>
        <IconButton name='delete' aria-label='delete' onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </td>
    </TRow>
  );
};
