import React from 'react';
import { useHistory } from 'react-router';
import { Button } from 'components/Button';
import { paths } from 'config/paths';
import { Questions } from 'interfaces/questions';
import { theme } from 'theme';

import { Wrapper } from './style';

interface Props {
  questions: Questions['randomQuestions'];
  certificationSlug: string;
  current: number;
  modal?: boolean;
  onSelect?: () => void;
}

export const QuestionPiker: React.FC<Props> = ({
  questions,
  certificationSlug,
  current,
  modal,
  onSelect
}) => {
  const history = useHistory();

  return (
    <Wrapper>
      {modal && (
        <div className='legend'>
          <span>Legend:</span>
          <div className='legend__info'>
            <div className='legend__info__example legend__info__example_completed' />
            <span>Completed</span>
          </div>
          <div className='legend__info'>
            <div className='legend__info__example legend__info__example_flagged' />
            <span>Flagged</span>
          </div>
          <div className='legend__info'>
            <div className='legend__info__example legend__info__example_not-completed' />
            <span>Not completed</span>
          </div>
          <div className='legend__info'>
            <div className='legend__info__example legend__info__example_current' />
            <span>Currently selected</span>
          </div>
        </div>
      )}
      <div className={`questions ${modal ? 'modal' : ''}`}>
        {questions.map((question) => {
          return (
            <Button
              key={question.number}
              text={String(question.number)}
              color={
                question.number === current
                  ? question?.result.isFlagged
                    ? theme.palette['dark-cr-gold']
                    : theme.palette['dark-indigo-medium']
                  : question?.result.answer
                  ? theme.palette['light-lavender-opacity-plain']
                  : question?.result.isFlagged
                  ? theme.palette['dark-cr-gold']
                  : theme.palette['dark-indigo-medium']
              }
              transparent={
                !question?.result.answer && question.number !== current
              }
              borderColor={
                question?.result.isFlagged
                  ? theme.palette['dark-cr-gold']
                  : undefined
              }
              textColor={
                question.number === current
                  ? theme.palette.white
                  : question?.result.answer
                  ? theme.palette['dark-cr-gray']
                  : undefined
              }
              paddingX={1}
              disabled={question.number === current}
              onClick={() => {
                if (onSelect) onSelect();
                history.push(
                  paths.examPage(certificationSlug, `${question.number}`)
                );
              }}
            />
          );
        })}
      </div>
    </Wrapper>
  );
};
