import axiosBase, { AxiosRequestConfig } from 'axios';

const url =
  process.env.REACT_APP_BACKEND_URL ||
  'https://api.kb.dev.institute.centralreach.com/';

export const defaultOptions = {
  baseURL: url,
  headers: {
    Accept: 'application/json'
  }
};

export const axios = axiosBase.create(defaultOptions);

axios.interceptors.response.use(
  (response: any) => {
    return response.data;
  },
  (error: any) => {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      document.body.innerHTML = 'You don’t have permission to access this page';
    }
    return error.response;
  }
);

class HTTP {
  get<Response>(url: string, config?: AxiosRequestConfig) {
    return axios
      .get(url, config)
      .then((res) => this.handleSuccess<Response>(res))
      .catch(this.handleError);
  }

  post<Request, Response>(
    url: string,
    body: Request,
    config?: AxiosRequestConfig
  ) {
    return axios
      .post(url, body, config)
      .then((res) => this.handleSuccess<Response>(res))
      .catch(this.handleError);
  }

  put<Request, Response>(
    url: string,
    body: Request,
    config?: AxiosRequestConfig
  ) {
    return axios
      .put(url, body, config)
      .then((res) => this.handleSuccess<Response>(res))
      .catch(this.handleError);
  }

  delete<Response>(url: string, config?: AxiosRequestConfig) {
    return axios
      .delete(url, config)
      .then((res) => this.handleSuccess<Response>(res))
      .catch(this.handleError);
  }

  patch<Request, Response>(
    url: string,
    body: Request,
    config?: AxiosRequestConfig
  ) {
    return axios
      .patch(url, body, config)
      .then((res) => this.handleSuccess<Response>(res))
      .catch(this.handleError);
  }

  handleSuccess<Response>(response: any): Response {
    if (!!response.data) return response.data;

    return response;
  }

  handleError(error: any) {
    if (error.response) {
      throw error.response;
    } else if (error.request) {
      throw error.request;
    }
    return Promise.reject(error);
  }
}

export const http = new HTTP();

export class APIService {
  constructor(public baseConfig?: any) {}

  get<Response>(path: string, params?: object, config?: object) {
    return http.get<Response>(`${path}`, {
      ...this.baseConfig,
      params: params ? params : {},
      ...config
    });
  }

  post<Request, Response>(path: string, body: Request, config?: object) {
    return http.post<Request, Response>(`${path}`, body, {
      ...this.baseConfig,
      ...config
    });
  }

  put<Request, Response>(path: string, body?: any, config?: object) {
    return http.put<Request, Response>(`${path}`, body, {
      ...this.baseConfig,
      ...config
    });
  }

  patch<Request, Response>(path: string, body?: any, config?: object) {
    return http.patch<Request, Response>(`${path}`, body, {
      ...this.baseConfig,
      ...config
    });
  }

  delete<Response>(path: string, config?: object) {
    return http.delete<Response>(`${path}`, {
      ...this.baseConfig,
      ...config
    });
  }
}
