import { Condition } from 'interfaces/condition';

import { APIService } from './api';

export type PostConditionAPIParams = {
  chartId: string;
  title: string;
  type: string;
  description: string;
  dateTime: string;
};

export type PutConditionAPIParams = {
  title: string;
  type: string;
  description: string;
  dateTime: string;
};

export class Conditions extends APIService {
  isMockedApi: boolean;

  constructor(isMockedApi: boolean = false) {
    super();
    this.isMockedApi = isMockedApi;
  }

  postConditionApi(body: PostConditionAPIParams): Promise<Condition> {
    if (this.isMockedApi) {
      return new Promise((resolve) =>
        setTimeout(resolve, 1000, require('../mocks/conditions.json')[0])
      );
    }

    return this.post<PostConditionAPIParams, Condition>(`/api/condition`, body);
  }

  putConditionApi(id: string, body: PutConditionAPIParams): Promise<Condition> {
    if (this.isMockedApi) {
      return new Promise((resolve) =>
        setTimeout(resolve, 1000, require('../mocks/conditions.json')[1])
      );
    }

    return this.put<PutConditionAPIParams, Condition>(
      `/api/condition/${id}`,
      body
    );
  }

  deleteConditionApi(id: string): Promise<any> {
    if (this.isMockedApi) {
      return new Promise((resolve) => setTimeout(resolve, 1000, undefined));
    }

    return this.delete<any>(`/api/condition/${id}`);
  }
}
