import styled from 'styled-components';

export const Wrap = styled.div`
  padding: 32px;
  max-width: 480px;

  .modal {
    &__title {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      margin-bottom: 8px;
    }
    &__description {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      margin-bottom: 16px;
    }
    &__content-item {
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
    }

    &__buttons {
      display: flex;
      margin-top: 16px;

      button:first-child {
        margin-right: 10px;
      }
    }
  }

  .content-item--icon {
    margin-right: 10px;
  }
`;
