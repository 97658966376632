import React, { useContext, useEffect, useState } from 'react';
import { DeckCtx } from 'components/Layout/Layout';

import { Wrapper } from './style';

interface Props {
  isStudyMode: boolean;
  seconds: number;
  time: number;
  onTimeIsUp: () => void;
  setStudyTime: (time: number) => void;
}

export const FlipCardTimer: React.FC<Props> = ({
  isStudyMode,
  seconds,
  time,
  onTimeIsUp,
  setStudyTime
}) => {
  const deckContext = useContext(DeckCtx);
  const [timeLeft, setTimeLeft] = useState<number>(seconds);

  useEffect(() => {
    if (deckContext?.context.pause) {
      return;
    }

    if (!timeLeft) {
      onTimeIsUp();
      return;
    }

    const interval = setInterval(() => {
      if (isStudyMode) {
        setStudyTime(time + 1);
      } else {
        setTimeLeft(timeLeft - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [timeLeft, time, deckContext?.context.pause]);

  return (
    <Wrapper timeAlmostUp={timeLeft < 6}>
      {isStudyMode ? `Study mode` : `${timeLeft} seconds`}
    </Wrapper>
  );
};
