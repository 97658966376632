import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from 'components/Button';
import { paths } from 'config/paths';
import { CreateSafmedsAttemptDto } from 'interfaces/attempts';

import { StudyWrapper } from './style';

interface StudyReviewReportProps {}
interface LocationState {
  res: CreateSafmedsAttemptDto;
}

export const StudyReviewReport: React.FC<StudyReviewReportProps> = () => {
  const history = useHistory();
  const { res } = useLocation<LocationState>().state;
  return (
    <StudyWrapper>
      <div className='result'>
        <h1 className='result--title'>
          Well done! You have finished the study session.
        </h1>
        <div className='result__buttons'>
          <Button
            className='result__buttons--button'
            text='Repeat this study session'
            onClick={() => history.goBack()}
          />
          <Button
            className='result__buttons--button'
            text='Done'
            onClick={() =>
              history.push(
                paths.safmedsCollections(res.collectionId, res.sectionId)
              )
            }
          ></Button>
        </div>
      </div>
    </StudyWrapper>
  );
};
