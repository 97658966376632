import styled from 'styled-components';

export const Wrapper = styled.div`
  .welcome {
    margin-bottom: 24px;
    margin-top: 52px;
    padding-right: 40px;

    @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
      flex-direction: column;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.md}) {
      padding-right: 0;
    }
  }
  .safmeds {
    &__decks {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
      width: 100%;
      margin-right: -32px;
      margin-bottom: 40px;
      @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        margin-right: 0;
      }
      @media (max-width: ${(props) => props.theme.breakpoints.md}) {
        grid-template-columns: 100%;
      }
      @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (min-width: ${(props) => props.theme.breakpoints.xxl}) {
        grid-template-columns: repeat(4, 1fr);
      }
      .deck-card {
        width: 100%;
      }
      .deck-card__upperblock__text {
        width: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
    &__deck--wrapper {
      display: flex;
      flex-direction: column;
    }
    &__deck--link {
      width: 100%;
      margin-bottom: 8px;
      padding-left: 2px;
      box-sizing: border-box;
      line-height: 25px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    &__deck--link a {
      color: #3f51b5;
    }
  }
`;

export const ChartsWrapper = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 40px;
  height: auto;
  align-items: flex-start;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
