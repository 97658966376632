type AimLine = {
  min: number;
  max: number;
};

export const getAimLine = ({ min, max }: AimLine) => [
  {
    type: 'box',
    yMin: min,
    yMax: max,
    backgroundColor: 'rgba(250,238,198,0.4)',
    borderColor: 'transparent',
    borderWidth: 0,
    drawTime: 'beforeDatasetsDraw'
  },
  {
    type: 'line',
    /* yMin/yMax for the aim line will come from the graphCMS data model, this can vary based on the deck type or organization */
    yMin: min,
    yMax: min,
    borderColor: '#EAB305',
    borderWidth: 3,
    drawTime: 'beforeDatasetsDraw'
  },
  {
    type: 'line',
    /* yMin/yMax for the aim line will come from the graphCMS data model, this can vary based on the deck type or organization */
    yMin: max,
    yMax: max,
    borderColor: '#F4D678',
    borderWidth: 1,
    drawTime: 'beforeDatasetsDraw'
  }
];
