import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { Box, Slider, Switch } from '@material-ui/core';
import { api } from 'api/index';
import { ReactComponent as StudyIcon } from 'assets/study.svg';
import { ReactComponent as TimingIcon } from 'assets/timing.svg';
import { Button } from 'components/Button';
import { DeckCtx } from 'components/Layout/Layout';
import { Preloader } from 'components/Preloader';
import { paths } from 'config/paths';
import { ResponseDeckDTO } from 'interfaces/decks';
import { theme } from 'theme';

import { marks } from './constants';
import { Wrapper } from './style';
import { getSliderValueText } from './utils';
interface Props {
  deckName: string;
  deckId: string;
  sectionId: string;
  collectionId: string;
  onClose?: () => void;
  startDisabled: boolean;
}

export const DeckCardPopup: React.FC<Props> = ({
  deckName,
  deckId,
  sectionId,
  collectionId,
  onClose = () => {},
  startDisabled = true
}) => {
  const deckContext = useContext(DeckCtx);

  const [isPreloaderActive, setIsPreloaderActive] = useState<boolean>(true);
  const [showErrorCards, setShowErrorCards] = useState<boolean>(false);
  const [isStudyMode, setIsStudyMode] = useState<boolean>(false);
  const [sliderValue, setSliderValue] = useState<number>(10);
  const history = useHistory();
  const [deck, setDeck] = useState<ResponseDeckDTO>();
  const [errDeck, setErrDeck] = useState<any>();

  useEffect(() => {
    api.deck
      .getGraphDeckById({ id: deckId, collectionId, sectionId })
      .then((dck) => {
        setDeck(dck);
        setIsPreloaderActive(false);
      })
      .catch((e) => {
        console.error(e);
        setIsPreloaderActive(false);
      });
  }, [deckId, sectionId, collectionId]);
  // Error deck case below \/
  useEffect(() => {
    if (showErrorCards) {
      api.deck
        .getErrorDeckById({ id: deckId, collectionId, sectionId })
        .then((dck) => setErrDeck(dck))
        .catch((e) => console.error(e));
    }
  }, [showErrorCards, collectionId, sectionId, deckId]);

  const handleSetMode = (mode: boolean) => () => setIsStudyMode(mode);

  const handleStart = () => {
    onClose();
    deckContext?.setContext({
      ...deckContext.context,
      deck: showErrorCards ? errDeck : deck,
      errorMode: showErrorCards,
      isStudyMode
    });
    history.push(paths.safmedsExam(collectionId), {
      isStudyMode,
      sliderValue,
      showErrorCards,
      sectionId: deck?.sectionId,
      sectionName: deck?.sectionName,
      collectionId: deck?.collectionId,
      collectionName: deck?.collectionName,
      deckCardsNumber: errDeck?.deckCards?.length,
      // Error deck case below \/
      deck: showErrorCards ? errDeck : deck
    });
  };
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.code === 'Enter' && e.key === 'Enter') {
      handleStart();
    }
  };
  const cardsQty = useMemo(
    () =>
      showErrorCards && errDeck?.deckCards.length
        ? errDeck?.deckCards.length
        : deck?.deckCardsNumber,
    [deck?.deckCardsNumber, errDeck?.deckCards.length, showErrorCards]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deck, showErrorCards, errDeck, isStudyMode, deckContext, sliderValue]);

  if (!deck) {
    return <Preloader />;
  }

  return (
    <Wrapper>
      <h2 className='deck-card__popup__title'>{deckName}</h2>
      <p className='deck-card__popup__cards'>{`${cardsQty || 0} ${
        cardsQty === 1 ? 'card' : 'cards'
      }`}</p>
      <div className='deck-card__popup__switcher'>
        <button
          type='button'
          className={`deck-card__popup__switch ${
            !isStudyMode ? 'deck-card__popup__switch--checked' : ''
          }`}
          onClick={handleSetMode(false)}
        >
          <TimingIcon className='deck-card__popup__switch--icon' />
          <div>
            <p className='deck-card__popup__switch--title'>Timing mode</p>
            <p className='deck-card__popup__switch--subtitle'>
              Traditional SAFMEDS timing. This data is used to generate charts
              and data timelines.
            </p>
          </div>
        </button>
        <button
          type='button'
          className={`deck-card__popup__switch ${
            isStudyMode ? 'deck-card__popup__switch--checked' : ''
          }`}
          onClick={handleSetMode(true)}
        >
          <StudyIcon className='deck-card__popup__switch--icon' />
          <div>
            <p className='deck-card__popup__switch--title'>Study mode</p>
            <p className='deck-card__popup__switch--subtitle'>
              Unlimited and untimed practice. This data will not appear on
              charts or data timelines.
            </p>
          </div>
        </button>
      </div>
      {deck?.errorCardsNumber > 0 && isStudyMode && (
        <div className='deck-card__popup__err-cards'>
          <>
            <div>
              <p className='deck-card__popup__err-title'>
                Show error cards only
              </p>
              <p className='deck-card__popup__err-text'>
                This option allows you to practice only the cards you skipped or
                missed
              </p>
            </div>
            <Switch
              color='primary'
              value={showErrorCards}
              onChange={() => setShowErrorCards(!showErrorCards)}
            />
          </>
        </div>
      )}
      {!isStudyMode && (
        <div className='deck-card__popup__slider'>
          <h6 className='deck-card__popup__duration'>Duration</h6>
          <Box className='deck-card__popup__rail-box'>
            <Slider
              aria-label='Restricted values slider'
              defaultValue={10}
              value={sliderValue}
              onChange={(
                event: React.ChangeEvent<{}>,
                newValue: number | number[]
              ) => setSliderValue(newValue as number)}
              min={10}
              max={120}
              valueLabelFormat={getSliderValueText}
              step={null}
              valueLabelDisplay='auto'
              marks={marks}
              tabIndex={0}
              color='primary'
            />
            <div className='deck-card__popup__boundaries'>
              <p>10 sec.</p>
              <p>120 sec.</p>
            </div>
          </Box>
        </div>
      )}
      <div className='deck-card__popup__actions'>
        <Button
          text='Start'
          color={
            !cardsQty || cardsQty === 0
              ? theme.palette['cr-gray']
              : theme.palette.aquisition
          }
          onClick={handleStart}
          disabled={startDisabled}
        />
        <Button text='Cancel' transparent onClick={onClose} />
      </div>
      {isPreloaderActive && <Preloader isAbsolute isLightMode />}
    </Wrapper>
  );
};
