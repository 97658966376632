import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  margin-top: 40px;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${(props) => props.theme.palette.background};

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    display: block;
  }

  .safmeds {
    &__form {
      min-width: 320px;
      @media (max-width: ${(props) => props.theme.breakpoints.md}) {
        min-width: auto;
        margin-top: 10px;
        margin-bottom: 16px;
      }
    }
    &__input {
      width: 100%;
    }
    &__label {
      display: flex;
      align-items: center;
    }
    &__label--text {
      font-family: 'Roboto';
      color: #92a2aa;
      letter-spacing: 0.15px;
      margin-left: 10px;
      font-weight: 400;
      font-size: 16px;
    }
    &__input-adornment {
      path {
        fill: gray;
      }
    }
    &__input fieldset {
      border-color: ${(props) => props.theme.palette.background};
    }
    &__input:hover fieldset {
      border-color: ${(props) => props.theme.palette.aquisition};
    }
    &__input--clear {
      fill: gray;
      padding: 5px;
    }
  }
  .safmeds__upper-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    h5 {
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0px;
      text-align: left;
      margin: 0;
    }
    .tag {
      padding: 3px 10px;
      height: 16px;
      margin: 0px 8px;
    }
  }
`;
