import { FC } from 'react';
import { IconButton } from '@material-ui/core';
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import { ReactComponent as EditIcon } from 'assets/edit.svg';
import { Condition } from 'interfaces/condition';
import moment from 'moment';

import { TRow } from '../Row/style';

type Props = {
  handleEdit: () => void;
  handleDelete: () => void;
} & Pick<Condition, 'title' | 'type' | 'dateTime'>;

export const ConditionRow: FC<Props> = ({
  title,
  type,
  dateTime,
  handleEdit,
  handleDelete
}) => {
  return (
    <TRow type='condition'>
      <td>
        {`${type.charAt(0).toUpperCase()}${type.slice(1)}`}: {title}
      </td>
      <td></td>
      <td></td>
      <td></td>
      <td>{moment(new Date(dateTime)).format('MM/DD/YYYY hh:mm A')}</td>
      <td>
        <IconButton name='edit' aria-label='edit' onClick={handleEdit}>
          <EditIcon />
        </IconButton>
        <IconButton name='delete' aria-label='delete' onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </td>
    </TRow>
  );
};
