import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 416px;
  margin: auto;

  .result {
    &__header {
      display: flex;
      align-items: center;
      padding-bottom: 18px;
      margin-bottom: 32px;
      border-bottom: 1px solid ${(props) => props.theme.palette['soft-gray']};

      span {
        font-size: 24px;
        line-height: 32px;
        font-family: Roboto;
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0px;
      }

      button {
        font-size: 13px;
        line-height: 22px;
        margin-left: 16px;
      }
    }

    &__overall {
      &__info {
        display: flex;
        flex-direction: column;

        @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
          flex-direction: column;
        }

        &:last-child {
          margin-top: 18px;
          border-top: 1px solid ${(props) => props.theme.palette['soft-gray']};

          @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
            margin-top: 10px;
          }
        }

        &__general {
          margin-bottom: 54px;
          
          @media (max-width: 450px) {
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          &__title {
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 8px;
          }

          &__data {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-size: 20px;
            line-height: 32px;
            letter-spacing: 0.15px;

            @media (max-width: 450px) {
              flex-direction: column;
            }
          }
        }

        &__block {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          flex: 1;
          margin-bottom: 16px;
          border-bottom: 1px solid
            ${(props) => props.theme.palette['soft-gray']};
          .tag {
            margin: 0;
            .tag__text {
              color: ${(props) =>
                props.theme.palette['dark-cr-gray']} !important;
              font-family: Roboto;
              font-size: 13px;
              font-weight: 700;
              line-height: 18px;
              letter-spacing: 0.1599999964237213px;
              text-align: left;
            }
          }
          @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
            margin-bottom: 10px;
          }

          &__title {
            font-weight: 700;
            font-size: 16px;
            line-height: 28px;
            letter-spacing: 0.15px;
            margin-bottom: 8px;

            @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
              margin-bottom: 0;
            }
          }

          &__value {
            font-family: 'Inter', sans-serif;
            font-size: 16.0156px;
            line-height: 22px;
            letter-spacing: -0.01em;
          }
        }
        &__buttons {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
            flex-direction: column;
          }
          button {
            width: 50%;
            margin: 40px 16px 0 0;

            @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
              width: 100%;
              margin: 24px 0 0;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }

        &__report {
          margin-top: 24px;
          width: 100%;
        }
      }
      &__bigTags {
        @media (max-width: 450px) {
          margin-bottom: 10px;
        }
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        width: 120px;
        height: 120px;
        padding: 15px 24px;
        border: #d5d5d5 1px solid;
        border-radius: 20px;
        font-size: 16px;
        font-weight: 700;
        
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          width: 80px;
          height: 45px;
          margin-bottom: 4px;
        }
      }
    }
    }
  }
`;

export const StudyWrapper = styled.div`
  .result {
    max-width: 420px;
    padding: 10px 2px;
    margin: 0 auto;

    &--title {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 133.4%;

      @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        font-size: 20px;
      }
    }

    &__buttons {
      margin-top: 18px;
      border-top: 1px solid #d5d5d5;
      padding-top: 32px;
    }

    &__buttons--button {
      width: 100%;
      margin-bottom: 16px;
    }
  }
`;
