import styled from 'styled-components';

export const Wrapper = styled.div`
  font-family: 'Manrope', sans-serif;
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.14px;
  display: flex;
  justify-content: center;
  align-items: center;

  .error {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 500px;
    margin: 30px;

    &__logo {
      display: flex;
      align-items: center;

      span {
        font-size: 20px;
        margin-left: 8px;
      }
    }

    &__title {
      font-size: 42px;
      margin-top: 30px;
      line-height: 48px;
    }

    &__description {
      margin: 20px 0 30px;
      font-size: 20px;
      line-height: 28px;
      color: ${(props) => props.theme.palette['dark-cr-gray']};
    }

    &__actions {
      display: flex;
      width: 100%;

      @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        flex-direction: column;
      }

      button {
        flex: 1;
        margin: 0 20px;

        @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
          margin: 10px 0;
        }
      }
    }
  }
`;
