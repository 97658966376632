import { FC } from 'react';
import { Button } from 'components/Button';
import { Condition } from 'interfaces/condition';
import moment from 'moment';

import { Wrap } from './style';

type Props = {
  chartName: string;
  title: Condition['title'];
  type: Condition['type'];
  date: Condition['dateTime'];
  onSubmit: () => void;
  onCancel: () => void;
};

export const ConditionDelete: FC<Props> = ({
  chartName,
  title,
  type,
  date,
  onSubmit,
  onCancel
}) => {
  return (
    <Wrap>
      <h1 className='modal__title'>
        Are you sure you want to delete the following record?
      </h1>
      <p className='modal__subtitle'>{chartName}</p>
      <div className='modal__content'>
        <div className='modal__content-item'>Title: {title}</div>
        <div className='modal__content-item'>
          Title: {type.charAt(0).toUpperCase() + type.slice(1)}
        </div>
        <div className='modal__content-item'>
          Title: {moment(date).format('D MMM YYYY')}
        </div>
      </div>
      <div className='modal__buttons'>
        <Button text='DELETE' onClick={onSubmit} color='#DE543C' />
        <Button text='CANCEL' onClick={onCancel} transparent />
      </div>
    </Wrap>
  );
};
