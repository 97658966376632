import { FC, useMemo } from 'react';
import { Condition } from 'interfaces/condition';
import { Measurement } from 'interfaces/measurment';
import moment from 'moment';

import { ConditionRow } from '../ConditionRow';
import { TableHead } from '../Head/Head';
import { TableRow } from '../Row';
import { TRow } from '../Row/style';
import { Table } from './style';

type Props = {
  data: Measurement[] | null;
  conditions: Condition[] | null;
  handleMeasurementEdit: (id: string) => void;
  handleMeasurementDelete: (id: string) => void;
  handleConditionEdit: (id: string) => void;
  handleConditionDelete: (id: string) => void;
};

type SortedItem =
  | ({
      rowType: 'condition';
    } & Condition)
  | ({
      rowType: 'measurment';
    } & Measurement);

export const MeasurementsTable: FC<Props> = ({
  data,
  conditions,
  handleMeasurementEdit,
  handleMeasurementDelete,
  handleConditionEdit,
  handleConditionDelete
}) => {
  const getSortedData = (
    conditons: Condition[],
    measurments: Measurement[]
  ): Array<SortedItem> => {
    const conditionsData = conditons.map((i) => ({
      ...i,
      rowType: 'condition',
      dateTime: moment(i.dateTime).format('MM/DD/YYYY HH:mm:ss')
    }));
    const measurmentsData = measurments.map((i) => ({
      ...i,
      rowType: 'measurment',
      dateTime: moment(i.dateTime).format('MM/DD/YYYY HH:mm:ss')
    }));

    const values: Array<SortedItem> = [
      ...conditionsData,
      ...measurmentsData
    ].sort(
      (a, b) => new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime()
    ) as Array<SortedItem>;

    return values;
  };

  const tableOfMeasurements: Array<JSX.Element> = useMemo(() => {
    if (!conditions?.length && !data?.length) {
      return [
        <TRow type='measurment'>
          <td>No attempts found</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </TRow>
      ];
    }

    const sortedData = getSortedData(conditions || [], data || []);

    return sortedData.map((item) =>
      item.rowType === 'condition' ? (
        <ConditionRow
          key={item._id}
          title={item.title}
          type={item.type}
          dateTime={item.dateTime}
          handleEdit={() => handleConditionEdit(item.id)}
          handleDelete={() => handleConditionDelete(item.id)}
        />
      ) : (
        <TableRow
          key={item._id}
          item={item}
          handleEdit={() => handleMeasurementEdit(item.id)}
          handleDelete={() => handleMeasurementDelete(item.id)}
        />
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, conditions]);

  const renderBody = useMemo(() => {
    return tableOfMeasurements;
  }, [tableOfMeasurements]);

  return (
    <Table>
      <TableHead />
      {renderBody}
      {/* {tableOfMeasurements} */}
    </Table>
  );
};
