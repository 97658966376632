import React from 'react';

import { Avatar, Wrapper } from './style';

export const UserHeading: React.FC = () => {
  const fullName = localStorage.getItem('fullName') || '';

  return (
    <Wrapper>
      <Avatar>
        {fullName.split(' ')[0]?.trim().charAt(0) || ''}
        {fullName.split(' ')[1]?.trim().charAt(0) || ''}
      </Avatar>
      <span>{fullName}</span>
    </Wrapper>
  );
};
