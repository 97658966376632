import { ChartDTO } from 'interfaces/chart';
import { ChartCardDTO } from 'interfaces/chartCard';

import { APIService } from './api';

type PostChartAPIParams = {
  name: string;
};

type PutChartAPIParams = {
  id: string;
  name: string;
};

export class Charts extends APIService {
  isMockedApi: boolean;

  constructor(isMockedApi: boolean = false) {
    super();
    this.isMockedApi = isMockedApi;
  }

  postChartApi(body: PostChartAPIParams): Promise<ChartCardDTO> {
    if (this.isMockedApi) {
      return new Promise((resolve) =>
        setTimeout(resolve, 1000, require('../mocks/charts.json')[0])
      );
    }

    return this.post<PostChartAPIParams, ChartCardDTO>(`/api/chart`, body);
  }

  getChartsApi(): Promise<ChartCardDTO[]> {
    if (this.isMockedApi) {
      return new Promise((resolve) =>
        setTimeout(resolve, 1000, require('../mocks/charts.json'))
      );
    }

    return this.get<ChartCardDTO[]>(`/api/chart`);
  }

  putChartApi({ id, name }: PutChartAPIParams): Promise<ChartCardDTO> {
    if (this.isMockedApi) {
      return new Promise((resolve) =>
        setTimeout(resolve, 1000, require('../mocks/charts.json')[0])
      );
    }

    return this.put<PostChartAPIParams, ChartCardDTO>(`/api/chart/${id}`, {
      name: name
    });
  }

  getChartByIdApi(id: string): Promise<ChartDTO> {
    if (this.isMockedApi) {
      return new Promise((resolve) =>
        setTimeout(resolve, 1000, require('../mocks/charts.json')[0])
      );
    }

    return this.get<ChartDTO>(`/api/chart/${id}`);
  }
}
