import { Answers, QuizQuestions } from 'interfaces/quizQuestions';

import { APIService } from './api';

type QuizAnswersParams = {
  answers: Answers;
  certificationSlug: string;
};

export class Quiz extends APIService {
  isMockedApi: boolean;

  constructor(isMockedApi: boolean = false) {
    super();
    this.isMockedApi = isMockedApi;
  }

  quizQuestions(slug: string): Promise<Array<QuizQuestions>> {
    if (this.isMockedApi) {
      return new Promise((resolve) =>
        setTimeout(resolve, 1000, require('../mocks/quizQuestions.json')[0])
      );
    }

    return this.get<Array<QuizQuestions>>(
      `/api/quizQuestion?certificationSlug=${slug}`
    );
  }

  quizAnswers(body: QuizAnswersParams): Promise<Array<QuizQuestions>> {
    if (this.isMockedApi) {
      return new Promise((resolve) =>
        setTimeout(resolve, 1000, require('../mocks/quizQuestions.json'))
      );
    }

    return this.post<QuizAnswersParams, Array<QuizQuestions>>(
      `/api/quizQuestion/quizAnswer`,
      body
    );
  }
}
