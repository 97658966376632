import {
  Attempt,
  AttemptById,
  CreateSafmedsAttemptDto,
  ReportTableDto
} from 'interfaces/attempts';
import { ErrorDeckAttemptDto } from 'interfaces/errorDeck';

import { APIService } from './api';

type PostAttemptAPIParams = {
  deckId: string;
  collectionId: string;
  sectionId: string;
};

export class Attempts extends APIService {
  isMockedApi: boolean;

  constructor(isMockedApi: boolean = false) {
    super();
    this.isMockedApi = isMockedApi;
  }

  getAttemptsAPI(slug: string): Promise<Array<Attempt>> {
    if (this.isMockedApi) {
      return new Promise((resolve) =>
        setTimeout(resolve, 1000, require('../mocks/attempt.json'))
      );
    }

    return this.get<Array<Attempt>>(`/api/attempts?certificationSlug=${slug}`);
  }

  getAttemptByIdAPI(id: string): Promise<AttemptById> {
    if (this.isMockedApi) {
      return new Promise((resolve) =>
        setTimeout(resolve, 1000, require('../mocks/attempt.json'))
      );
    }

    return this.get<AttemptById>(`/api/attempts/${id}`);
  }

  postSafmedsAttempt(request: CreateSafmedsAttemptDto): Promise<AttemptById> {
    if (this.isMockedApi) {
      return new Promise((resolve) =>
        setTimeout(resolve, 1000, require('../mocks/attempt.json'))
      );
    }

    return this.post<CreateSafmedsAttemptDto, AttemptById>(
      `/api/safmedsAttempt`,
      request
    );
  }

  postSafmedsAttemptPrevious(
    body: PostAttemptAPIParams
  ): Promise<ReportTableDto> {
    if (this.isMockedApi) {
      return new Promise((resolve) =>
        setTimeout(resolve, 1000, require('../mocks/attempt.json'))
      );
    }

    return this.post<PostAttemptAPIParams, ReportTableDto>(
      `/api/safmedsAttempt/previousData`,
      body
    );
  }

  postErrorDeckAttempt(request: ErrorDeckAttemptDto): Promise<any> {
    if (this.isMockedApi) {
      return new Promise((resolve) =>
        setTimeout(resolve, 1000, require('../mocks/attempt.json'))
      );
    }

    return this.post<ErrorDeckAttemptDto, AttemptById>(
      `/api/errorDeck/errorDeckAttempt`,
      request
    );
  }
}
