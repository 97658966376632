import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  .deck-report {
    margin-top: 83px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    &-text {
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0px;
      text-align: left;
      margin: 0;
      margin-right: 10px;
    }

    &__filter {
      display: flex;
      align-items: center;

      &__switch {
        display: flex;
        align-items: center;

        button {
          font-size: 13px;
          line-height: 22px;
          letter-spacing: 0.46px;
          transition: background 0.5s;

          &:first-child {
            border-radius: 4px 0 0 4px;
          }

          &:last-child {
            border-radius: 0 4px 4px 0;
          }
        }
      }
    }
  }
  .deck-report__table__row {
    height: 52px;
    width: 100%;
    border-radius: 0px;
  }
  .deck-report__table__row__td {
    padding: 14px 0px 14px 5px;
    /* width: 20%; */
  }
  .deck-report__table-header > th {
    height: 60px;
    width: 864px;
    border-radius: 0px;
    padding: 0px 0px 0px 24px;
    background: #f3f3f3;
    border-top: 2px solid #667c8857;
  }
  .deck-report__table-header {
    cursor: pointer;
  }
  .deck-report__table--collapse {
    @media print {
      display: none;
    }
  }

  .deck-report__table-container {
    margin: 0 auto;
    width: 80%;
    @media (max-width: ${(props) => props.theme.breakpoints.xl}) {
      width: 100%;
    }
    .deck-report__table-container__no-attempts {
      margin-bottom: 24px;
      margin-top: 34px;
      text-align: center;
    }
    .deck-report__upper-text {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      align-items: center;
      border-bottom: 1px solid ${(props) => props.theme.palette.background};
      h5 {
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0px;
        text-align: left;
        margin: 0;
      }
      .deck-report__print {
        margin-left: auto;
      }

      @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        display: flex;
        h5 {
          font-size: 16px;
        }
        button {
          font-size: 12px;
          line-height: 20px;
          padding: 3px 4px;
        }
      }
    }
    .deck-report__table {
      border-spacing: 0;
      vertical-align: middle;
      text-align: left;
      min-width: 568px;
      width: 100%;
      &__wrap {
        overflow: auto;
      }
      &__head {
        width: 80%;
        &-cell {
          height: 24px;
          // width: 52px;

          align-items: center;
          border-bottom: 1px solid $info;
          border-top: 1px solid $info;
          line-height: 18px;
          padding: 2rem 32px 1rem 5px;
          vertical-align: middle;
          white-space: nowrap;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0.17000000178813934px;
          text-align: left;
        }
      }
      &__th-row {
        align-items: center;
        display: flex;
        flex-direction: row;
      }
      &__td {
        align-items: center;
        display: flex;
        flex-direction: row;
      }
      &__row {
        border-top: 0;
      }
      &__head-cell {
      }
    }
    .deck-report__deck-title {
      font-family: Roboto;
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0px;
      text-align: left;
      margin-bottom: 8px;
      margin-top: 16px;
    }
    .deck-report__table__body {
      .deck-report__table__row {
        &:nth-child(odd) {
          border-top: 1px solid ${(props) => props.theme.palette.background};
          border-bottom: 1px solid ${(props) => props.theme.palette.background};
          background-color: #fafeff;
        }
      }
    }
  }
`;
