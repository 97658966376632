export const marks = [
  {
    value: 10,
    label: ''
  },
  {
    value: 15,
    label: ''
  },
  {
    value: 20,
    label: ''
  },
  {
    value: 30,
    label: ''
  },
  {
    value: 45,
    label: ''
  },
  {
    value: 60,
    label: ''
  },
  {
    value: 90,
    label: ''
  },
  {
    value: 120,
    label: ''
  }
];
