import { FC, useEffect, useMemo, useRef } from 'react';
import { Chart as ReactChart } from 'react-chartjs-2';
import {
  CategoryScale,
  Chart as ChartJS,
  ChartEvent,
  Legend,
  LinearScale,
  LineElement,
  LogarithmicScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip
} from 'chart.js';
import annotationPlugin, { EventContext } from 'chartjs-plugin-annotation'; // Annotation(Condition) plugin
import zoomPlugin from 'chartjs-plugin-zoom'; // Zoom and pan plugin
import { getAimLine } from 'helpers/getChartAimLine';
import { getCondiotion } from 'helpers/getChartCondition';
import { getListOfTrends } from 'helpers/getListOfContitions';
import { getNumberOfDayFromDate } from 'helpers/getNumberOfDayFromDate';
import { externalTooltipHandler, renderTooltip } from 'helpers/renderTooltip';
import { ChartDataSets, ChartDTO } from 'interfaces/chart';
import { Condition } from 'interfaces/condition';
import { Measurement } from 'interfaces/measurment';
// import { format } from 'date-fns';
import moment from 'moment';

import 'chartjs-adapter-moment'; // Infinite graph plugin

import { ReactComponent as ZoomIn } from '../../assets/zoomIn.svg';
import { ReactComponent as ZoomOut } from '../../assets/zoomOut.svg';
import { Wrapper } from './style';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  LogarithmicScale,
  Title,
  Tooltip,
  Legend,
  annotationPlugin,
  zoomPlugin
);

type Props = {
  chart: ChartDTO;
  datasets: Array<ChartDataSets>;
  conditions: Array<Condition>;
  startDate: Date;
  correctList: Array<{ y: number; x: number }>;
  incorrectList: Array<{ y: number; x: number }>;
  handleOnConditionEdit: (id: string) => void;
  handleOnMeasurmentEdit: (id: string) => void;
  handleGetMeasurment: (data: string) => Measurement;
  handleOnDeleteMes: (id: string) => void;
  handleConditionDelete: (id: string) => void;
};

// @ts-ignore
// eslint-disable-next-line no-extend-native
Date.prototype.addDays = function (days: number): Date {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

export const Chart: FC<Props> = ({
  chart,
  datasets,
  conditions,
  startDate,
  correctList,
  incorrectList,
  handleOnConditionEdit,
  handleOnMeasurmentEdit,
  handleGetMeasurment,
  handleOnDeleteMes,
  handleConditionDelete
}) => {
  const chartRef = useRef<any>(null);

  const getGraphEndDate = (
    start: Date = startDate,
    days: number = 140
  ): Date => {
    // @ts-ignore
    return new Date(start).addDays(days);
  };

  const onConditionEdit = (id: string) => {
    handleOnConditionEdit(id);
  };

  const onConditionDelete = (id: string) => {
    handleConditionDelete(id);
  };

  const onMeasurmnetEdit = (id: string) => {
    handleOnMeasurmentEdit(id);
  };

  const onMeasurmnetDelete = (id: string) => {
    handleOnDeleteMes(id);
  };

  const getConditionTooltipText = (id: string): string => {
    const condition = conditions.find((c) => c.id === id) as Condition;
    return `${
      condition.type === 'slice'
        ? 'Slice condition title: '
        : 'Intervention condition title: '
    } ${condition.title}`;
  };

  // @ts-ignore
  const onTootlipClick: (
    ctx: EventContext,
    event: ChartEvent & { chart: { canvas: HTMLCanvasElement } },
    id: string
  ) => (
    ctx: EventContext,
    event: ChartEvent & { chart: { canvas: HTMLCanvasElement } },
    onEdit: () => void,
    onDelete: () => void
  ) => void = (ctx, event, id) => {
    return renderTooltip(
      getConditionTooltipText(id),
      ctx,
      event,
      () => onConditionEdit(id),
      () => onConditionDelete(id)
    );
  };

  const conditionLines = useMemo(() => {
    return conditions.map(({ id, title, type, dateTime }) => {
      return getCondiotion({
        positionX: new Date(dateTime).toDateString(),
        color: type === 'slice' ? '#003EDF' : '#DF0000',
        label: title,
        onClick: (ctx, e) => onTootlipClick(ctx, e, id),
        width: 2
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditions]);

  const trendLines = useMemo(() => {
    if (correctList.length > 1 && incorrectList.length > 1) {
      return [
        ...getListOfTrends(startDate, correctList, conditions, '#1AB5A0'),
        ...getListOfTrends(startDate, incorrectList, conditions, '#FF837A')
      ];
    } else {
      return [];
    }
  }, [correctList, incorrectList, conditions, startDate]);

  const options = useMemo(() => {
    return {
      scales: {
        x: {
          title: {
            display: true,
            text: 'SUCCESSIVE CALENDAR DAYS'
          },
          type: 'time',
          time: {
            unit: 'day',
            displayFormats: {
              day: 'MM-DD-YYYY'
            }
          },
          ticks: {
            callback: (value: any) => {
              const day = getNumberOfDayFromDate(startDate, value);
              if (day % 7 === 0) {
                return day;
              }
              return null;
            }
          },
          offset: false,
          grid: {
            color: '#8BE2FF',
            drawBorder: true,
            drawOnChartArea: true,
            drawTicks: true,
            lineWidth: 2
          },
          font: {
            size: 8
          },
          min: startDate,
          max: getGraphEndDate(startDate)
        },
        x2: {
          type: 'time',
          position: 'top',
          time: {
            unit: 'day',
            displayFormats: {
              day: 'MM-DD-YYYY'
            }
          },
          ticks: {
            callback: (value: any, index: any) => {
              const day = getNumberOfDayFromDate(startDate, value);
              if (day % 14 === 0) {
                return moment(new Date(value)).format('MM/DD/YYYY');
              }
              return null;
            },
            font: {
              size: 14
            }
          },
          offset: false,
          grid: {
            drawBorder: true,
            drawOnChartArea: false,
            drawTicks: true
          },
          min: startDate,
          max: getGraphEndDate(startDate)
        },
        xAsix: {
          type: 'time',
          time: {
            unit: 'day',
            displayFormats: {
              day: 'MM-DD-YYYY'
            }
          },
          ticks: {
            callback: () => {
              return '';
            }
          },
          offset: false,
          grid: {
            color: '#E5F6FC',
            drawBorder: false,
            drawOnChartArea: true,
            drawTicks: false
          },
          font: {
            size: 8
          },
          min: startDate,
          max: getGraphEndDate(startDate)
        },
        y: {
          title: {
            display: true,
            text: 'COUNT PER MINUTE'
          },
          grid: {
            color: '#8BE2FF',
            /* major minor grid lines based on log10 integer value of tick */
            lineWidth: (context: any) => {
              const remain =
                context.tick.value /
                Math.pow(10, Math.floor(Math.log10(context.tick.value)));
              if (remain === 1 || remain === 5) {
                return 1;
              }
              return 0.3;
            }
          },
          type: 'logarithmic' as any,
          // beginAtZero: true,
          ticks: {
            autoSkip: false,
            includeBounds: true,
            /* only show specific y axis labels) */
            callback: (tickValue: any) => {
              const remain =
                tickValue / Math.pow(10, Math.floor(Math.log10(tickValue)));
              if (remain === 1 || remain === 5) {
                // if (tickValue === 0.5) tickValue = '0';
                return String(tickValue);
              }
              return '';
            }
          },
          min: 0.001,
          max: 1000
        },
        y2: {
          title: {
            display: true,
            text: 'COUNTING TIME'
          },
          display: true,
          type: 'logarithmic' as any,
          position: 'right' as any,
          grid: {
            drawOnChartArea: false
          },
          ticks: {
            autoSkip: false,
            includeBounds: true,
            callback: (tickValue: any) => {
              if (tickValue <= 1) {
                return String(
                  Number.isInteger(60 / (tickValue * 60))
                    ? 60 / (tickValue * 60) + (tickValue === 1 ? "' min" : "'")
                    : ''
                );
              } else {
                return String(
                  (60 / tickValue) % 5
                    ? ''
                    : 60 / tickValue + (tickValue === 6 ? '" sec' : '"')
                );
              }
            }
          },
          min: 0.001,
          max: 1000
        }
      },
      plugins: {
        annotation: {
          annotations: [
            ...conditionLines,
            getCondiotion({
              positionX: startDate.toDateString(),
              color: 'rgba(65, 149, 204, 0.5)',
              width: 5
            }),
            ...getAimLine({
              min: chart.options.safmedsAimLineMin,
              max: chart.options.safmedsAimLineMax
            }),
            ...trendLines
          ]
        },
        legend: {
          display: true,
          position: 'top' as const,
          labels: {
            usePointStyle: true,
            boxHeight: 0,
            boxWidth: 24
          }
        },
        title: {
          display: false,
          text: 'Chart.js Line Chart'
        },
        zoom: {
          limits: {
            y: { min: 0.001, max: 1000 }
          },
          pan: {
            enabled: true,
            mode: 'xy',
            maxY: 100,
            speed: 0.5
          }
        },
        tooltip: {
          enabled: false,
          usePointStyle: true,
          position: 'nearest',
          external: (c: EventContext) => {
            return externalTooltipHandler(
              c as EventContext & { tooltip: any },
              handleGetMeasurment,
              onMeasurmnetEdit,
              onMeasurmnetDelete
            );
          },
          events: ['click']
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chart.options, startDate, conditionLines, trendLines, datasets]);

  const handleClick = (e: MouseEvent) => {
    const tool = window.document.getElementById('chartjs-tooltip1');
    const canvas = window.document.getElementsByClassName('chartjs')[0];
    // @ts-ignore
    if (e.target && e.target.id !== 'chartjs-tooltip1') {
      if (canvas && canvas.parentNode && tool) {
        canvas.parentNode.removeChild(tool);
      }
    }
  };

  const handleZoomIn = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.zoom(1.1);
    }
  };

  const handleZoomOut = () => {
    chartRef.current.zoom(0.9);
  };

  useEffect(() => {
    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <Wrapper className='chart'>
      <div className='chart__inner'>
        <ReactChart
          ref={chartRef}
          className='chartjs'
          type='line'
          // @ts-ignore
          options={options}
          data={{ datasets }}
        />
      </div>
      <div className='chart__buttons'>
        <button onClick={() => handleZoomIn()}>
          <ZoomIn />
        </button>
        <button onClick={() => handleZoomOut()}>
          <ZoomOut />
        </button>
      </div>
    </Wrapper>
  );
};
