import React, { useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Prompt, useHistory, useLocation, useParams } from 'react-router';
import { api } from 'api/index';
import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import { Notification } from 'components/Notification';
import { Preloader } from 'components/Preloader';
import { Question } from 'components/Question';
import { QuestionPiker } from 'components/QuestionPiker';
import { Timer } from 'components/Timer';
import { paths } from 'config/paths';
import { CertificationDTO } from 'interfaces/certification';
import { Questions } from 'interfaces/questions';
import { Error } from 'pages/Error';
import { theme } from 'theme';

import { Wrapper } from './style';

type Params = {
  current: string;
  certificationSlug: string;
};
interface LocationState {
  customExamTime?: number;
  customQuestionNumbers?: number;
  attemptNumber: number;
}

export const Exam: React.FC = () => {
  const [attemptId, setAttemptId] = useState('');
  const [questions, setQuestions] = useState<Questions['randomQuestions']>([]);

  const location = useLocation<LocationState>();
  const { current, certificationSlug } = useParams<Params>();
  const currentQuestion = questions.find(
    (question) => question.number === Number(current)
  );

  const [isQuestionListOpen, setIsQuestionListOpen] = useState<boolean>(false);
  const [isPreloaderActive, setIsPreloaderActive] = useState<boolean>(false);
  const [isLeaveModalOpen, setIsLeaveModalOpen] = useState<boolean>(false);
  const [isTimeUp, setIsTimeUp] = useState<boolean>(false);

  const [startExamTime] = useState(new Date());
  const [customExamTime, setCustomExamTime] = useState(0);
  const [attemptNumber] = useState(location.state?.attemptNumber || 1);
  const [startQuestionTime, setStartQuestionTime] = useState(new Date());
  const [finishConfirmation, setFinishConfirmation] = useState<boolean>(false);
  const [certification, setCertification] = useState<CertificationDTO>();

  const history = useHistory();

  useEffect(() => {
    if (
      location.state?.customQuestionNumbers &&
      location.state?.customExamTime
    ) {
      setCustomExamTime(location.state.customExamTime);
      api.exam
        .startExamAPI({
          overallQuestionsNumber: location.state.customQuestionNumbers,
          testTime: location.state.customExamTime,
          certificationSlug
        })
        .then((res) => {
          setAttemptId(res.attemptId);
          setQuestions(
            res.randomQuestions.map(
              (question: Questions['randomQuestions'][number], id: number) => {
                return {
                  ...question,
                  number: id + 1,
                  result: {
                    answer: '',
                    isFlagged: false,
                    time: 0
                  }
                };
              }
            )
          );
        });
    } else {
      history.push(paths.home());
    }
    // eslint-disable-next-line
  }, [certificationSlug]);

  useEffect(() => {
    api.cert
      .getCertificationsAPI()
      .then((certifications) => {
        setCertification(
          certifications.find((c) => c.certificationSlug === certificationSlug)
        );
      })
      .catch((err) => console.error(err));
  }, [certificationSlug]);

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, []);

  const alertUser = (e: any) => {
    e.preventDefault();
    e.returnValue = 'Reload site? Changes that you made will not be saved';
  };

  const handleCloseQuestionList = () => {
    setIsQuestionListOpen(false);
  };

  const handleChangeResult = (
    type: 'answer' | 'isFlagged' | 'time',
    result?: string | number | boolean
  ) => {
    setQuestions((prev) => {
      const updatedResult = [...prev];
      const current = updatedResult.find(
        (answer) => answer.number === currentQuestion?.number
      );

      if (!current) return updatedResult;

      switch (type) {
        case 'answer':
          current.result.answer = String(result);
          break;

        case 'isFlagged':
          current.result.isFlagged = !current.result.isFlagged;
          break;

        case 'time':
          current.result.time += Number(result || 1);
          break;

        default:
          break;
      }

      return updatedResult;
    });
  };

  const handleChangePage = (location: Location) => {
    if (
      !location.pathname.startsWith(paths.exam()) &&
      location.pathname !== paths.result() &&
      !isLeaveModalOpen
    ) {
      setIsLeaveModalOpen(true);
      return false;
    }

    if (location.pathname.startsWith(paths.exam())) {
      handleChangeResult(
        'time',
        Math.floor((new Date().getTime() - startQuestionTime.getTime()) / 1000)
      );
      setStartQuestionTime(new Date());

      setAnswerOnAPI();
    }

    return true;
  };

  const setAnswerOnAPI = (isFinish?: boolean) => {
    setIsPreloaderActive(true);

    if (currentQuestion) {
      return api.exam
        .addAnswerAPI({
          attemptId,
          answer: {
            questionId: currentQuestion.id,
            answerId: currentQuestion.result.answer,
            isFlagged: currentQuestion.result.isFlagged,
            time: isFinish
              ? Math.floor(
                  (new Date().getTime() - startQuestionTime.getTime()) / 1000
                )
              : currentQuestion.result.time
          }
        })
        .then(() => setIsPreloaderActive(false));
    }
  };

  const handleFinishExam = () => {
    setAnswerOnAPI(true)?.then(() =>
      history.push({
        pathname: paths.result(),
        state: {
          timeInfo: {
            started: startExamTime,
            completed: new Date()
          },
          attemptId,
          attemptNumber
        }
      })
    );
  };

  const skippedQuestions = useMemo(
    () => questions.reduce((acc, cur) => (!cur.result.answer ? ++acc : acc), 0),
    [questions]
  );

  return (
    <ErrorBoundary
      FallbackComponent={(props) => (
        <Error {...props} questionId={currentQuestion?.id} />
      )}
    >
      {questions.length && currentQuestion ? (
        <>
          <Wrapper>
            <div className='info'>
              <div className='info__title'>
                <span>
                  {certification?.certification} Certification - Mock exam
                </span>
                <Button
                  text='Cancel'
                  transparent
                  paddingX={9}
                  paddingY={3}
                  color={theme.palette['dark-indigo-medium']}
                  onClick={() => setIsLeaveModalOpen(true)}
                />
              </div>
              <Timer
                onTimeIsUp={() => setIsTimeUp(true)}
                minutes={customExamTime}
              />
            </div>
            <QuestionPiker
              questions={
                currentQuestion.number < 9
                  ? questions.slice(0, 15)
                  : questions.length < currentQuestion.number + 8
                  ? questions.slice(-15)
                  : questions.slice(
                      currentQuestion.number - 8,
                      currentQuestion.number + 7
                    )
              }
              certificationSlug={certificationSlug}
              current={currentQuestion.number}
            />
            <Button
              text='Show all'
              className='more-toggle'
              paddingX={4}
              paddingY={3}
              transparent
              color={theme.palette['dark-indigo-medium']}
              onClick={() => setIsQuestionListOpen(true)}
              borderColor='transparent'
            />
            <Question
              questionsLength={questions.length}
              onChangeResult={handleChangeResult}
              onFinishExam={() => setIsQuestionListOpen(true)}
              question={currentQuestion.questionText.html}
              certificationSlug={certificationSlug}
              {...currentQuestion}
            />
            <Modal
              open={isQuestionListOpen}
              maxWidth='912px'
              borderRadius={8}
              borderColor={theme.palette.background}
              onClose={handleCloseQuestionList}
              overflowY={true}
            >
              <QuestionPiker
                modal
                questions={questions}
                certificationSlug={certificationSlug}
                current={currentQuestion.number}
                onSelect={handleCloseQuestionList}
              />
              <div className='finish-action'>
                <Button
                  text='Finish'
                  onClick={() => {
                    setIsQuestionListOpen(false);
                    setFinishConfirmation(true);
                  }}
                />
              </div>
            </Modal>
            <Modal
              open={isLeaveModalOpen}
              maxWidth='480px'
              borderRadius={4}
              onClose={() => setIsLeaveModalOpen(false)}
            >
              <Notification
                title='Leave the exam?'
                description='Your progress will not be saved'
                submitText='Leave'
                cancelText='Cancel'
                onSubmit={() => history.push(paths.mockExam(certificationSlug))}
                onCancel={() => setIsLeaveModalOpen(false)}
              />
            </Modal>
            <Modal open={isTimeUp} maxWidth='480px' borderRadius={4}>
              <Notification
                title='Time is up!'
                description='Your assessment has been submitted automatically.'
                submitText='View results'
                onSubmit={handleFinishExam}
              />
            </Modal>
            <Modal
              open={finishConfirmation}
              maxWidth='570px'
              borderRadius={4}
              onClose={() => setFinishConfirmation(false)}
            >
              <Notification
                title={`${
                  finishConfirmation && skippedQuestions
                    ? `${skippedQuestions} question${
                        skippedQuestions !== 1 ? 's' : ''
                      } ${
                        skippedQuestions !== 1 ? 'have' : 'has'
                      } not been answered. Clicking 'Finish' will score ${
                        skippedQuestions !== 1 ? 'these items' : 'this item'
                      } as errors.
                Are you sure you want to exit the exam?`
                    : 'Are you sure you want to complete the exam?'
                }`}
                description=''
                submitText='Finish'
                cancelText='Cancel'
                fullWidthButton
                onSubmit={handleFinishExam}
                onCancel={() => setFinishConfirmation(false)}
              />
            </Modal>
            <Prompt message={handleChangePage as () => boolean} />
          </Wrapper>
          {isPreloaderActive && <Preloader />}
        </>
      ) : (
        <Preloader />
      )}
    </ErrorBoundary>
  );
};
