import styled from 'styled-components';

export const Wrapper = styled.div`
  .legend {
    display: flex;
    padding: 24px 24px 0 24px;

    @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
      flex-direction: column;
    }

    &__info {
      display: flex;
      align-items: center;

      &__example {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        box-sizing: border-box;
        margin-right: 8px;

        &_completed {
          background-color: ${(props) =>
            props.theme.palette['light-lavender-opacity-plain']};
        }

        &_flagged {
          border: 2px solid ${(props) => props.theme.palette['dark-cr-gold']};
        }

        &_not-completed {
          border: 2px solid
            ${(props) => props.theme.palette['dark-indigo-medium']};
        }

        &_current {
          background-color: ${(props) =>
            props.theme.palette['dark-indigo-medium']};
        }
      }
    }

    span {
      margin-right: 16px;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.15px;
    }
  }

  .questions {
    display: flex;
    justify-content: space-between;

    @media (max-width: ${(props) => props.theme.breakpoints.md}) {
      flex-wrap: wrap;
    }

    &.modal {
      flex-wrap: wrap;
      justify-content: flex-start;
      padding: 28px 24px 0;

      @media (max-width: ${(props) => props.theme.breakpoints.md}) {
        justify-content: space-between;
      }

      button {
        @media (min-width: ${(props) => props.theme.breakpoints.mdMin}) {
          width: calc(100% / 15 - 8px);
          margin: 0 8px 8px 0;
        }
      }
    }

    button {
      width: 100%;
      margin-right: 8px;
      border-width: 3px;
      line-height: 22px;
      max-height: 42px;

      @media (max-width: ${(props) => props.theme.breakpoints.md}) {
        width: calc(20% - 10px);
        margin: 0 10px 10px 0;
      }

      &:nth-child(5n) {
        @media (max-width: ${(props) => props.theme.breakpoints.md}) {
          margin: 0;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
`;
