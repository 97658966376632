import React from 'react';
import { Tag } from 'components/Tag';

import { Wrapper } from './style';

interface Props {
  tagColor?: string;
  title: string;
  tagText: string | null;
  style?: React.CSSProperties;
}

export const HeadingWithTag: React.FC<Props> = ({
  tagColor,
  title,
  tagText,
  style
}) => (
  <Wrapper>
    <div className='safmeds__upper-text' style={style}>
      <h5>{title}</h5>
      {tagText && <Tag color={tagColor} text={tagText} />}
    </div>
  </Wrapper>
);
