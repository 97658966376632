import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 24px;
  width: 90vw;
  margin-left: calc(-20vw / 2);
  overflow: auto;

  @media (max-width: ${(props) => props.theme.breakpoints.xl}) {
    width: 100%;
    margin-left: 0;
  }

  .progress-chart-wrap {
    min-width: 1100px;
    padding-bottom: 5px;
  }

  @media print {
    overflow: visible;
    canvas {
      max-width: 1400px !important;
    }
  }
`;
