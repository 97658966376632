import React, { useCallback, useEffect, useState } from 'react';
import { api } from 'api/index';
import { DeckCardPopup } from 'components/DeckCardPopup';
import { Modal } from 'components/Modal';
import { MoreButton } from 'components/MoreButton';
import { Tag } from 'components/Tag';
import { ResponseDeckDTO } from 'interfaces/decks';

import { Wrapper } from './style';

interface Props {
  backgroundColor: string;
  tagColor: string;
  title: string;
  tagText: string;
  cardsQty: number;
  deckId: string;
  sectionId: string;
  collectionId: string;
}

export const DeckCard: React.FC<Props> = ({
  backgroundColor,
  tagColor,
  title,
  tagText,
  cardsQty,
  deckId,
  sectionId,
  collectionId
}) => {
  const [deck, setDeck] = useState<ResponseDeckDTO>();
  const [isStartModalOpen, setIsStartModalOpen] = useState<boolean>(false);
  const handleCloseStartModal = useCallback(
    () => setIsStartModalOpen(false),
    []
  );
  useEffect(() => {
    api.deck
      .getGraphDeckById({ id: deckId, collectionId, sectionId })
      .then((dck) => setDeck(dck))
      .catch((e) => console.error(e));
  }, [deckId, sectionId, collectionId]);

  return (
    <Wrapper className='deck-card__wrapper'>
      <button
        className='deck-card'
        style={{ backgroundColor }}
        onClick={() => setIsStartModalOpen(true)}
      >
        <div className='deck-card__upperblock'>
          <p className='deck-card__upperblock__text'>{title}</p>
          <MoreButton
            options={['Data']}
            deckId={deckId}
            sectionId={sectionId}
            collectionId={collectionId}
          />
        </div>
        <div className='deck-card__lowerblock'>
          <Tag color={tagColor} text={tagText} />
          {`${cardsQty} ${cardsQty === 1 ? 'card' : 'cards'}`}
        </div>
      </button>
      <Modal
        width='100%'
        maxWidth='866px'
        open={isStartModalOpen}
        borderRadius={4}
        onClose={handleCloseStartModal}
        overflowY
      >
        <DeckCardPopup
          deckName={title}
          onClose={handleCloseStartModal}
          deckId={deckId}
          sectionId={sectionId}
          collectionId={collectionId}
          startDisabled={deck?.deckCardsNumber === 0}
        />
      </Modal>
    </Wrapper>
  );
};
