import styled from 'styled-components';

export const Wrap = styled.form`
  padding: 40px;

  .form {
    &__field {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
    }

    &__field--label {
      width: 35%;
    }

    &__field--input {
      width: 65%;
    }

    &__field--block {
    }

    &__box--label {
      width: 100%;
      margin-top: 16px;
    }

    &__box--input {
      width: 100%;
      margin-top: 16px;
      margin-bottom: 16px;
    }

    &__field--date {
      margin-right: 10px;
    }

    &__field--pickers {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;
    }

    &__buttons {
      display: flex;
      margin-top: 16px;
      font-family: 'Roboto';
    }
    &__buttons button:first-child {
      margin-right: 16px;
    }

    &__field--label {
      display: flex;
      align-items: center;

      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #254555;
    }

    &__field--icon {
      margin-right: 8px;
    }
  }
`;
