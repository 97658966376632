import React from 'react';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Tag } from 'components/Tag';

import { ReactComponent as ClearInput } from '../../assets/clear.svg';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';
import { Wrapper } from './style';

interface Props {
  tagColor: string;
  title: string;
  tagText: string;
  search: string;
  onChange: (value: string) => void;
  onSubmit: (value: string) => void;
  style?: React.CSSProperties;
}

const Label = () => (
  <p className='safmeds__label'>
    <span className='safmeds__label--text'>Search</span>
  </p>
);

export const HeadingWithInput: React.FC<Props> = ({
  tagColor,
  title,
  tagText,
  search,
  onChange,
  onSubmit,
  style
}) => {
  const [value, setValue] = React.useState<string>(search);
  const handleChage = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValue(e.target.value);
    onChange?.(e.target.value);
  };
  const handleReset = () => {
    setValue('');
    onChange('');
    onSubmit('');
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(value);
  };

  return (
    <Wrapper>
      <div className='safmeds__upper-text' style={style}>
        <h5>{title}</h5>
        <Tag color={tagColor} text={tagText} />
      </div>
      <form
        data-testid='form'
        onSubmit={(e) => handleSubmit(e)}
        className='safmeds__form'
      >
        <TextField
          id='outlined-basic'
          name='search'
          value={value}
          label={<Label />}
          className='safmeds__input'
          onChange={(e) => handleChage?.(e)}
          inputProps={{
            'data-testid': 'input'
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                className='safmeds__input-adornment'
                position='end'
              >
                <IconButton
                  type='button'
                  aria-label='search'
                  onClick={() => onSubmit(value)}
                >
                  <SearchIcon />
                </IconButton>
                {search && (
                  <IconButton
                    className='safmeds__input--clear'
                    aria-label='clear'
                    data-testid='reset'
                    onClick={() => handleReset()}
                  >
                    <ClearInput />
                  </IconButton>
                )}
              </InputAdornment>
            )
          }}
          variant='outlined'
          size='small'
        />
      </form>
    </Wrapper>
  );
};
