import React from 'react';
import { Button } from 'components/Button';
import { convertTimeToCSTFormat } from 'helpers/convertTimeToCSTFormat';
import { theme } from 'theme';

import { Wrapper } from '../style';

interface Props {
  title: string;
  date: string;
  backgroundColor?: string;
  isCronCompleted?: boolean;
  isPassed?: boolean;
  disabled?: boolean;
  percent?: number;
  onOpen?: () => void;
}

export const ExamAssessment: React.FC<Props> = ({
  title,
  date = '',
  percent,
  isCronCompleted = false,
  isPassed = false,
  disabled = false,
  onOpen,
  backgroundColor = theme.palette.white
}) => {
  const handleClick = () => (!disabled ? onOpen : () => {});

  return (
    <Wrapper
      onClick={handleClick()}
      disabled={disabled}
      style={{ backgroundColor: backgroundColor }}
      data-testid='assessment'
    >
      <span className='title'>{title}</span>
      <span className='date'>{convertTimeToCSTFormat(date)}</span>
      <span className={`result ${!isPassed ? 'notCompleted' : ''}`}>
        {isPassed
          ? `${isCronCompleted ? 'Scored' : 'Completed'} (${percent}% correct)`
          : 'Scoring in progress'}
      </span>
      <Button
        text='View'
        onClick={handleClick()}
        className={disabled ? 'hidden' : ''}
        disabled={disabled}
        transparent
        paddingX={9}
        paddingY={3}
      />
    </Wrapper>
  );
};
