import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { api } from 'api/index';
import { Assessment } from 'components/Assessment';
import { Button } from 'components/Button';
import { Preloader } from 'components/Preloader';
import { paths } from 'config/paths';
import { Attempt } from 'interfaces/attempts';
import { CertificationDTO } from 'interfaces/certification';
import { theme } from 'theme';

import { Wrapper } from './style';

type RouteParams = {
  certificationSlug: string;
};

export const MockExam: React.FC = () => {
  const history = useHistory();
  const params = useParams<RouteParams>();
  const [isPreloaderActive, setIsPreloaderActive] = useState<any>(true);
  const [attempts, setAttempts] = useState<Array<Attempt> | null>([]);
  const [certification, setCertification] = useState<CertificationDTO>();

  const handeOpenAttempt = (state: {
    reportId: string;
    attemptNumber: number;
  }) => {
    history.push({
      pathname: paths.result(),
      state
    });
  };

  useEffect(() => {
    Promise.all([
      api.attempt.getAttemptsAPI(params.certificationSlug),
      api.cert.getCertificationsAPI()
    ])
      .then(([attemptsRes, certificationsRes]) => {
        setCertification(
          certificationsRes.find(
            (c) => c.certificationSlug === params.certificationSlug
          )
        );
        sessionStorage.setItem('attempts', String(attemptsRes.length + 1));
        setAttempts(attemptsRes);
      })
      .then((res) => setIsPreloaderActive(false));
  }, [params]);

  return (
    <>
      {!isPreloaderActive && attempts !== null ? (
        <Wrapper>
          <div className='welcome'>
            <p className='welcome__title'>
              <Button
                text='Back'
                className='header--back'
                paddingX={9}
                paddingY={3}
                mode='prev'
                color={theme.palette.white}
                textColor={theme.palette['dark-lavender']}
                borderColor={theme.palette['dark-lavender']}
                arrowColor={theme.palette['dark-lavender']}
                hoverArrowColor={theme.palette.white}
                onClick={() =>
                  history.push(paths.fork(params.certificationSlug))
                }
              />
              <span className='welcome__text'>
                {certification?.certification} Certification - Mock Exam
              </span>
            </p>
            <p className='welcome__description'>
              Previous assessments
              <Button
                text='Start exam'
                onClick={() => {
                  history.push(paths.quiz(certification?.certificationSlug));
                }}
                className='start-btn'
              />
            </p>
          </div>
          {attempts.length > 0 ? (
            attempts.map((attempt, id) => (
              <Assessment.Exam
                key={attempt._id}
                title={`Attempt ${id + 1}`}
                date={attempt.createdAt}
                percent={attempt.percent}
                isPassed={attempt.isCompleted}
                disabled={!attempt.isCompleted}
                isCronCompleted={attempt.isCronCompleted}
                onOpen={() =>
                  handeOpenAttempt({
                    reportId: attempt.reportId,
                    attemptNumber: id + 1
                  })
                }
                backgroundColor='rgba(167, 182, 232, 0.25)'
              />
            ))
          ) : (
            <div className='welcome' style={{ textAlign: 'center' }}>
              No assessments found
            </div>
          )}
        </Wrapper>
      ) : (
        <Preloader />
      )}
    </>
  );
};
