import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { api } from 'api/index';
import { Button } from 'components/Button';
import { DetailedResult } from 'components/DetailedResult';
import { Preloader } from 'components/Preloader';
import { SuggestionsResult } from 'components/SuggestionsResult';
import { SummaryResult } from 'components/SummaryResult';
import { paths } from 'config/paths';
import { convertTimeToCSTFormat } from 'helpers/convertTimeToCSTFormat';
import { getTimeBySeconds } from 'helpers/getTimeBySeconds';
import { printPDFDocumentWithName } from 'helpers/printPDFDocument';
import { CertificationDTO } from 'interfaces/certification';
import { Report } from 'interfaces/result';
import { theme } from 'theme';

import { Wrapper } from './style';

interface LocationState {
  timeInfo: {
    started: string;
    completed: string;
  };
  attemptId: string;
  reportId: string;
  attemptNumber: number;
}

export const Result: React.FC = () => {
  const [report, setReport] = useState<Report | null>(null);
  const [certification, setCertification] = useState<CertificationDTO>();
  const fullName = localStorage.getItem('fullName') || '';

  const location = useLocation<LocationState>();
  const history = useHistory();

  useEffect(() => {
    location?.state?.reportId
      ? api.report
          .getReportAPI(location?.state?.reportId)
          .then((res) => setReport(res))
      : location?.state?.attemptId
      ? api.exam
          .reportGenerationAPI({
            timeInfo: location.state.timeInfo,
            attemptId: location.state.attemptId
          })
          .then((res) => {
            setReport(res);
          })
      : history.push(paths.home());

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (report?.certification) {
      api.cert
        .getCertificationBySlugAPI(report?.certification)
        .then(setCertification);
    }
  }, [report]);

  const handlePrint = () => {
    printPDFDocumentWithName(fullName);
  };

  return (
    <>
      {report?.details && certification ? (
        <Wrapper>
          <div className='result__header'>
            <Button
              text={!location?.state?.attemptId ? 'Back' : 'Finish'}
              className='result__header--back'
              paddingX={9}
              paddingY={3}
              mode='prev'
              color={theme.palette.white}
              textColor={theme.palette['dark-lavender']}
              borderColor={theme.palette['dark-lavender']}
              arrowColor={theme.palette['dark-lavender']}
              hoverArrowColor={theme.palette.white}
              onClick={() => history.push(paths.mockExam(report.certification))}
            />
            <span>
              <span className='result__header--name'>Your </span>
              <span className='result__header--print-name'>
                {fullName + "'s "}
              </span>
              assessment results
            </span>
            <Button
              text='Print'
              className='result__header--print'
              paddingX={9}
              paddingY={3}
              color={theme.palette.white}
              textColor={theme.palette['dark-lavender']}
              borderColor={theme.palette['dark-lavender']}
              arrowColor={theme.palette['dark-lavender']}
              hoverArrowColor={theme.palette.white}
              onClick={handlePrint}
            />
          </div>
          <div className='result__overall'>
            <span className='result__overall__title'>
              {`${report.certificationName} Certification - Mock attempt ${
                location?.state?.attemptNumber || 1
              } details`}
            </span>
            <div className='result__overall__info'>
              <div className='result__overall__info__block'>
                <span className='result__overall__info__block__title'>
                  Started:
                </span>
                <span className='result__overall__info__block__value'>
                  {convertTimeToCSTFormat(report.details.timeInfo.startedAt)}
                </span>
              </div>
              <div className='result__overall__info__block'>
                <span className='result__overall__info__block__title'>
                  Completed:
                </span>
                <span className='result__overall__info__block__value'>
                  {convertTimeToCSTFormat(report.details.timeInfo.completedAt)}
                </span>
              </div>
              <div className='result__overall__info__block'>
                <span className='result__overall__info__block__title'>
                  Total time:
                </span>
                <span className='result__overall__info__block__value'>
                  {getTimeBySeconds(report.details.timeInfo.totalTime)}
                </span>
              </div>
              <div className='result__overall__info__block'>
                <span className='result__overall__info__block__title'>
                  Time remaining:
                </span>
                <span className='result__overall__info__block__value'>
                  {getTimeBySeconds(report.details.timeInfo.remainingTime)}
                </span>
              </div>
            </div>
            <div className='result__overall__info'>
              <div className='result__overall__info__block'>
                <span className='result__overall__info__block__title'>
                  Questions Answered:
                </span>
                <span className='result__overall__info__block__value'>
                  {`${
                    report.details.questions - report.details.skippedQuestions
                  }/${report.details.questions}`}
                </span>
              </div>
              <div className='result__overall__info__block'>
                <span className='result__overall__info__block__title'>
                  Correct:
                </span>
                <span className='result__overall__info__block__value'>{`${report.details.correctAnswers}`}</span>
              </div>
              <div className='result__overall__info__block'>
                <span className='result__overall__info__block__title'>
                  Incorrect:
                </span>
                <span className='result__overall__info__block__value'>{`${report.details.incorrectAnswers}`}</span>
              </div>
              <div className='result__overall__info__block'>
                <span className='result__overall__info__block__title'>
                  Skipped:
                </span>
                <span className='result__overall__info__block__value'>{`${report.details.skippedQuestions}`}</span>
              </div>
            </div>
          </div>
          <div className='pagebreak'> </div>
          <SummaryResult topics={report.topicsStats} />
          <div className='pagebreak'> </div>
          <DetailedResult
            topics={report.topicsStats}
            subTopics={report.subTopicStats}
          />
          <div className='pagebreak'> </div>
          <SuggestionsResult
            topics={report.topicsStats}
            isPrevAttempt={!location?.state?.attemptId}
            certification={certification}
          />
        </Wrapper>
      ) : (
        <Preloader />
      )}
    </>
  );
};
