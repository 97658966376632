import styled from 'styled-components';

export const Wrapper = styled.main`
  .header {
    &__title {
      font-weight: 300;
      font-size: 60px;
      line-height: 120%;
      margin-bottom: 16px;
      letter-spacing: -0.5px;
      line-height: 120%;
      color: #254555;

      @media (max-width: ${(props) => props.theme.breakpoints.md}) {
        font-size: 40px;
      }
    }
    &__description {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 175%;
      /* identical to box height, or 28px */

      letter-spacing: 0.15px;

      color: #254555;
    }
  }

  .content__subtitle {
    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
      font-size: 14px;
    }
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 32px;

    margin-top: 70px;

    @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 15px;
      margin-top: 50px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
      grid-template-columns: repeat(1, 1fr);
      margin-top: 30px;
    }
  }

  .card {
    text-align: center;
    border: 1px solid #d5d5d5;
    box-shadow: 0px 12px 40px 1px rgba(0, 0, 0, 0.06);
    border-radius: 8px;

    padding: 24px;
    &__title {
      margin: 0;
      font-weight: 400;
    }
    &__description {
      line-height: 28px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      margin-top: 8px;
      padding-top: 8px;

      border-top: 1px solid #d5d5d5;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
      padding: 15px;
      &__title {
        font-size: 16px;
      }
      &__description {
        font-size: 14px;
      }
    }
  }
`;
