import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { api } from 'api/index';
import { Button } from 'components/Button';
import { Preloader } from 'components/Preloader';
import { ProgressChart } from 'components/ProgressChart';
import { Table } from 'components/Table';
import { convertTimeToCSTFormat } from 'helpers/convertTimeToCSTFormat';
import { printPDFDocumentWithName } from 'helpers/printPDFDocument';
import { Location } from 'history';
import { ReportTableAttemptsDto, ReportTableDto } from 'interfaces/attempts';
import { theme } from 'theme';

import { Wrapper } from './style';

interface LocationState {
  deckId: string;
  collectionId: string;
  sectionId: string;
}

const getTime = (
  data: ReportTableAttemptsDto
): {
  correct: number;
  incorrect: number;
  skipped: number;
} => {
  return {
    correct: (data.correct / data.duration) * 60,
    incorrect: (data.incorrect / data.duration) * 60,
    skipped: (data.skipped / data.duration) * 60
  };
};

export const DeckReport: React.FC = () => {
  const [reportByDates, setReport] = useState<ReportTableDto>();
  const [tableMode, setTableMode] = useState<'day' | 'all'>('day');
  const [isPreloaderActive, setIsPreloaderActive] = useState<any>(true);
  const history = useHistory();
  const location: Location<LocationState> = useLocation();
  const { deckId, collectionId, sectionId } = location.state;
  const fullName = localStorage.getItem('fullName') || '';

  const handleSortByDate = (
    array: ReportTableDto['attempts']
  ): ReportTableDto['attempts'] => {
    return array.sort((a, b) => {
      return (
        (new Date(b.completedAt) as any) - (new Date(a.completedAt) as any)
      );
    });
  };

  const getBestResultByDay = (
    array: ReportTableDto['attempts'],
    date: ReportTableAttemptsDto['dayCompletedAt']
  ) => {
    const sorted = array
      .filter((item) => item.dayCompletedAt.includes(date))
      .sort((a, b) => {
        const aData = getTime(a);
        const bData = getTime(b);

        if (aData.correct === bData.correct) {
          if (aData.incorrect === bData.incorrect) {
            return aData.skipped - bData.skipped;
          } else {
            return aData.incorrect - bData.incorrect;
          }
        } else {
          return bData.correct - aData.correct;
        }
      });

    return sorted.slice(0, 1);
  };

  const sortedByDates = useMemo(() => {
    if (reportByDates?.attempts.length)
      return handleSortByDate(reportByDates?.attempts);

    return [];
  }, [reportByDates?.attempts]);

  const estConvertedAttempts: ReportTableAttemptsDto[] | undefined =
    useMemo(() => {
      if (sortedByDates.length) {
        const copy = [...sortedByDates].map((att) => ({ ...att }));
        return copy.map((attempt) => {
          attempt.completedAt = convertTimeToCSTFormat(attempt.completedAt);
          attempt.dayCompletedAt = attempt.completedAt
            .substring(0, 10)
            ?.split(' ')[0]
            ?.trim();
          return attempt;
        });
      }

      return [];
    }, [sortedByDates]);

  const reportTable: JSX.Element[] = useMemo(
    () =>
      Array.from(
        new Set(estConvertedAttempts?.map((item: any) => item.dayCompletedAt))
      ).map((date: any) => {
        const attempts =
          tableMode === 'all'
            ? estConvertedAttempts.filter((item: any) =>
                item?.dayCompletedAt?.includes(date)
              )
            : getBestResultByDay(estConvertedAttempts, date);
        return (
          <Table
            tableDate={date}
            isRowHeader={tableMode === 'all'}
            attempts={attempts}
            key={date}
          />
        );
      }),
    [tableMode, estConvertedAttempts]
  );

  const handlePrint = () => {
    printPDFDocumentWithName(fullName);
  };

  useEffect(() => {
    api.attempt
      .postSafmedsAttemptPrevious({ deckId, collectionId, sectionId })
      .then((res) => {
        setIsPreloaderActive(true);
        setReport(res);
      })
      .then((res) => setIsPreloaderActive(false))
      .catch((error) => error.response);
  }, [collectionId, sectionId, deckId]);

  return (
    <>
      {!isPreloaderActive ? (
        <>
          <Wrapper>
            <div className='deck-report__table-container'>
              <div className='deck-report__upper-text'>
                <div
                  style={{
                    marginRight: '16px'
                  }}
                >
                  <Button
                    mode='prev'
                    text='BACK'
                    color={theme.palette.white}
                    textColor={theme.palette['dark-lavender']}
                    borderColor={theme.palette['dark-lavender']}
                    arrowColor={theme.palette['dark-lavender']}
                    hoverArrowColor={theme.palette.white}
                    onClick={() => history.goBack()}
                  />
                </div>
                <h5
                  style={{
                    marginRight: '16px'
                  }}
                >
                  Deck report
                </h5>
                <Button
                  text='Print'
                  className='deck-report__print'
                  paddingX={9}
                  paddingY={3}
                  color={theme.palette.white}
                  textColor={theme.palette['dark-lavender']}
                  borderColor={theme.palette['dark-lavender']}
                  arrowColor={theme.palette['dark-lavender']}
                  hoverArrowColor={theme.palette.white}
                  onClick={handlePrint}
                />
              </div>
              <div className='deck-report__table__header-container'>
                <h5 className='deck-report__deck-title'>
                  {reportByDates?.details?.name}
                </h5>
                <p
                  style={{
                    marginRight: '16px'
                  }}
                >
                  {`${reportByDates?.details?.collectionName} - ${
                    reportByDates?.details?.deckCardsNumber
                  } ${
                    reportByDates?.details?.deckCardsNumber === 1
                      ? 'card'
                      : 'cards'
                  }`}
                </p>
              </div>
              {reportByDates && reportByDates.attempts.length > 0 ? (
                <>
                  <ProgressChart
                    report={reportByDates}
                    aimMin={reportByDates?.options?.safmedsAimLineMin || 28}
                    aimMax={reportByDates?.options?.safmedsAimLineMax || 40}
                  />
                  <div className='deck-report'>
                    <h5 className='deck-report-text'>Results</h5>
                    <div className='deck-report__filter'>
                      <div className='deck-report__filter__switch'>
                        <Button
                          text='Best of the day'
                          paddingX={9}
                          paddingY={3}
                          transparent={tableMode === 'all'}
                          onClick={() => setTableMode('day')}
                        />
                        <Button
                          text='All results'
                          paddingX={9}
                          paddingY={3}
                          transparent={tableMode === 'day'}
                          onClick={() => setTableMode('all')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='deck-report__table__wrap'>
                    <table
                      aria-describedby='deck-report-table'
                      className='deck-report__table'
                    >
                      <thead className='deck-report__table__head'>
                        <tr className='deck-report__table__row'>
                          <th
                            className='deck-report__table__head-cell'
                            style={{ width: '16%' }}
                          >
                            Correct
                          </th>
                          <th
                            className='deck-report__table__head-cell'
                            style={{ width: '16%' }}
                          >
                            Incorrect
                          </th>
                          <th
                            className='deck-report__table__head-cell'
                            style={{ width: '16%' }}
                          >
                            Skipped
                          </th>
                          <th
                            className='deck-report__table__head-cell'
                            style={{ width: '25%' }}
                          >
                            Duration
                          </th>
                          <th
                            className='deck-report__table__head-cell'
                            style={{ width: '25%' }}
                          >
                            Date
                          </th>
                        </tr>
                      </thead>
                      <tbody className='deck-report__table__body'>
                        {reportTable}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <div className='deck-report__table-container__no-attempts'>
                  No attempts found
                </div>
              )}
            </div>
          </Wrapper>
          {isPreloaderActive && <Preloader />}
        </>
      ) : (
        <Preloader />
      )}
    </>
  );
};
