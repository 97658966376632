import React from 'react';

import { Wrapper } from './style';

interface Props {
  isAbsolute?: boolean;
  isLightMode?: boolean;
}

export const Preloader: React.FC<Props> = ({ isAbsolute, isLightMode }) => {
  return (
    <Wrapper isAbsolute={!!isAbsolute} isLightMode={!!isLightMode}>
      <div>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </Wrapper>
  );
};
