import styled from 'styled-components';

export const Wrapper = styled.div`
  .welcome {
    margin-bottom: 24px;
    margin-top: 34px;

    @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
      flex-direction: column;
    }

    &__title {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-family: Roboto;
      font-size: 24px;
      font-weight: 400;
      letter-spacing: 0px;
      text-align: left;
      padding-bottom: 18px;
      border-bottom: 1px solid ${(props) => props.theme.palette.background};
      margin-bottom: 39px;
      width: 100%;

      button {
        margin-right: 10px;
      }

      @media (max-width: ${(props) => props.theme.breakpoints.md}) {
        font-size: 22px;
      }

      @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        font-size: 18px;
      }
    }

    &__description {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-family: Roboto;
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0px;
      text-align: left;
      margin-bottom: 16px;

      @media (max-width: ${(props) => props.theme.breakpoints.md}) {
        font-size: 22px;
      }

      @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        font-size: 18px;
      }
    }
  }

  .start-btn {
    @media (max-width: ${(props) => props.theme.breakpoints.md}) {
      width: initial;
      margin: 0;
      padding: 7px 10px;
      line-height: 22px;
    }
  }

  .cards {
    display: flex;
    justify-content: space-between;
    margin-top: 68px;
    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
      flex-direction: column;
      margin-top: 30px;
    }
  }

  .previousAssessments {
    margin-top: 120px;
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 18px;
    border-bottom: 1px solid ${(props) => props.theme.palette.background};
    margin-bottom: 16px;

    @media (max-width: ${(props) => props.theme.breakpoints.md}) {
      margin-top: 30px;
    }
  }
`;
