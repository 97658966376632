import {
  DataGetOrganizationDto,
  ResponseCollectionsDTO
} from 'interfaces/graphOrganization';
import { Organization } from 'interfaces/organizations';

import { APIService } from './api';

type ReactLogParams = {
  questionId: string;
  error: string;
  email: string;
};

export class Organizations extends APIService {
  isMockedApi: boolean;

  constructor(isMockedApi: boolean = false) {
    super();
    this.isMockedApi = isMockedApi;
  }

  organizationList(): Promise<Array<Organization>> {
    if (this.isMockedApi) {
      return new Promise((resolve) =>
        setTimeout(resolve, 1000, require('../mocks/result.json'))
      );
    }

    return this.get<Array<Organization>>(`/api/organization`);
  }

  getOrganization(): Promise<DataGetOrganizationDto> {
    if (this.isMockedApi) {
      return new Promise((resolve) =>
        setTimeout(resolve, 1000, require('../mocks/decks.json'))
      );
    }

    return this.get<DataGetOrganizationDto>(`/api/graphOrganization`);
  }

  getCollection(collectionId: string): Promise<ResponseCollectionsDTO> {
    if (this.isMockedApi) {
      return new Promise((resolve) =>
        setTimeout(resolve, 1000, require('../mocks/decks.json'))
      );
    }

    return this.get<ResponseCollectionsDTO>(
      `/api/graphCollection/${collectionId}`
    );
  }

  postReactLog(body: ReactLogParams): Promise<any> {
    return this.post<ReactLogParams, any>(`/api/reactLog`, body);
  }
}
