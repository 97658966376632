import React from 'react';
import { Button } from 'components/Button';

import { CardItem } from './style';

interface ForkPageCardProps {
  title: string;
  description: string;
  buttonText: string;
  buttonHref: string;
}

export const ForkPageCard: React.FC<ForkPageCardProps> = ({
  title,
  description,
  buttonText,
  buttonHref
}) => (
  <CardItem>
    <h5>{title}</h5>
    <p>{description}</p>
    <a
      target='_blank'
      href={buttonHref}
      id={buttonHref}
      rel='noopener noreferrer'
      {...{ name: buttonHref }}
    >
      <Button
        text={buttonText.toUpperCase()}
        type='button'
        disabled
        className='card-item__button'
      />
    </a>
  </CardItem>
);
