import { FC, useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { api } from 'api/index';
import { Preloader } from 'components/Preloader';
import { paths } from 'config/paths';
import { CertificationDTO } from 'interfaces/certification';

import { Wrapper } from './style';

type TState = {
  loading: boolean;
  data: CertificationDTO[] | null;
  error: Error | null;
};

export const Home: FC = () => {
  const [state, setState] = useState<TState>({
    loading: true,
    data: null,
    error: null
  });

  const handleClick = (slug: string) => {
    localStorage.setItem('certificationSlug', slug);
  };

  useEffect(() => {
    localStorage.removeItem('certificationSlug');
    api.cert
      .getCertificationsAPI()
      .then((data) => {
        setState({
          loading: false,
          data,
          error: null
        });
      })
      .catch((error) => {
        setState({
          loading: false,
          data: null,
          error
        });
      });
  }, []);

  if (state.loading) return <Preloader />;

  if (!!state.error) {
    throw Error(state.error.message);
  }

  if (state.data?.length === 1) {
    const { certificationSlug } = state.data[0];
    localStorage.setItem('certificationSlug', certificationSlug);

    return <Redirect to={paths.fork(certificationSlug)} />;
  }

  return (
    <Wrapper>
      <div className='header'>
        <h1 className='header__title'>
          {`Welcome back, ${
            localStorage.getItem('fullName')?.split(' ')[0]?.trim() || ''
          }!`}
        </h1>
        <span className='header__description'>
          Select a certification you want to study for:
        </span>
      </div>
      <div className='content'>
        {state.data?.length ? (
          <div className='cards'>
            {state.data.map((card) => (
              <Link
                key={card.certificationSlug}
                to={paths.fork(card.certificationSlug)}
                onClick={() => handleClick(card.certificationSlug)}
                className='card'
              >
                <h3 className='card__title'>{card.certification}</h3>
                {card.certificationDescription?.text && (
                  <p
                    className='card__description'
                    dangerouslySetInnerHTML={{
                      __html: card.certificationDescription.text
                    }}
                  ></p>
                )}
              </Link>
            ))}
          </div>
        ) : (
          <p>No certifications available. Please contact your Administrator.</p>
        )}
      </div>
    </Wrapper>
  );
};
