// import deleteIcon from 'assets/delete.svg';
// import edit from 'assets/edit.svg';
import { ChartEvent } from 'chart.js';
import { EventContext } from 'chartjs-plugin-annotation';
import { Measurement } from 'interfaces/measurment';
import moment from 'moment';

import { convertTimeToCSTFormat } from './convertTimeToCSTFormat';
import { secondsToHMS } from './utils';

const edit = `<path d="M12.5007 5.14302L15.0007 7.64302M4.16732 13.4764L3.33398 16.8097L6.66732 15.9764L16.3223 6.32135C16.6348 6.00881 16.8103 5.58496 16.8103 5.14302C16.8103 4.70108 16.6348 4.27723 16.3223 3.96468L16.179 3.82135C15.8664 3.5089 15.4426 3.33337 15.0007 3.33337C14.5587 3.33337 14.1349 3.5089 13.8223 3.82135L4.16732 13.4764Z" stroke="#254555" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>`;
const deleteIcon = `<path d="M11.6673 9.16667V14.1667M8.33398 9.16667V14.1667M5.00065 5.83333V15.8333C5.00065 16.2754 5.17625 16.6993 5.48881 17.0118C5.80137 17.3244 6.22529 17.5 6.66732 17.5H13.334C13.776 17.5 14.1999 17.3244 14.5125 17.0118C14.8251 16.6993 15.0007 16.2754 15.0007 15.8333V5.83333M3.33398 5.83333H16.6673M5.83398 5.83333L7.50065 2.5H12.5007L14.1673 5.83333" stroke="#DE543C" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>`;

const getOrCreateTooltip = async (
  text: string,
  canvas: HTMLCanvasElement,
  x: number,
  y: number
) => {
  let tooltipEl = canvas.parentNode?.querySelector('div');

  if (tooltipEl) {
    await canvas.parentNode?.removeChild(tooltipEl);
  }

  tooltipEl = document.createElement('div');
  tooltipEl.setAttribute('id', 'chartjs-tooltip1');
  tooltipEl.style.background = '#fff';
  tooltipEl.style.borderRadius = '3px';
  tooltipEl.style.color = 'white';
  tooltipEl.style.opacity = '1';
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.transform = 'translate(-50%, 30%)';
  tooltipEl.style.transition = 'all .1s ease';
  tooltipEl.style.display = 'block';
  tooltipEl.style.boxShadow = '0px 4px 4px rgba(0, 0, 0, 0.1)';
  tooltipEl.style.width = '130px';
  tooltipEl.style.height = 'auto';
  tooltipEl.style.left = `${x}px`;
  tooltipEl.style.top = `${y}px`;

  const table = document.createElement('table');
  table.style.margin = '0px';
  table.style.display = 'grid';

  const head = document.createElement('div');

  head.innerText = text;

  head.style.position = 'absolute';
  head.style.background = 'rgba(0, 0, 0, 0.7)';
  head.style.top = '-45px';
  head.style.borderRadius = '3px';
  head.style.padding = '3px';
  head.style.setProperty(
    'left',
    `calc(${table.offsetWidth}px - ${head.offsetWidth}px)`
  );
  head.style.whiteSpace = 'nowrap';

  tooltipEl.appendChild(head);
  tooltipEl.appendChild(table);
  canvas.parentNode?.appendChild(tooltipEl);

  return tooltipEl;
};

const renderTooltipButtons = (
  parent: any,
  color: string,
  imgSrc: string,
  border: boolean = false,
  text: string,
  cb: () => void
) => {
  const btn = document.createElement('button');
  const img = document.createElement('svg');

  img.innerHTML = imgSrc;

  img.style.fill = 'none';
  img.style.width = '20px';
  img.style.height = '20px';
  img.style.margin = '0 15px 0 0';

  btn.style.background = 'transparent';
  btn.style.border = 'none';
  btn.style.borderTop = border ? '1px solid rgba(37, 69, 85, 0.15)' : 'none';
  btn.style.color = color;
  btn.style.width = '100%';
  btn.style.display = 'flex';
  btn.style.justifyContent = 'flex-start';
  btn.style.alignItems = 'center';
  btn.style.padding = '5px';
  btn.style.cursor = 'pointer';
  btn.style.fontFamily = 'Roboto, sans-serif';
  btn.style.fontSize = '16px';
  btn.onclick = cb;

  btn.appendChild(img);
  btn.innerHTML += text;

  parent.appendChild(btn);
};

export const renderTooltip = (
  text: string,
  ctx: EventContext,
  event: ChartEvent & { chart: { canvas: HTMLCanvasElement } },
  onEdit: () => void,
  onDelete: () => void
) => {
  // Tooltip Element
  const { canvas } = event.chart;

  // @ts-ignore
  getOrCreateTooltip(text, canvas, ctx.element.x, ctx.element.height / 2).then(
    (tooltipEl) => {
      const tableRoot = tooltipEl.querySelector('table');

      renderTooltipButtons(tableRoot, 'black', edit, false, 'Edit', onEdit);
      renderTooltipButtons(
        tableRoot,
        'red',
        deleteIcon,
        true,
        'Delete',
        onDelete
      );
    }
  );
};

const createTooltip = (
  chart: Pick<EventContext, 'chart'> & { canvas: HTMLCanvasElement },
  measurment: Measurement,
  type: 'Counting' | 'Correct' | 'Incorrect' | 'Custom' | 'Counting time'
) => {
  let tooltipEl = chart.canvas.parentNode?.querySelector('div');

  if (tooltipEl) {
    chart.canvas.parentNode?.removeChild(tooltipEl);
  }

  tooltipEl = document.createElement('div');
  tooltipEl.setAttribute('id', 'chartjs-tooltip');
  tooltipEl.setAttribute('data-testid', 'chartjs-tooltip');
  tooltipEl.style.background = '#fff';
  tooltipEl.style.borderRadius = '3px';
  tooltipEl.style.color = 'white';
  tooltipEl.style.opacity = '1';
  // tooltipEl.style.pointerEvents = 'none';
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.transform = 'translate(-50%, 30%)';
  tooltipEl.style.transition = 'all .1s ease';
  tooltipEl.style.display = 'block';
  tooltipEl.style.boxShadow = '0px 4px 4px rgba(0, 0, 0, 0.1)';
  tooltipEl.style.width = '130px';
  tooltipEl.style.height = '60px';

  const table = document.createElement('table');
  table.style.margin = '0px';
  table.style.display = 'grid';

  const head = document.createElement('div');

  const handleGetText = (
    meas: Measurement,
    type: 'Counting' | 'Correct' | 'Incorrect' | 'Custom' | 'Counting time'
  ) => {
    const { dateTime, countingTime, correct, incorrect, custom } = meas;

    switch (type) {
      case 'Counting time':
        return `${moment(convertTimeToCSTFormat(dateTime)).format(
          'M/D/YYYY'
        )} Counting: ${secondsToHMS(countingTime)}`;
      case 'Correct':
        return `${moment(convertTimeToCSTFormat(dateTime)).format(
          'M/D/YYYY'
        )} Correct: ${correct}`;
      case 'Incorrect':
        return `${moment(convertTimeToCSTFormat(dateTime)).format(
          'M/D/YYYY'
        )} Incorrect: ${incorrect}`;
      case 'Custom':
        return `${moment(convertTimeToCSTFormat(dateTime)).format(
          'M/D/YYYY'
        )} Custom: ${custom ? custom : 0}`;
      default:
        return '';
    }
  };

  head.innerText = measurment ? handleGetText(measurment, type) : '';

  head.style.position = 'absolute';
  head.style.background = 'rgba(0, 0, 0, 0.7)';
  head.style.top = '-45px';
  head.style.borderRadius = '3px';
  head.style.padding = '3px';
  head.style.setProperty(
    'left',
    `calc(${table.offsetWidth}px - ${head.offsetWidth}px)`
  );
  head.style.whiteSpace = 'nowrap';

  tooltipEl.appendChild(head);
  tooltipEl.appendChild(table);
  chart.canvas.parentNode?.appendChild(tooltipEl);

  return tooltipEl;
};

export const externalTooltipHandler = (
  context: EventContext & { tooltip: any },
  handleGetTooltipItems: (date: string) => Measurement,
  handleEdit: (id: string) => void,
  handleDelete: (id: string) => void
) => {
  // Tooltip Element
  const { chart, tooltip } = context;

  const measurment = handleGetTooltipItems(
    new Date(tooltip.dataPoints[0].label).toUTCString()
  );

  const tooltipEl = createTooltip(
    chart as any,
    measurment,
    tooltip.dataPoints[0].dataset.label
  );

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0';
    return;
  }

  // Set Text
  if (tooltip.body) {
    const tableRoot = tooltipEl.querySelector('table');

    // Remove old children
    while (tableRoot?.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    renderTooltipButtons(tableRoot, 'black', edit, false, 'Edit', () =>
      handleEdit(measurment.id)
    );
    renderTooltipButtons(tableRoot, 'red', deleteIcon, true, 'Delete', () =>
      handleDelete(measurment.id)
    );
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = '1';
  tooltipEl.style.left = positionX + tooltip.caretX + 'px';
  tooltipEl.style.top = positionY + tooltip.caretY + 'px';
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding =
    tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
};
