import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { api } from 'api/index';
import { Button } from 'components/Button';
import { DeckCard } from 'components/DeckCard';
import { Preloader } from 'components/Preloader';
import { Section } from 'components/Section';
import { paths } from 'config/paths';
import { ResponseCollectionsDTO } from 'interfaces/graphOrganization';
import { SectionDto } from 'interfaces/sections';
import { theme } from 'theme';

import { ReactComponent as ClearInput } from '../../assets/clear.svg';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';
import { ReactComponent as UpArrow } from '../../assets/up-arrow.svg';
import { Wrapper } from './style';

interface RouteParams {
  collection: string;
  section?: string;
}

export const Collection: React.FC = () => {
  const [search, setSearch] = useState<string>('');
  const [sections, setSections] = useState<Array<SectionDto> | null>(null);
  const [selected, setSelected] = useState<SectionDto | null>(null);
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [showMore, setShowMore] = useState<'idle' | 'display' | 'hide'>('idle');
  const [isPreloaderActive, setIsPreloaderActive] = useState<any>(true);
  const [collection, setCollection] = useState<ResponseCollectionsDTO>();
  const params = useParams<RouteParams>();
  const location = useLocation<{ scroll?: boolean }>();
  const history = useHistory();
  const descriptionRef = useRef<HTMLParagraphElement>(null);
  const selectedRef = useRef<HTMLAnchorElement>(null);
  const backBtnRef = useRef<HTMLDivElement>(null);

  const handleResetDescription = () => setShowMore('idle');

  const handleSearch = (searchWord: string) => {
    const filtered = collection?.deckSections?.filter(function ({
      sectionName
    }) {
      return sectionName.toLowerCase().indexOf(searchWord.toLowerCase()) >= 0;
    });
    setSections(filtered || null);
  };

  const handleUpdateSections = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearch(e.target.value.toString());
  };

  const handleSearchSubmit = (e: any) => {
    e.preventDefault();
    handleSearch(e.target[0].value);
  };
  const handleReset = () => {
    handleSearch('');
    setSearch('');
  };

  const handlePrev = () => {
    if (sections?.length) {
      const index = sections?.findIndex(
        (section) => section.id === selected?.id
      );
      if (index > 0 && sections[index - 1]) {
        history.push(sections[index - 1].id);
      }
    }
  };
  const handleNext = () => {
    if (sections?.length) {
      const index = sections?.findIndex(
        (section) => section.id === selected?.id
      );
      if (index < sections.length && sections[index + 1]) {
        history.push(sections[index + 1].id);
      }
    }
  };

  const handleCollapse = (sectionId: string) => {
    return selected?.id === sectionId && setCollapsed(!collapsed);
  };

  useEffect(() => {
    api.org
      .getCollection(params.collection)
      .then((col) => {
        setIsPreloaderActive(true);
        setCollection(col);
        setSections(col.deckSections);

        const section = col.lastSection
          ? col.deckSections.find((item) => item.id === col.lastSection)
          : col.deckSections[0];

        if (params.section) {
          setSelected(
            col.deckSections.find((item) => item.id === params.section) || null
          );
        }

        history.push(
          paths.safmedsCollections(
            col.collectionId,
            params.section || section?.id
          ),
          {
            scroll: true
          }
        );
      })
      .then(() => setIsPreloaderActive(false))
      .catch((e) => console.error(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.collection]);

  useEffect(() => {
    setTimeout(() => {
      if (descriptionRef.current) {
        if (
          descriptionRef.current.offsetHeight <
            descriptionRef.current.scrollHeight ||
          descriptionRef.current.offsetWidth <
            descriptionRef.current.scrollWidth
        ) {
          setShowMore('display');
        } else {
          handleResetDescription();
        }
      }
    }, 0);
  }, [descriptionRef, selected]);

  useEffect(() => {
    if (sections?.length) {
      setSelected(sections.find((item) => item.id === params.section) || null);
      setCollapsed(true);
      handleResetDescription();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.section]);

  useEffect(() => {
    if (location.state?.scroll || !collapsed) {
      setTimeout(() => {
        selectedRef.current?.scrollIntoView();
        backBtnRef.current?.scrollIntoView(); // To start focus state from back button on load page
        window.scrollTo(0, 0);
      }, 0);
    }
  }, [location, collapsed]);

  if (isPreloaderActive) {
    return <Preloader />;
  }

  const Label = () => (
    <p className='safmeds__label'>
      <span className='safmeds__label--text'>Search</span>
    </p>
  );

  return (
    <Wrapper collapsed={collapsed}>
      <div className='safmeds__upper-text'>
        <div
          className='safmeds__back-button'
          ref={backBtnRef}
          style={{
            marginRight: '16px'
          }}
        >
          <Button
            mode='prev'
            text='BACK'
            color={theme.palette.white}
            textColor={theme.palette['dark-lavender']}
            borderColor={theme.palette['dark-lavender']}
            arrowColor={theme.palette['dark-lavender']}
            hoverArrowColor={theme.palette.white}
            onClick={() => history.push(paths.safmeds())}
          />
        </div>
        <h5
          style={{
            marginRight: '16px'
          }}
        >
          {collection?.collectionName}
        </h5>
      </div>
      <section
        className={`safmeds__content-wrap ${
          collapsed ? 'safmeds__content-wrap--collapsed' : ''
        }`}
      >
        <div className='safmeds__content'>
          <div className='safmeds__content--head'>
            <h1 className='safmeds__content--header'>
              {selected?.sectionName}
            </h1>
            <div className='safmeds__content--buttons'>
              {selected?.id !== sections?.[0]?.id && (
                <Button
                  mode='prev'
                  text='Previous'
                  color={theme.palette.white}
                  className='safmeds__content--button prev'
                  textColor={theme.palette['dark-lavender']}
                  borderColor={theme.palette['dark-lavender']}
                  arrowColor={theme.palette['dark-lavender']}
                  hoverArrowColor={theme.palette.white}
                  onClick={() => handlePrev()}
                />
              )}
              {selected?.id !== sections?.[sections?.length - 1]?.id && (
                <Button
                  mode='next'
                  text='Next'
                  color={theme.palette.white}
                  className='safmeds__content--button next'
                  textColor={theme.palette['dark-lavender']}
                  borderColor={theme.palette['dark-lavender']}
                  arrowColor={theme.palette['dark-lavender']}
                  hoverArrowColor={theme.palette.white}
                  onClick={() => handleNext()}
                />
              )}
            </div>
          </div>
          {selected?.sectionDescription?.html && (
            <>
              <p
                ref={descriptionRef}
                className={`safmeds__content--description ${
                  showMore === 'hide'
                    ? ''
                    : 'safmeds__content--description-ellipsed'
                }`}
                dangerouslySetInnerHTML={{
                  __html: selected?.sectionDescription?.html || ''
                }}
              ></p>
              {showMore !== 'idle' && (
                <span
                  onClick={() =>
                    setShowMore(showMore === 'hide' ? 'display' : 'hide')
                  }
                  className='safmeds__content--show-more'
                >
                  {showMore === 'hide' ? 'Hide' : 'Show more'}
                </span>
              )}
            </>
          )}
          <div className='safmeds__content--decks'>
            {collection &&
              selected?.decks.map((deck) => (
                <DeckCard
                  backgroundColor={
                    deck.type === 'Acquisition'
                      ? theme.palette['aquisition-background']
                      : theme.palette['fluency-background']
                  }
                  cardsQty={deck.deckCardsNumber}
                  tagColor={
                    deck.type === 'Acquisition'
                      ? theme.palette.aquisition
                      : theme.palette.fluency
                  }
                  tagText={deck.type}
                  title={deck.name || ''}
                  key={deck.id}
                  deckId={deck.id}
                  collectionId={collection.collectionId}
                  sectionId={selected.id}
                />
              ))}
          </div>
        </div>
        <aside
          className={`safmeds__sidebar ${
            collapsed ? 'safmeds__sidebar--collapsed' : ''
          }`}
        >
          <button
            className='safmeds__collapse'
            onClick={() => setCollapsed(!collapsed)}
            tabIndex={0}
          >
            <UpArrow className='safmeds__collapse--arrow' />
            <UpArrow className='safmeds__collapse--arrow' />
          </button>
          <form onSubmit={handleSearchSubmit} className='safmeds__form'>
            <TextField
              id='outlined-basic'
              name='search'
              value={search}
              label={<Label />}
              className='safmeds__input'
              onChange={(e) => handleUpdateSections(e)}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    className='safmeds__input-adornment'
                    position='end'
                  >
                    <IconButton
                      type='button'
                      aria-label='search'
                      onClick={() => handleSearch(search)}
                    >
                      <SearchIcon />
                    </IconButton>
                    {search && (
                      <IconButton
                        className='safmeds__input--clear'
                        aria-label='clear'
                        onClick={() => handleReset()}
                      >
                        <ClearInput />
                      </IconButton>
                    )}
                  </InputAdornment>
                )
              }}
              variant='outlined'
              size='small'
            />
          </form>
          <ul className='safmeds__sections'>
            {collection && sections?.length
              ? sections.map((section) => (
                  <Section
                    key={section.id}
                    sectionId={section.id}
                    sectionName={section.sectionName}
                    selected={selected?.id || null}
                    collectionId={collection.collectionId}
                    handleCollapse={() => handleCollapse(section.id)}
                    ref={selectedRef}
                  />
                ))
              : 'Nothing found'}
          </ul>
        </aside>
      </section>
    </Wrapper>
  );
};
