import styled from 'styled-components';

export const ChartPageWrapper = styled.div`
	.chart {
    // Chart header ---------
		&__upper-text {
      display: flex;
      flex-direction: row;
      height: 50px;
      align-items: center;
			justify-content: space-between;
      margin-bottom: 16px;

			@media (max-width: ${(props) => props.theme.breakpoints.sm}) {
				display: block;
      }
    }
		&__title-wrap {
			display: flex;
			align-items: center;
			h5 {
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0px;
        text-align: left;
        margin: 0;
      }
      .tag {
        padding: 3px 10px;
        height: 16px;
        margin: 0px 8px;
      }
		}

		&__back-button {
			@media print {
				display: none;
			}
		}

		&__buttons-wrap {
			display: flex;
			align-items: center;
			button {
				margin-right: 16px;
			}
			button:last-child {
				margin-right: 0;
			}

			@media (max-width: ${(props) => props.theme.breakpoints.sm}) {
				margin-top: 16px;
				width: 100%;
				justify-content: space-between;
				button {
					width: 48%;
				}
      }

			@media print {
				display: none;
			}
		}
`;
