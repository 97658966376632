import styled from 'styled-components';

export const Wrapper = styled.div<{
  // Width can be written as a string of appropriate format, like correct CSS values:
  // For example: '10px', '10vw', '10%' etc.
  maxWidth: string;
  width?: string;
  borderRadius: number;
  borderColor: string;
}>`
  div::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  div::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  div::-webkit-scrollbar-thumb {
    background-color: rgba(0, 144, 202, 0.4);
  }
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${(props) => props.theme.palette['dark-cr-gray-opacity']};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  .close-icon {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
  }
  .content {
    max-height: 90%;
    max-width: ${(props) => `${props.maxWidth}`};
    width: ${(props) => `${props.width}`};
    background-color: ${(props) => props.theme.palette.white};
    border-radius: ${(props) => `${props.borderRadius}px`};
    border: ${(props) =>
      props.borderColor ? `1px solid ${props.borderColor}` : 'none'};
    margin: 64px;
    overflow: hidden;
    position: relative;

    @media (max-width: ${(props) => props.theme.breakpoints.md}) {
      margin: 20px;
    }
  }
`;
