import { ReportRequest } from 'interfaces/report';

import { APIService } from './api';

type SendFeedbackAPIParams = {
  questionId: string;
  certificationSlug: string;
  feedback: string;
};

export class Feedback extends APIService {
  isMockedApi: boolean;

  constructor(isMockedApi: boolean = false) {
    super();
    this.isMockedApi = isMockedApi;
  }

  sendFeedbackAPI(body: SendFeedbackAPIParams): Promise<boolean> {
    if (this.isMockedApi) {
      return new Promise((resolve) => setTimeout(resolve, 500, true));
    }

    return this.post<SendFeedbackAPIParams, boolean>('/api/feedback', body);
  }

  feedbackReport(body: ReportRequest): Promise<Blob> {
    if (this.isMockedApi) {
      return new Promise((resolve) =>
        setTimeout(resolve, 1000, require('../mocks/result.json'))
      );
    }

    return this.post<ReportRequest, Blob>('/api/admin/feedbackReport', body, {
      responseType: 'blob'
    });
  }
}
