import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 13px 0 22px;
  margin-bottom: 24px;

  .logo-wrapper {
    display: flex;
    button {
      margin-left: 30px;
    }
  }

  @media print {
    display: none !important;
  }
`;

export const Logo = styled(Link)<{ hidetext?: boolean }>`
  display: flex;
  align-items: center;

  .logo__text {
    max-width: 190px;
    margin-left: 10px;
  }

  span {
    font-family: 'Manrope', sans-serif;
    margin-left: 8px;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.14px;
  }

  .logo-text {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
    color: #002157;
    font-weight: 600;
    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
      font-size: 14px;
    }
  }
`;
