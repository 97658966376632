import styled from 'styled-components';

export const Wrapper = styled.div<{ disabled: boolean; collapsed: boolean }>`
  display: block;
  letter-spacing: 0.15px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.palette.background};
  margin-bottom: 4px;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  box-shadow: ${({ collapsed }) =>
    collapsed ? 'none' : '6px 6px 5px rgba(0, 0, 0, 0.04)'};

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
    width: calc(50% - 8px);
    margin: 4px;
    box-sizing: border-box;
    display: inline-flex;

    & > * {
      margin-bottom: 20px;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin: 4px 0;
    width: 100%;
  }

  .title {
    font-size: 16px;
    line-height: 28px;
    font-weight: 700;
    min-width: 360px;

    display: flex;
    align-items: center;

    @media (max-width: ${(props) => props.theme.breakpoints.md}) {
      min-width: auto;
      font-size: 14px;

      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  button {
    font-size: 13px;
    line-height: 22px;

    &.hidden {
      visibility: hidden;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.md}) {
      font-size: 12px;
    }
  }

  .assessment {
    &__head {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px;
      box-sizing: border-box;
    }
    &__head--icon {
      width: 10px;
      margin-right: 10px;
      rotate: ${({ collapsed }) => (collapsed ? '0' : '180deg')};
    }
    &__description {
      width: 100%;
      height: ${({ collapsed }) => (collapsed ? '0' : 'auto')};
      border-top: ${({ collapsed }) =>
        collapsed ? 'none' : '1px solid rgba(167, 182, 232, 0.25)'};
      padding: ${({ collapsed }) => (collapsed ? '0 16px' : '16px')};
      overflow: hidden;
      margin-top: ${({ collapsed }) => (collapsed ? '0' : '10px')};
      box-sizing: border-box;
      /* transition: 0.3s; */
      display: flex;
    }
    &__description--text {
      width: 100%;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      margin-right: 10px;
    }
    &__description--video video {
      width: 444px;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
      &__head {
        padding: 10px;
        margin: 0;
      }
      &__description {
        display: block;
        margin: 0;
      }
      &__description--text {
        font-size: 12px;
        margin-bottom: ${({ collapsed }) => (collapsed ? '0' : '5px')};
        margin-right: 0;
      }
      &__description--video video {
        width: 100%;
        max-height: 200px;
      }
    }

    /* @media (max-width: ${(props) => props.theme.breakpoints.md}) {
      &__head {
        padding: 10px;
        margin: 0;
      }
      &__description {
        display: block;
        margin: 0;
      }
      &__description--text {
        font-size: 12px;
        margin-bottom: ${({ collapsed }) => (collapsed ? '0' : '5px')};
        margin-right: 0;
      }
      &__description--video video {
        width: 100%;
      }
    } */
  }
`;
