import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;

  .chart {
    &__buttons {
      position: absolute;
      top: 72px;
      right: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #a8a8a8;
      border-radius: 8px;

      @media print {
        display: none;
      }
    }

    &__buttons svg {
      width: 24px;
      height: 24px;
    }

    &__buttons button {
      cursor: pointer;
      padding: 8px;
    }

    &__buttons button:first-child {
      border-bottom: 1px solid #a8a8a8;
    }
  }
`;
