import styled from 'styled-components';

export const Wrap = styled.div`
  padding: 32px;

  .modal {
    &__title {
      font-size: 24px;
      margin-bottom: 8px;
    }
    &__subtitle {
      font-size: 12px;
    }

    &__content {
      margin: 16px 0;
      font-size: 16px;
      line-height: 24px;
    }

    &__buttons {
      display: flex;
      button:first-child {
        margin-right: 16px;
      }
    }
  }
`;
