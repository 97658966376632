import styled, { keyframes } from 'styled-components';

const flipKeyframe = keyframes`
  0% {
    -o-transform: rotateY(0);
    -webkit-transform: rotateY(0);
    -ms-transform: rotateY(0);
    transform: rotateY(0);
  }
  30% {
    -o-transform: rotateY(-20deg);
    -webkit-transform: rotateY(-20deg);
    -ms-transform: rotateY(-20deg);
    transform: rotateY(-20deg);
  }
  50% {
    -o-transform: rotateY(-60deg);
    -webkit-transform: rotateY(-60deg);
    -ms-transform: rotateY(-60deg);
    transform: rotateY(-60deg);
  }
  70% {
    -o-transform: rotateY(-120deg);
    -webkit-transform: rotateY(-120deg);
    -ms-transform: rotateY(-120deg);
    transform: rotateY(-120deg);
  }
  100% {
    -o-transform: rotateY(-180deg);
    -webkit-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
  }
`;

const beckFlipKeyframe = keyframes`
  0% {
    -o-transform: rotateY(-180deg);
    -webkit-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
  }
  30% {
    -o-transform: rotateY(-120deg);
    -webkit-transform: rotateY(-120deg);
    -ms-transform: rotateY(-120deg);
    transform: rotateY(-120deg);
  }
  50% {
    -o-transform: rotateY(-60deg);
    -webkit-transform: rotateY(-60deg);
    -ms-transform: rotateY(-60deg);
    transform: rotateY(-60deg);
  }
  70% {
    -o-transform: rotateY(-20deg);
    -webkit-transform: rotateY(-20deg);
    -ms-transform: rotateY(-20deg);
    transform: rotateY(-20deg);
  }
  100% {
    -o-transform: rotateY(0);
    -webkit-transform: rotateY(0);
    -ms-transform: rotateY(0);
    transform: rotateY(0);
  }
`;

export const Wrapper = styled.div`
  .flip-card-front,
  .flip-card-back {
    &__text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 30% 32px 0 32px;
      font-family: Roboto, sans-serif;
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0px;
      text-align: center;

      blockquote {
        font-size: 16px;
        line-height: 175%;
      }
    }
    &__buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin: 24px 32px;
      &.flip-card-front__buttons {
        flex-direction: column;

        button {
          width: 100%;

          &:first-child {
            margin-bottom: 8px;
          }
        }
      }
      &__flip {
        width: 100% !important;
        margin-bottom: 8px;
      }
      &__incorrect {
        border: 1px solid #f88078;
        border-radius: 4px;
        padding: 7px 21px;
        color: #f88078;
      }
      &__correct {
        border: 1px solid ${(props) => props.theme.palette.green};
        border-radius: 4px;
        padding: 7px 21px;
        color: ${(props) => props.theme.palette.green};
      }
      button {
        width: 172px;
        display: flex;
        justify-content: center !important;
        align-items: center;
      }
      &__flip {
        color: ${(props) => props.theme.palette.white};
        background-color: #3f51b5;
        border-radius: 4px;
        height: 42px;
        .h-22 {
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            margin-left: 12px;
          }
        }
      }

      @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        button {
          width: 49%;
        }
      }
    }
  }
  .flip-card-3D-wrapper {
    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
      width: 100%;
      margin: 0 auto;
    }
    width: 416px;
    height: 544px;
    position: relative;
  }
  #flip-card {
    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    height: 544px;
    width: 416px;
    -o-transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    -o-transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
    pointer-events: none;
    #flip-card-front,
    #flip-card-back {
      height: 100%;
      border-radius: 8px;
      border: 1px solid ${(props) => props.theme.palette['soft-gray']};
      text-align: center;
      display: flex;
      flex-direction: column;
      max-height: 544px;
      max-width: 416px;
      width: 416px;
      justify-content: space-between;
      @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        max-width: 100%;
        max-height: 444px;
      }
    }
  }
  .do-flip {
    animation: 0.2s ease-out 0s 1 both ${flipKeyframe} !important;
  }
  .back-flip {
    animation: 0.2s ease-in 0s 1 both ${beckFlipKeyframe} !important;
  }
  #flip-card-btn-turn-to-back,
  #flip-card-btn-turn-to-front {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: ${(props) => props.theme.palette.white};
    background-color: #3f51b5;
    border-radius: 4px;
    height: 42px;
    .h-22 {
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        margin-left: 12px;
      }
    }
  }
  #flip-card .flip-card-front {
    pointer-events: auto;
  }
  #flip-card .flip-card-front,
  #flip-card .flip-card-back {
    position: absolute;
    height: 100%;
    width: 416px;
    -o-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: rotateX(0deg);
    z-index: 2;
    -webkit-box-shadow: 5px 6px 32px 2px rgba(133, 133, 133, 0.71);
    -moz-box-shadow: 5px 6px 32px 2px rgba(133, 133, 133, 0.71);
    box-shadow: 5px 6px 32px 2px rgba(133, 133, 133, 0.71);
  }
  #flip-card .flip-card-back {
    -o-transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }
`;
