import React from 'react';
import { useHistory } from 'react-router';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ReactComponent as MoreIcon } from 'assets/3-vertical-dots.svg';
import { paths } from 'config/paths';

interface Props {
  options: Array<string>;
  deckId: string;
  sectionId: string;
  collectionId: string;
}

export const MoreButton: React.FC<Props> = ({
  options,
  deckId,
  sectionId,
  collectionId
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const history = useHistory();

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  const handleOpenData = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
    history.push(paths.deckReport(), {
      deckId,
      sectionId,
      collectionId
    });
  };

  return (
    <div>
      <IconButton
        aria-label='more'
        id='long-button'
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
      >
        <MoreIcon style={{ width: '16px', height: '16px' }} />
      </IconButton>
      <Menu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: '100px',
            width: '11ch',
            marginTop: '40px',
            marginLeft: '40px'
          }
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} onClick={handleOpenData}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
