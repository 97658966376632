import { FC } from 'react';

import { THead } from './style';

export const TableHead: FC = () => {
  return (
    <THead>
      <tr>
        <th>Correct</th>
        <th>Incorrect</th>
        <th>Custom</th>
        <th>Counting time</th>
        <th>Date</th>
        <th style={{ width: '8%' }}></th>
      </tr>
    </THead>
  );
};
