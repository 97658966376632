import React, { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  LogarithmicScale,
  PointElement,
  Title,
  Tooltip
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { convertTimeToCSTFormat } from 'helpers/convertTimeToCSTFormat';
import { getListOfTrendsWithoutConditions } from 'helpers/getListOfContitions';
import { ReportTableDto } from 'interfaces/attempts';

import { Wrapper } from './style';
interface Props {
  report: ReportTableDto;
  aimMin: number;
  aimMax: number;
}

export const ProgressChart: React.FC<Props> = ({
  report,
  aimMin = 28,
  aimMax = 40
}) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    PointElement,
    LineElement,
    annotationPlugin,
    Title,
    Tooltip,
    Legend
  );

  const attemptsPerMinute = report.attempts.map((attempt) => ({
    ...attempt,
    skipped: attempt.skipped * (60 / attempt.duration),
    incorrect: attempt.incorrect * (60 / attempt.duration),
    correct: attempt.correct * (60 / attempt.duration)
  }));

  // Get the best attempt: highest correct, lowest incorrect, lowest skipped
  const attemptsByDay = {} as any;
  attemptsPerMinute.map((attempt) => {
    attempt.dayCompletedAt = convertTimeToCSTFormat(attempt.completedAt, true);
    if (attemptsByDay[attempt.dayCompletedAt]) {
      if (attemptsByDay[attempt.dayCompletedAt].correct === attempt.correct) {
        if (
          attemptsByDay[attempt.dayCompletedAt].incorrect ===
            attempt.incorrect &&
          attemptsByDay[attempt.dayCompletedAt].skipped > attempt.skipped
        ) {
          attemptsByDay[attempt.dayCompletedAt] = attempt;
        } else if (
          attemptsByDay[attempt.dayCompletedAt].incorrect > attempt.incorrect
        ) {
          attemptsByDay[attempt.dayCompletedAt] = attempt;
        }
      } else if (
        attemptsByDay[attempt.dayCompletedAt].correct < attempt.correct
      ) {
        attemptsByDay[attempt.dayCompletedAt] = attempt;
      }
    } else {
      attemptsByDay[attempt.dayCompletedAt] = attempt;
    }

    return null;
  });

  const attemptdays = Object.keys(attemptsByDay).sort(
    (a, b) => new Date(a).getTime() - new Date(b).getTime()
  );

  const dateRange = (startDate: Date, endDate: Date, steps = 1) => {
    const dateArray = [];
    endDate.setUTCDate(endDate.getUTCDate() + 1);
    const currentDate = new Date(startDate);

    while (currentDate <= new Date(endDate)) {
      dateArray.push(new Date(currentDate));
      // Use UTC date to prevent problems with time zones and DST
      currentDate.setUTCDate(currentDate.getUTCDate() + steps);
    }

    return dateArray;
  };

  const dataDays = dateRange(
    new Date(attemptdays[0]),
    new Date(attemptdays[attemptdays.length - 1])
  );

  const totalList = [] as any;
  const correctList = [] as any;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const correctTrendList = [] as any;
  const incorrectList = [] as any;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const incorrectTrendList = [] as any;
  const skippedList = [] as any;
  const durationList = [] as any;

  dataDays.map((day) => {
    const currAttempt =
      attemptsByDay[convertTimeToCSTFormat(String(day), true)];
    const sum =
      currAttempt?.correct >= 0
        ? currAttempt.correct + currAttempt.incorrect + currAttempt.skipped
        : null;

    totalList.push(sum === 0 ? 0.5 : sum ?? null);
    currAttempt?.correct >= 0 &&
      correctTrendList.push({
        y: currAttempt.correct,
        x: dataDays.findIndex((date) => date.getTime() === day.getTime())
      });
    currAttempt?.incorrect >= 0 &&
      incorrectTrendList.push({
        y: currAttempt.incorrect,
        x: dataDays.findIndex((date) => date.getTime() === day.getTime())
      });
    correctList.push(
      currAttempt?.correct === 0 ? 0.5 : currAttempt?.correct ?? null
    );
    incorrectList.push(
      currAttempt?.incorrect === 0 ? 0.5 : currAttempt?.incorrect ?? null
    );
    skippedList.push(
      currAttempt?.skipped === 0 ? 0.5 : currAttempt?.skipped ?? null
    );
    durationList.push(
      currAttempt?.duration === 0 ? 0.5 : currAttempt?.duration ?? null
    );

    return null;
  });

  /*
      120 - 0.5
      90 - 0.75
      60 - 1
      45 - 1.5
      30 - 2
      20 - 3
      15 - 4
      10 - 6
  */
  const formattedDurationList = durationList.map((duration: number) => {
    if (duration === 120) {
      return 0.5;
    }
    if (duration === 90) {
      return 0.75;
    }
    if (duration === 60) {
      return 1;
    }
    if (duration === 45) {
      return 1.5;
    }
    if (duration === 30) {
      return 2;
    }
    if (duration === 20) {
      return 3;
    }
    if (duration === 15) {
      return 4;
    }
    if (duration === 10) {
      return 6;
    } else {
      return duration;
    }
  });

  const formatTickTime = (tickValue: number) => {
    if (tickValue === 0.5) {
      return "2'";
    }
    if (tickValue === 0.75) {
      return "1' 30''";
    }
    if (tickValue === 1) {
      return "1' min";
    }
    if (tickValue === 1.5) {
      return "45''";
    }
    if (tickValue === 2) {
      return "30''";
    }
    if (tickValue === 3) {
      return "20''";
    }
    if (tickValue === 4) {
      return "15''";
    }
    if (tickValue === 6) {
      return "10''";
    }
    return '';
  };

  const trendLines = useMemo(() => {
    if (correctTrendList.length > 1 && incorrectTrendList.length > 1) {
      return [
        getListOfTrendsWithoutConditions(correctTrendList, '#1AB5A0'),
        getListOfTrendsWithoutConditions(incorrectTrendList, '#FF837A')
      ];
    } else {
      return [];
    }
  }, [correctTrendList, incorrectTrendList]);

  const options = {
    responsive: true,
    // type: 'line',
    interaction: {
      intersect: false,
      mode: 'index' as const
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'SUCCESSIVE CALENDAR DAYS'
        },
        beginAtZero: true,
        display: true,
        grid: {
          color: 'lightblue',
          /* create major / minor grids based on mod  7 */
          lineWidth: (context: any) => (context.tick.value % 7 ? 0.3 : 1)
        },
        ticks: {
          autoSkip: false,
          /* only show tick labels for weeks (mod 7) */
          callback: (value: any) => (value % 7 ? '' : value),
          minRotation: 0,
          maxRotation: 0
        }
      },
      y: {
        title: {
          display: true,
          text: 'COUNT PER MINUTE'
        },
        grid: {
          color: 'lightblue',
          /* major minor grid lines based on log10 integer value of tick */
          lineWidth: (context: any) =>
            Number.isInteger(Math.log10(context.tick.value)) ? 1 : 0.3
        },
        type: 'logarithmic' as any,
        beginAtZero: true,
        ticks: {
          autoSkip: false,
          includeBounds: true,
          /* only show specific y axis labels) */
          callback: (tickValue: any) => {
            if (tickValue === 0) {
              return '0';
            }
            const remain =
              tickValue / Math.pow(10, Math.floor(Math.log10(tickValue)));
            if (remain === 1 || remain === 5) {
              if (tickValue === 0.5) tickValue = '0';
              return String(tickValue);
            }
            return '';
          }
        },
        min: 0.5,
        max: 1000
      },
      y2: {
        display: true,
        type: 'logarithmic' as any,
        position: 'right' as any,
        grid: {
          drawOnChartArea: false
        },
        ticks: {
          autoSkip: false,
          includeBounds: true,
          callback: (tickValue: any) => {
            if (tickValue <= 1) {
              return String(
                Number.isInteger(60 / (tickValue * 60))
                  ? 60 / (tickValue * 60) + (tickValue === 1 ? "' min" : "'")
                  : ''
              );
            } else {
              return String(
                (60 / tickValue) % 5
                  ? ''
                  : 60 / tickValue + (tickValue === 6 ? '" sec' : '"')
              );
            }
          }
        },
        min: 0.5,
        max: 1000
      }
    },
    plugins: {
      autocolors: false,
      legend: {
        display: true,
        position: 'top' as const,
        labels: {
          usePointStyle: true,
          boxHeight: 0,
          boxWidth: 24
        }
      },
      chartAreaBorder: {
        borderColor: 'lightblue',
        borderWidth: 2
      },
      annotation: {
        autocolors: false,
        annotations: [
          {
            type: 'box',
            yMin: aimMin,
            yMax: aimMax,
            backgroundColor: 'rgba(250,238,198,0.4)',
            borderColor: 'transparent',
            borderWidth: 0,
            drawTime: 'beforeDatasetsDraw'
          },
          {
            type: 'line',
            /* yMin/yMax for the aim line will come from the graphCMS data model, this can vary based on the deck type or organization */
            yMin: aimMin,
            yMax: aimMin,
            borderColor: '#EAB305',
            borderWidth: 3,
            drawTime: 'beforeDatasetsDraw'
          },
          {
            type: 'line',
            /* yMin/yMax for the aim line will come from the graphCMS data model, this can vary based on the deck type or organization */
            yMin: aimMax,
            yMax: aimMax,
            borderColor: '#F4D678',
            borderWidth: 1,
            drawTime: 'beforeDatasetsDraw'
          },
          ...trendLines
        ]
      } as any,
      tooltip: {
        intersect: false,
        usePointStyle: true,
        callbacks: {
          title: (tooltipItems: any) => {
            return `Date: ${convertTimeToCSTFormat(
              `${dataDays[tooltipItems[0].dataIndex]}`,
              true
            )}`;
          },
          label: (tooltipItem: any) => {
            return `${tooltipItem.dataset.label}: ${
              tooltipItem.dataset.label === 'Counting time'
                ? formatTickTime(Number(tooltipItem.formattedValue))
                : (
                    Math.round(
                      tooltipItem.formattedValue.replaceAll(',', '') * 100
                    ) / 100
                  ).toLocaleString()
            }`;
          }
        }
      },
      title: {
        display: true,
        text: `Progress Chart - ${report?.details?.collectionName} / ${report?.details?.name}`
      }
    }
  };

  const data = {
    labels: Array.from(
      Array(dataDays.length < 141 ? 141 : dataDays.length).keys()
    ),
    datasets: [
      {
        label: 'Correct',
        data: correctList,
        spanGaps: true,
        z: 10,
        borderColor: 'rgba(37, 69, 85, 0.24)',
        backgroundColor: 'rgb(26, 181, 160)',
        borderWidth: 2,
        pointRadius: 3
      },
      {
        label: 'Incorrect',
        data: incorrectList,
        spanGaps: true,
        z: 10,
        borderColor: 'rgba(37, 69, 85, 0.24)',
        backgroundColor: 'rgb(248, 128, 120)',
        borderWidth: 2,
        pointStyle: 'crossRot',
        pointRadius: 4,
        pointBorderWidth: 2,
        pointBorderColor: 'rgb(248, 128, 120)'
      },
      {
        label: 'Skipped',
        data: skippedList,
        spanGaps: true,
        borderColor: 'rgba(37, 69, 85, 0.24)',
        backgroundColor: 'rgb(211, 123, 7)',
        z: 10,
        pointStyle: 'rect',
        pointRadius: 4,
        pointBorderWidth: 2,
        borderWidth: 2
      },
      {
        label: 'Counting time',
        data: formattedDurationList,
        spanGaps: true,
        borderColor: 'rgb(51, 51, 51)',
        backgroundColor: 'rgb(51, 51, 51)',
        pointStyle: 'dash',
        showLine: false,
        borderWidth: 3,
        pointRadius: 5,
        yAxisID: 'y2',
        borderDash: [2, 6],
        borderOffset: 4
      },
      {
        label: 'Aim Line Min',
        data: [],
        spanGaps: true,
        borderColor: '#EAB305',
        fill: false,
        backgroundColor: '#EAB305',
        pointStyle: 'line',
        showLine: false,
        borderWidth: 3,
        pointRadius: 3
      },
      {
        label: 'Aim Line Max',
        data: [],
        spanGaps: true,
        borderColor: '#F4D678',
        fill: false,
        backgroundColor: '#F4D678',
        pointStyle: 'line',
        showLine: false,
        borderWidth: 1,
        pointRadius: 1
      }
    ]
  };

  return (
    <Wrapper>
      <div className='progress-chart-wrap'>
        <Line options={options} data={data} height={30} width={'100%'} />
      </div>
    </Wrapper>
  );
};
