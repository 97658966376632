import styled from 'styled-components';

export const Wrapper = styled.div`
  .safmeds__upper-text {
    display: flex;
    flex-direction: row;
    height: 50px;
    align-items: center;
    margin-bottom: 16px;
    border-bottom: 1px solid ${(props) => props.theme.palette.background};
    h5 {
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0px;
      text-align: left;
      margin: 0;
    }
    .tag {
      padding: 3px 10px;
      height: 16px;
      margin: 0px 8px;
    }
  }
`;
