import React, { useMemo, useState } from 'react';
import { DateTableHeader } from 'components/DateTableHeader';
import { TableRow } from 'components/TableRow';
import { ReportTableAttemptsDto } from 'interfaces/attempts';

interface TableProps {
  tableDate: string;
  attempts?: Array<ReportTableAttemptsDto>;
  isRowHeader?: boolean;
}

export const Table: React.FC<TableProps> = ({
  tableDate,
  attempts,
  isRowHeader = false
}) => {
  const [isTableOpened, setTableOpened] = useState(true);

  const renderAttempts = useMemo(() => {
    if (!attempts?.length) {
      return [];
    }

    if (isRowHeader) {
      if (isTableOpened) {
        return attempts.map((tr: any) => (
          <TableRow rowData={tr} key={tr._id} />
        ));
      }
    } else {
      return attempts.map((tr: any) => <TableRow rowData={tr} key={tr._id} />);
    }
  }, [attempts, isRowHeader, isTableOpened]);

  return (
    <>
      {isRowHeader && (
        <DateTableHeader
          date={tableDate}
          handleClick={() => setTableOpened(!isTableOpened)}
          isTableOpened={isTableOpened}
        />
      )}
      {renderAttempts}
    </>
  );
};
