import styled from 'styled-components';

export const Wrapper = styled.form`
  position: relative;
  padding: 24px 32px 32px 32px;
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2),
    0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 20px;
  }

  .close-icon {
    position: absolute;
    right: 15px;
    top: 15px;
    display: flex;
    border-radius: 50%;
    padding: 5px;
    transition: background-color 0.2s;

    &:hover {
      background-color: ${(props) =>
        props.theme.palette['dark-lavender-opacity']};
    }
  }

  .title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 10px;
    padding-right: 30px;
  }

  .description {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  .success-msg {
    p {
      font-size: 19px;
      margin-bottom: 20px;
    }

    button {
      width: 100%;
    }
  }

  .actions {
    display: flex;
    align-items: flex-end;

    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
      flex-direction: column;
      align-items: stretch;
    }

    button {
      min-width: 110px;

      &:first-child {
        margin-right: 20px;
        margin-top: 30px;

        @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
          margin: 20px 0;
        }
      }
    }
  }
`;
