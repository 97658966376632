export const paths = {
  home: (): string => '/',
  fork: (slug: string = ':certificationSlug'): string => `/${slug}`,
  quiz: (slug: string = ':certificationSlug'): string => `/quiz/${slug}`,
  result: (): string => `/result`,
  reviewReport: (): string => `/review-report`,
  studyReport: (): string => `/study-report`,
  deckReport: (): string => `/deck-report`,
  safmeds: (): string => `/safmeds`,
  safmedsExam: (id: string = ':id'): string => `${paths.safmeds()}/${id}/exam`,
  safmedsCollections: (
    collection: string = ':collection',
    section: string = ':section?'
  ): string => `${paths.safmeds()}/${collection}/${section}`,
  exam: () => `/exam`,
  examPage: (
    slug: string = ':certificationSlug',
    current: string = ':current'
  ): string => `${paths.exam()}/${slug}/${current}`,
  mockExam: (slug: string = ':certificationSlug'): string =>
    `/mockexam/${slug}`,
  chart: (_id: string = ':id'): string => `/chart/${_id}`
};
