import styled from 'styled-components';

export const Wrapper = styled.div<{ timeAlmostUp: boolean }>`
  background-color: ${(props) =>
    props.timeAlmostUp
      ? props.theme.palette['dark-cr-red']
      : props.theme.palette['dark-indigo-medium']};
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: ${(props) => props.theme.palette.white};
  padding: 3px 10px;
  border-radius: 16px;
`;
