export function printPDFDocumentWithName(title: string) {
  const prevTitle = document.title;

  try {
    // same-origin frame
    document.title = window.parent.document.title = title;
    window.print();
  } catch (e) {
    // cross-origin frame

    // we could instead grab the current content of the page
    // but for the demo, location.href will do
    const p: any = window.open(window.location.href);

    if (p !== null) {
      p.onload = function () {
        // no more frame so we don't care ;-)
        p.document.title = title;
        // a bit more hack to close the popup once printed...
        function closePopup() {
          p?.close();
        }
        if ('onafterprint' in p) {
          // FF and IE
          p.onafterprint = closePopup;
        } else {
          // webkit don't support onafterprint
          const mediaQueryList = p?.matchMedia('print');

          const mqlListener = (mql: any) => {
            if (!mql.matches) {
              closePopup();
              mediaQueryList.removeListener(mqlListener);
            }
          };

          mediaQueryList.addListener(mqlListener);
        }
      };
      // we're ready
      p.print();
    }
  }

  document.title = window.parent.document.title = prevTitle;
}
