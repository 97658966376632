import { APIService } from './api';

export type LoginAPIParams = {
  learnerId: string;
  courseId: string;
};

type LoginAPIResponse = {
  accessToken: string;
};

type GetUserAPIResponse = {
  firstName: string;
  lastName: string;
  email: string;
  type: string;
};

export class Auth extends APIService {
  isMockedApi: boolean;

  constructor(isMockedApi: boolean = false) {
    super();
    this.isMockedApi = isMockedApi;
  }

  async loginAPI(params: {
    learnerId: string;
    courseId: string;
  }): Promise<string> {
    if (this.isMockedApi) {
      return new Promise((resolve) =>
        setTimeout(resolve, 500, 'some-access-token')
      );
    }
    const { accessToken } = await this.post<LoginAPIParams, LoginAPIResponse>(
      `/api/auth/login`,
      params
    );
    return accessToken;
  }

  getUserAPI(): Promise<GetUserAPIResponse> {
    if (this.isMockedApi) {
      return new Promise((resolve) =>
        setTimeout(resolve, 500, {
          firstName: 'Melany',
          lastName: 'Fox'
        })
      );
    }
    return this.get<GetUserAPIResponse>(`/api/users`);
  }
}
