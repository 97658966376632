import { Measurement } from 'interfaces/measurment';

import { APIService } from './api';

export type PostMeasurementAPIParams = {
  chartId: string;
  correct: number;
  incorrect: number;
  custom: number | null;
  countingTime: number;
  dateTime: string;
};

export type PutMeasurementAPIParams = {
  correct: number;
  incorrect: number;
  custom: number | null;
  countingTime: number;
  dateTime: string;
};

export class Measurements extends APIService {
  isMockedApi: boolean;

  constructor(isMockedApi: boolean = false) {
    super();
    this.isMockedApi = isMockedApi;
  }

  postMeasurementApi(body: PostMeasurementAPIParams): Promise<Measurement> {
    if (this.isMockedApi) {
      return new Promise((resolve) =>
        setTimeout(resolve, 1000, require('../mocks/measurement.json'))
      );
    }

    return this.post<PostMeasurementAPIParams, Measurement>(
      `/api/measurement`,
      body
    );
  }

  putMeasurementApi(
    id: string,
    body: PutMeasurementAPIParams
  ): Promise<Measurement> {
    if (this.isMockedApi) {
      return new Promise((resolve) =>
        setTimeout(resolve, 1000, require('../mocks/measurement.json'))
      );
    }

    return this.put<PutMeasurementAPIParams, Measurement>(
      `/api/measurement/${id}`,
      body
    );
  }

  deleteMeasurementApi(id: string): Promise<any> {
    if (this.isMockedApi) {
      return new Promise((resolve) => setTimeout(resolve, 1000, undefined));
    }

    return this.delete<any>(`/api/measurement/${id}`);
  }
}
