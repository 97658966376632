import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Prompt, useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { api } from 'api/index';
import { Button } from 'components/Button';
import { FlipCard } from 'components/FlipCard';
import { FlipCardTimer } from 'components/FlipCardTimer';
import { DeckCtx } from 'components/Layout/Layout';
import { Modal } from 'components/Modal';
import { Notification } from 'components/Notification';
import { paths } from 'config/paths';
import { Location } from 'history';
import { theme } from 'theme';

import { ReactComponent as KeyboardIcon } from '../../assets/keyboard.svg';
import { Wrapper } from './style';

interface LocationProps {
  isStudyMode: boolean;
  sliderValue: number;
  showErrorCards: boolean;
  deck?: any;
  collectionId?: string;
  collectionName?: string;
}

interface CorrectExamAnswer {
  deckId: string;
  sectionId: string;
  sectionName: string;
  collectionId: string;
  deckName: string;
  collectionName: string;
  correct: number;
  incorrect: number;
  skipped: number;
  duration: number;
  incorrectAnswersId: any;
  correctAnswersId: any;
  isStudyMode: boolean;
}

interface Props {}

export const ExamCard: React.FC<Props> = () => {
  const history = useHistory();
  const location: Location<LocationProps> = useLocation();
  const {
    isStudyMode,
    sliderValue,
    showErrorCards,
    deck,
    collectionId,
    collectionName
  } = location.state;

  const deckContext = useContext(DeckCtx);

  const [deckCards, setDeckCards] = useState<null | any[]>(null);
  const [activeCardNumber, setActiveCardNumber] = useState(1);
  const [activeCard, setActiveCard] = useState(deckCards?.[0]);
  const [flipped, setFlipped] = useState(false);
  const [isTooltipOpened, setTooltipOpened] = useState(false);
  const [studyTime, setStudyTime] = useState(0);
  const [leaveModal, setLeaveModal] = useState(false);

  const [skipEnabled, setSkipEnabled] = useState(true);
  const [correctEnabled, setCorrectEnabled] = useState(true);
  const [incorrectEnabled, setIncorrectEnabled] = useState(true);

  const [dataHasBeenSubmited, setDataHasBeenSubmited] = useState(false);

  const [examAnswer, setExamAnswer] = useState<CorrectExamAnswer>({
    deckId: deck.id,
    sectionId: deck?.section.id,
    sectionName: deck?.section.sectionName,
    collectionId: deck?.collectionId || collectionId,
    deckName: deck?.name,
    collectionName: deck?.collectionName || collectionName,
    correct: 0,
    incorrect: 0,
    skipped: 0,
    duration: isStudyMode ? studyTime : sliderValue,
    incorrectAnswersId: [],
    correctAnswersId: [],
    isStudyMode
  });
  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, []);

  const shuffle = (array: any[]) => {
    const updatedArray = [...array];

    for (let i = updatedArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [updatedArray[i], updatedArray[j]] = [updatedArray[j], updatedArray[i]];
    }

    return updatedArray;
  };

  const summaryCount = useMemo(() => {
    return examAnswer.correct + examAnswer.incorrect + examAnswer.skipped;
  }, [examAnswer.correct, examAnswer.incorrect, examAnswer.skipped]);

  useEffect(() => {
    if (!deckCards) {
      setDeckCards(shuffle(deck.deckCards));
    }
  }, [deckCards, deck.deckCards]);
  useEffect(() => {
    if (isStudyMode) {
      setExamAnswer({ ...examAnswer, duration: studyTime });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studyTime]);
  useEffect(() => {
    deckContext?.setContext({
      ...deckContext.context,
      isStudyMode,
      deck: examAnswer
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [examAnswer]);

  const alertUser = (e: any) => {
    e.preventDefault();
    e.returnValue = 'Reload site? Changes that you made will not be saved';
  };
  const handleFlip = (bool: boolean): void => setFlipped(bool);
  const handleFinish = async (
    res: any,
    deck: any,
    activeCardNumber: number
  ) => {
    setDataHasBeenSubmited(true);
    if (isStudyMode) {
      await history.push(paths.studyReport(), {
        res: res
      });
    } else {
      await history.push(paths.reviewReport(), {
        res,
        deck,
        activeCardNumber
      });
    }
  };

  // Disable buttons after 1 click, enable on next card
  const handleCardAction = (actionType: string, answerId: string) => {
    if (summaryCount + 1 > activeCardNumber) {
      return;
    }
    switch (actionType) {
      case 'skip':
        if (deckCards!.length >= activeCardNumber + 1) {
          setExamAnswer(
            !showErrorCards
              ? {
                  ...examAnswer,
                  skipped: examAnswer.skipped + 1,
                  incorrectAnswersId: [
                    ...examAnswer.incorrectAnswersId,
                    answerId
                  ]
                }
              : {
                  ...examAnswer,
                  skipped: examAnswer.skipped + 1
                }
          );
          setActiveCardNumber(activeCardNumber + 1);
        } else if (deckCards!.length < activeCardNumber + 1) {
          const newExamAnswer = !showErrorCards
            ? {
                ...examAnswer,
                skipped: examAnswer.skipped + 1,
                incorrectAnswersId: [...examAnswer.incorrectAnswersId, answerId]
              }
            : {
                ...examAnswer,
                skipped: examAnswer.skipped + 1
              };
          Promise.resolve().then(async () => {
            await setExamAnswer(newExamAnswer);
            await setActiveCardNumber(deckCards!.length);
            if (showErrorCards) {
              await delete newExamAnswer.incorrectAnswersId;
              const res = await api.attempt.postErrorDeckAttempt(
                newExamAnswer as any
              );
              handleFinish(res, deck, activeCardNumber);
            } else {
              delete newExamAnswer.correctAnswersId;
              const res = await api.attempt.postSafmedsAttempt(
                newExamAnswer as any
              );
              handleFinish(res, deck, activeCardNumber);
            }
          });
        } else {
          return;
        }
        break;
      case 'correct':
        if (deckCards!.length >= activeCardNumber + 1) {
          setExamAnswer(
            !showErrorCards
              ? {
                  ...examAnswer,
                  correct: examAnswer.correct + 1
                }
              : {
                  ...examAnswer,
                  correct: examAnswer.correct + 1,
                  correctAnswersId: [...examAnswer.correctAnswersId, answerId]
                }
          );
          setActiveCardNumber(activeCardNumber + 1);
          handleFlip(!flipped);
        } else if (deckCards!.length < activeCardNumber + 1) {
          const newExamAnswer = !showErrorCards
            ? {
                ...examAnswer,
                correct: examAnswer.correct + 1
              }
            : {
                ...examAnswer,
                correct: examAnswer.correct + 1,
                correctAnswersId: [...examAnswer.correctAnswersId, answerId]
              };
          Promise.resolve().then(async () => {
            await setExamAnswer(newExamAnswer);
            await setActiveCardNumber(deckCards!.length);
            if (showErrorCards) {
              delete newExamAnswer.incorrectAnswersId;
              const res = await api.attempt.postErrorDeckAttempt(
                newExamAnswer as any
              );
              handleFinish(res, deck, activeCardNumber);
            } else {
              await delete newExamAnswer.correctAnswersId;
              const res = await api.attempt.postSafmedsAttempt(
                newExamAnswer as any
              );
              handleFinish(res, deck, activeCardNumber);
            }
          });
        } else {
          return;
        }
        break;
      case 'incorrect':
        if (deckCards!.length >= activeCardNumber + 1) {
          setExamAnswer(
            !showErrorCards
              ? {
                  ...examAnswer,
                  incorrect: examAnswer.incorrect + 1,
                  incorrectAnswersId: [
                    ...examAnswer.incorrectAnswersId,
                    answerId
                  ]
                }
              : {
                  ...examAnswer,
                  incorrect: examAnswer.incorrect + 1
                }
          );
          setActiveCardNumber(activeCardNumber + 1);
          handleFlip(!flipped);
        } else if (deckCards!.length < activeCardNumber + 1) {
          const newExamAnswer = !showErrorCards
            ? {
                ...examAnswer,
                incorrect: examAnswer.incorrect + 1,
                incorrectAnswersId: [...examAnswer.incorrectAnswersId, answerId]
              }
            : {
                ...examAnswer,
                incorrect: examAnswer.incorrect + 1
              };
          Promise.resolve().then(async () => {
            await setExamAnswer(newExamAnswer);
            await setActiveCardNumber(deckCards!.length);
            if (showErrorCards) {
              await delete newExamAnswer.incorrectAnswersId;
              const res = await api.attempt.postErrorDeckAttempt(
                newExamAnswer as any
              );
              handleFinish(res, deck, activeCardNumber);
            } else {
              await delete newExamAnswer.correctAnswersId;
              const res = await api.attempt.postSafmedsAttempt(
                newExamAnswer as any
              );
              handleFinish(res, deck, activeCardNumber);
            }
          });
        } else {
          return;
        }
        break;
      case 'timeout':
        const newExamAnswer = {
          ...examAnswer
        };
        Promise.resolve().then(async () => {
          await setExamAnswer(newExamAnswer);
          if (showErrorCards) {
            delete newExamAnswer.incorrectAnswersId;
            const res = await api.attempt.postErrorDeckAttempt(
              newExamAnswer as any
            );
            handleFinish(res, deck, activeCardNumber);
          } else {
            delete newExamAnswer.correctAnswersId;
            const res = await api.attempt.postSafmedsAttempt(
              newExamAnswer as any
            );
            handleFinish(res, deck, activeCardNumber);
          }
        });
        break;
      default:
        break;
    }
  };

  const handleTimeIsUp = () => {
    handleCardAction('timeout', activeCard.id);
  };
  useEffect(() => {
    if (deckCards) setActiveCard(deckCards[activeCardNumber - 1]);
  }, [activeCardNumber, deckCards]);

  const handleLeave = () => {
    deckContext?.setContext({ ...deckContext.context, pause: false });
    history.goBack();
  };
  const handleCancel = () => {
    setLeaveModal(false);
    deckContext?.setContext({ ...deckContext.context, pause: false });
  };

  const handleChangePage = (location: Location) => {
    if (
      location.pathname !== paths.reviewReport() &&
      location.pathname !== paths.studyReport() &&
      !isStudyMode &&
      !leaveModal
    ) {
      setLeaveModal(true);
      deckContext?.setContext({ ...deckContext.context, pause: true });
      return false;
    } else if (isStudyMode) {
      if (examAnswer && !dataHasBeenSubmited) {
        if (examAnswer.isStudyMode) {
          if (deckContext?.context.errorMode) {
            delete examAnswer.incorrectAnswersId;
            api.attempt.postErrorDeckAttempt(examAnswer as any);
          } else {
            delete examAnswer.correctAnswersId;
            api.attempt.postSafmedsAttempt(examAnswer as any);
          }
        }
      }
    }

    return true;
  };

  return deckCards && activeCard ? (
    <Wrapper>
      <div className='exam-card'>
        <h5 className='exam-card__title'>{deck?.name}</h5>
        <div className='exam-card__timer'>
          <FlipCardTimer
            isStudyMode={isStudyMode}
            onTimeIsUp={handleTimeIsUp}
            setStudyTime={setStudyTime}
            seconds={sliderValue}
            time={studyTime}
          />
          <p className='exam-card__timer__text'>
            {activeCardNumber || '1'}/{deckCards.length}
          </p>
        </div>
        <FlipCard
          flipped={flipped}
          handleFlip={handleFlip}
          handleCardAction={handleCardAction}
          cardData={activeCard}
          correctEnabled={correctEnabled}
          incorrectEnabled={incorrectEnabled}
          skipEnabled={skipEnabled}
          isStudyMode={isStudyMode}
          setCorrectEnabled={setCorrectEnabled}
          setIncorrectEnabled={setIncorrectEnabled}
          setSkipEnabled={setSkipEnabled}
          isModalOpen={leaveModal}
        />
        <div className='exam-card__shortcuts'>
          {isTooltipOpened && (
            <div className='exam-card__shortcuts__tooltip'>
              {flipped ? (
                <>
                  <span className='exam-card__shortcuts__tooltip__title'>
                    Back Side
                  </span>
                  <div className='exam-card__shortcuts__tooltip__action'>
                    <span>Correct</span>
                    <Button
                      mode='next'
                      text=''
                      className='exam-card__shortcuts__tooltip__action__button'
                    />
                  </div>
                  <div className='exam-card__shortcuts__tooltip__action'>
                    <span>Incorrect</span>
                    <Button
                      mode='prev'
                      arrowColor={theme.palette['dark-cr-gray']}
                      text=''
                      className='exam-card__shortcuts__tooltip__action__button'
                    />
                  </div>
                  {isStudyMode && (
                    <div className='exam-card__shortcuts__tooltip__action'>
                      <span>Flip</span>
                      <Button
                        text='Space'
                        textColor={theme.palette['dark-cr-gray']}
                        className='exam-card__shortcuts__tooltip__action__button'
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  <span className='exam-card__shortcuts__tooltip__title'>
                    Front Side
                  </span>
                  <div className='exam-card__shortcuts__tooltip__action'>
                    <span>Skip</span>
                    <Button
                      mode='next'
                      text=''
                      className='exam-card__shortcuts__tooltip__action__button'
                    />
                  </div>
                  <div className='exam-card__shortcuts__tooltip__action'>
                    <span>Flip</span>
                    <Button
                      text='Space'
                      textColor={theme.palette['dark-cr-gray']}
                      className='exam-card__shortcuts__tooltip__action__button'
                    />
                  </div>
                </>
              )}
            </div>
          )}
          <Button
            text=''
            color={theme.palette.background}
            textColor={theme.palette['dark-cr-gray']}
            className='exam-card__shortcuts__keyboard'
            onClick={() => setTooltipOpened(!isTooltipOpened)}
          >
            <KeyboardIcon />
            <span className='exam-card__shortcuts__keyboard__text'>
              Keyboard shortcuts
            </span>
          </Button>
        </div>
      </div>
      <Modal
        open={leaveModal}
        maxWidth='480px'
        borderRadius={4}
        onClose={() => handleCancel()}
      >
        <Notification
          title='Quit the session?'
          description='Your progress will not be saved'
          submitText='Leave'
          cancelText='Cancel'
          onSubmit={() => handleLeave()}
          onCancel={() => handleCancel()}
        />
      </Modal>
      <Prompt message={handleChangePage as () => boolean} />
    </Wrapper>
  ) : (
    <></>
  );
};
