import React, { useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { api } from 'api/index';
import { Button } from 'components/Button';
import { ButtonGroup } from 'components/ButtonGroup';
import { Preloader } from 'components/Preloader';
import { endOfDay, startOfDay } from 'date-fns';
import { saveAs } from 'file-saver';
import { useFormik } from 'formik';
import { CertificationDTO } from 'interfaces/certification';
import { Organization } from 'interfaces/organizations';
import { theme } from 'theme';
import { date, object, ref, string } from 'yup';

import { Wrapper } from './style';

interface Props {
  onClose: () => void;
}

const reports = [
  { type: 'summary', label: 'Attempt summary' },
  { type: 'analysys', label: 'Question analysis' },
  { type: 'feedback', label: 'Question feedback ' }
];

type ReportFields = {
  report: {
    type?: 'summary' | 'analysys' | 'feedback';
    dateFrom?: Date;
    dateTo?: Date;
    organization?: string[];
    certificationSlug: string;
  };
};
export const Report: React.FC<Props> = ({ onClose }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [organizations, setOrganizations] = useState<Array<Organization> | []>(
    []
  );
  const [certifications, setCertifications] = useState<CertificationDTO[]>();

  const {
    values: { report },
    setFieldValue,
    errors,
    handleSubmit,
    submitCount
  } = useFormik<ReportFields>({
    validateOnChange: false,
    initialValues: {
      report: {
        type: 'summary',
        dateFrom: undefined,
        dateTo: undefined,
        organization: [],
        certificationSlug: 'bcba'
      }
    },
    validationSchema: object({
      report: object({
        type: string().required('Please select a report type'),
        dateFrom: date().max(ref('dateTo')),
        dateTo: date().min(ref('dateFrom'))
      })
    }),

    onSubmit: () => {
      const request = {
        createdGte:
          (report.dateFrom &&
            startOfDay(report.dateFrom as Date).toISOString()) ??
          undefined,
        createdLte:
          (report.dateTo && endOfDay(report.dateTo as Date).toISOString()) ??
          undefined,
        clientId: report?.organization,
        certificationSlug: report.certificationSlug
      };
      setIsLoading(true);
      if (report.type === 'summary') {
        api.report
          .summaryReport(request)
          .then((report) => saveAs(report as any, 'SummaryReport.xlsx'))
          .catch(() => alert('Some error occured duting report generation'))
          .finally(() => setIsLoading(false));
      }
      if (report.type === 'feedback') {
        api.feedback
          .feedbackReport(request)
          .then((report) => saveAs(report as any, 'FeedbackReport.xlsx'))
          .catch(() => alert('Some error occured duting report generation'))
          .finally(() => setIsLoading(false));
      }
      if (report.type === 'analysys') {
        api.report
          .anylysysReport(request)
          .then((report) => saveAs(report as any, 'AnalysisReport.xlsx'))
          .catch(() => alert('Some error occured duting report generation'))
          .finally(() => setIsLoading(false));
      }
    }
  });

  const handleDelete = (value: string) => {
    setFieldValue(
      'report.organization',
      report.organization?.filter((orgValue) => orgValue !== value)
    );
  };
  const handleOrganizationChange = ({
    target: { value }
  }: React.ChangeEvent<{
    value: any;
  }>) => {
    setFieldValue(
      'report.organization',
      typeof value === 'string' ? value.split(',') : value
    );
  };

  useEffect(() => {
    Promise.all([
      api.cert.getAvalibleCertificationsAPI(),
      api.org.organizationList()
    ])
      .then(([certifications, organizations]) => {
        setCertifications(certifications);
        setOrganizations(organizations);
      })
      .catch((e) => console.error(e));
  }, []);

  return (
    <Wrapper noValidate onSubmit={handleSubmit}>
      <article>
        <h1>Export report</h1>

        <FormControl
          fullWidth
          style={{
            marginBottom: '12px',
            marginTop: '16px'
          }}
        >
          <InputLabel id='demo-certification-select-autowidth-label'>
            <h3 style={{ margin: 0 }}>Select a certification type:</h3>
          </InputLabel>
          <Select
            labelId='demo-certification-select-autowidth-label'
            id='demo-certification-select-standard'
            value={report.certificationSlug}
            onChange={(e) => {
              setFieldValue('report.certificationSlug', e.target.value);
            }}
            label='Select a certification type:'
            fullWidth
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              getContentAnchorEl: null
            }}
          >
            {certifications?.map((certification) => {
              return (
                <MenuItem
                  value={certification.certificationSlug}
                  key={certification.id}
                >
                  {certification.certification}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <ButtonGroup
          title='Select the report type to export'
          onChange={(type) => setFieldValue('report.type', type)}
          value={report.type}
          error={errors.report?.type && errors.report?.type.toString()}
        >
          {reports.map(({ type, label }) => {
            return (
              <ButtonGroup.Option value={type} key={label}>
                {label}
              </ButtonGroup.Option>
            );
          })}
        </ButtonGroup>

        <h2>Filter fields (optional)</h2>
        <FormControl
          fullWidth
          style={{
            marginBottom: '16px'
          }}
        >
          <InputLabel id='demo-simple-select-autowidth-label'>
            <h3
              style={{
                margin: '0',
                opacity: `${report.type === 'feedback' ? '.7' : '1'}`
              }}
            >
              Select organization
            </h3>
          </InputLabel>
          <Select
            labelId='demo-simple-select-autowidth-label'
            id='demo-simple-select-standard'
            value={report.organization}
            multiple
            onChange={handleOrganizationChange}
            disabled={report.type === 'feedback'}
            label='Select organization'
            fullWidth
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              getContentAnchorEl: null
            }}
            renderValue={(selected: unknown) => (
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gridRowGap: 0.5
                }}
              >
                {(selected as string[]).map((value) => (
                  <Chip
                    key={value}
                    label={
                      organizations?.find((org) => org.organizationId === value)
                        ?.name
                    }
                    variant='outlined'
                    clickable
                    onDelete={() => handleDelete(value)}
                    onMouseDown={(event) => {
                      event.stopPropagation();
                    }}
                  />
                ))}
              </Box>
            )}
          >
            {organizations?.map((orgaization) => {
              return (
                <MenuItem
                  value={orgaization.organizationId}
                  key={orgaization.organizationId}
                >
                  {orgaization.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <h3>Select date range</h3>
        <div style={{ display: 'flex', position: 'relative' }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              value={report.dateFrom ?? null}
              onChange={(e) => setFieldValue('report.dateFrom', e ?? undefined)}
              variant='inline'
              format='dd MMM yyyy'
              label='Date from'
              autoOk
              style={{ margin: '0 40px 26px 0 ' }}
              color='primary'
            />
            <KeyboardDatePicker
              value={report.dateTo ?? null}
              onChange={(e) => setFieldValue('report.dateTo', e ?? undefined)}
              variant='inline'
              label='Date to'
              format='dd MMM yyyy'
              autoOk
            />
          </MuiPickersUtilsProvider>
          {(errors.report?.dateTo ||
            (errors.report?.dateFrom && submitCount)) && (
            <p className='error' style={{ bottom: '0px' }}>
              Please select correct date range
            </p>
          )}
        </div>
      </article>
      <div className='actions'>
        <Button
          text='Export as .xlsx'
          type='submit'
          color={!errors ? theme.palette['light-lavender'] : undefined}
          className='action'
        />
        <Button text='Cancel' transparent onClick={onClose} />
      </div>
      {isLoading && <Preloader />}
    </Wrapper>
  );
};
