import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
  palette: {
    green: 'rgb(26, 181, 160)',
    'light-green': 'rgb(163, 224, 216)',
    'dark-green': 'rgb(5, 147, 161)',
    'cr-blue': 'rgb(4, 145, 199)',
    'light-cr-blue': 'rgb(63, 178, 222)',
    'dark-cr-blue': 'rgb(4, 126, 175)',
    'xtralight-cr-blue': 'rgb(216, 239, 248)',
    indigo: 'rgb(60, 148, 225)',
    'light-indigo': 'rgb(135, 189, 236)',
    'dark-indigo': 'rgb(11, 96, 171)',
    'dark-indigo-medium': 'rgb(63, 81, 181)',
    lavender: 'rgb(91, 126, 202)',
    'light-lavender': 'rgb(167, 182, 232)',
    'light-lavender-opacity': 'rgba(167, 182, 232, 0.25)',
    'light-lavender-opacity-plain': 'rgba(167, 182, 232, 0.5)',
    'dark-lavender': 'rgb(42, 71, 177)',
    'dark-lavender-opacity': 'rgba(42, 71, 177, 0.06)',
    'medium-dark-lavender': 'rgb(22, 55, 175)',
    purple: 'rgb(142, 88, 189)',
    'light-purple': 'rgb(180, 151, 205)',
    'dark-purple': 'rgb(103, 38, 159)',
    'pink-highlight': 'rgb(202, 52, 160)',
    'light-pink': 'rgb(227, 153, 207)',
    'dark-pink': 'rgb(180, 14, 134)',
    'cr-red': 'rgb(226, 67, 88)',
    'light-cr-red': 'rgb(244, 125, 141)',
    'dark-cr-red': 'rgb(186, 41, 60)',
    coral: 'rgb(248, 128, 120)',
    orange: 'rgb(246, 125, 104)',
    'light-orange': 'rgb(242, 150, 134)',
    'dark-orange': 'rgb(222, 84, 60)',
    'cr-gold': 'rgb(239, 164, 66)',
    'llight-cr-gold': 'rgb(245, 200, 142)',
    'dark-cr-gold': 'rgb(211, 123, 7)',
    yellow: 'rgb(244, 214, 120)',
    'light-yellow': 'rgb(250, 238, 198)',
    'dark-yellow': 'rgb(234, 179, 5)',
    'dark-cr-gray': 'rgb(37, 69, 85)',
    'dark-cr-gray-opacity': 'rgba(37, 69, 85, 0.5)',
    'medium-cr-gray': 'rgb(102, 124, 136)',
    'cr-gray': 'rgb(146, 162, 170)',
    'light-cr-gray': 'rgb(210, 217, 220)',
    'soft-gray': 'rgb(213, 213, 213)',
    background: 'rgb(243, 243, 243)',
    white: 'rgb(255, 255, 255)',
    'lightest-blue': 'rgb(250, 254, 255)',
    aquisition: '#2A47B1',
    'aquisition-background': '#A7B6E840',
    fluency: '#047EAF',
    'fluency-background': '#D8EFF8'
  },
  breakpoints: {
    sm: '576px',
    md: '768px',
    mdMin: '769px',
    lg: '992px',
    xl: '1200px',
    xxl: '1440px'
  }
};
