import { FC } from 'react';
import { ReactComponent as Clock } from 'assets/clock.svg';
import { Button } from 'components/Button';
import { convertTimeToCSTFormat } from 'helpers/convertTimeToCSTFormat';
import { Measurement } from 'interfaces/measurment';
import moment from 'moment';

import { Wrap } from './style';

type Props = {
  chartName: string;
  measurment: Measurement;
  onSubmit: () => void;
  onClose: () => void;
};

export const DeleteMeasurment: FC<Props> = ({
  chartName,
  measurment,
  onSubmit,
  onClose
}) => {
  const getTime = () => {
    if (measurment.countingTime < 60) {
      return `${measurment.countingTime} seconds`;
    } else {
      return `${Math.floor(measurment.countingTime / 60)} minutes`;
    }
  };

  return (
    <Wrap className='modal'>
      <h1 className='modal__title'>
        Are you sure you want to delete the following record?
      </h1>
      <p className='modal__description'>{chartName}</p>
      <div className='modal__content'>
        <div className='modal__content-item'>
          {moment(convertTimeToCSTFormat(measurment.dateTime)).format(
            'M/D/YYYY'
          )}
        </div>
        <div className='modal__content-item'>
          <svg
            className='content-item--icon'
            viewBox='0 0 100 100'
            width={16}
            height={16}
            xmlns='http://www.w3.org/2000/svg'
          >
            <circle cx='50' cy='50' r='40' style={{ fill: '#1AB5A0' }} />
          </svg>
          Correct: {measurment.correct}
        </div>
        <div className='modal__content-item'>
          <svg
            className='content-item--icon'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 72 72'
            width='16px'
            height='16px'
          >
            <path
              d='M 20 16 C 18.976125 16 17.952375 16.390875 17.171875 17.171875 C 15.609875 18.732875 15.609875 21.266125 17.171875 22.828125 L 30.34375 36 L 17.171875 49.171875 C 15.609875 50.732875 15.609875 53.266125 17.171875 54.828125 C 17.951875 55.609125 18.977 56 20 56 C 21.023 56 22.048125 55.609125 22.828125 54.828125 L 36 41.65625 L 49.171875 54.828125 C 49.951875 55.609125 50.977 56 52 56 C 53.023 56 54.048125 55.609125 54.828125 54.828125 C 56.390125 53.267125 56.390125 50.733875 54.828125 49.171875 L 41.65625 36 L 54.828125 22.828125 C 56.390125 21.267125 56.390125 18.733875 54.828125 17.171875 C 53.268125 15.609875 50.732875 15.609875 49.171875 17.171875 L 36 30.34375 L 22.828125 17.171875 C 22.048125 16.390875 21.023875 16 20 16 z'
              style={{ fill: '#FF837A' }}
              width='100%'
              height='100%'
            />
          </svg>
          Incorrect: {measurment.incorrect}
        </div>
        <div className='modal__content-item'>
          <svg
            className='content-item--icon'
            viewBox='0 0 100 100'
            width={16}
            height={16}
            xmlns='http://www.w3.org/2000/svg'
          >
            <rect
              width='80%'
              height='80%'
              style={{ fill: '#000', transform: 'translateX(14%)' }}
            />
          </svg>
          Custom: {measurment.custom}
        </div>
        <div className='modal__content-item'>
          <Clock className='content-item--icon' width={16} height={16} />
          Counting time: {getTime()}
        </div>
      </div>
      <div className='modal__buttons'>
        <Button onClick={onSubmit} text='Delete' color='rgb(226, 67, 88)' />
        <Button onClick={onClose} text='Cancel' transparent />
      </div>
    </Wrap>
  );
};
