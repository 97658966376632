import React, { useEffect, useState } from 'react';

import { Wrapper } from './style';

interface Props {
  minutes: number;
  onTimeIsUp: () => void;
}

export const Timer: React.FC<Props> = ({ minutes, onTimeIsUp }) => {
  const [timeLeft, setTimeLeft] = useState<number>(minutes);

  const hoursLeft = Math.floor(timeLeft / 60);

  useEffect(() => {
    if (!timeLeft) {
      onTimeIsUp();
      return;
    }

    const interval = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 60000);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [timeLeft]);

  return (
    <Wrapper timeAlmostUp={timeLeft < 6}>{`${hoursLeft} hours ${
      timeLeft - hoursLeft * 60
    } minutes`}</Wrapper>
  );
};
