import { Link } from 'react-router-dom';
import styled from 'styled-components';

type SectionStyleProps = {
  selected: boolean;
};

export const SectionStyle = styled(Link)<SectionStyleProps>`
  width: 100%;
  padding: 8px 16px;
  box-sizing: border-box;

  cursor: pointer;

  border: 1px solid #f3f3f3;
  border-radius: 6px;

  transition: 0.2s;

  background: ${(props) => (props.selected ? '#F3F3F3' : 'transparent')};

  font-weight: 400;
  font-size: 16px;
  line-height: 175%;
  letter-spacing: 0.15px;

  display: block;

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.palette['dark-cr-blue']};
  }

  color: #254555;
`;
