import { LoginAPIParams } from 'api/auth';

const type = 'learner';

export const getLearner = (): LoginAPIParams => {
  const searchParams = new URLSearchParams(document.location.search);

  const learnerId = searchParams.get('user_id');
  const courseId = searchParams.get('courseId');

  localStorage.setItem('userId', learnerId || '');
  localStorage.setItem('courseId', courseId || '');

  // @ts-ignore
  if (process.env.NODE_ENV === 'development' && type === 'admin') {
    return {
      courseId: 'cdcebb8d-f42d-4711-b1b4-72641fd551ad',
      learnerId: '0f2c1bef-df8e-4e46-8a8a-a24278c5b91d'
    };
    // @ts-ignore
  } else if (process.env.NODE_ENV === 'development' && type === 'learner') {
    return {
      courseId: 'cdcebb8d-f42d-4711-b1b4-72641fd551ad',
      learnerId: 'c64db198-5fd6-4b25-856e-7f0195f1ed1b'
    };
  }

  if (!learnerId && !courseId) {
    console.error('No learnerId or courseId found in URL');
    return {
      learnerId: '',
      courseId: ''
    };
  }

  return {
    learnerId: learnerId || '',
    courseId: courseId || ''
  };
};
