import styled from 'styled-components';

export const THead = styled.thead`
  tr {
    height: 52px;
    width: 100%;
    border-radius: 0px;
  }
  th {
    height: 24px;
    align-items: center;
    line-height: 18px;
    padding: 2rem 32px 1rem 15px;
    vertical-align: middle;
    white-space: nowrap;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.17000000178813934px;
    text-align: left;
  }
`;
