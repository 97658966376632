import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Dialog } from '@material-ui/core';
import { api } from 'api';
import { PostConditionAPIParams, PutConditionAPIParams } from 'api/condition';
import {
  PostMeasurementAPIParams,
  PutMeasurementAPIParams
} from 'api/measurement';
import { Button } from 'components/Button';
import { Chart } from 'components/Chart';
import { MeasurementsTable } from 'components/Chart/Table';
import { ConditionDelete } from 'components/ConditionDelete';
import { ConditionForm } from 'components/ConditionForm';
import { DeleteMeasurment } from 'components/DeleteMeasurment';
import { MeasurementForm } from 'components/MeasurementModal';
import { Modal } from 'components/Modal';
import { paths } from 'config/paths';
import { convertTimeToCSTFormat } from 'helpers/convertTimeToCSTFormat';
import { printPDFDocumentWithName } from 'helpers/printPDFDocument';
import { ChartDataSets, ChartDTO } from 'interfaces/chart';
import { Condition } from 'interfaces/condition';
import { Measurement } from 'interfaces/measurment';
import { theme } from 'theme';

import { ChartPageWrapper } from './style';

// @ts-ignore
// eslint-disable-next-line no-extend-native
Date.prototype.reduceDays = function (days: number): Date {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() - days);
  return date;
};

type Props = {
  name: string;
  showTimeline: boolean;
  handleChangeMode: () => void;
  handleMeasurmentOpen: () => void;
  handleConditionOpen: () => void;
};

const ChartPageHeader: FC<Props> = ({
  name,
  showTimeline,
  handleChangeMode,
  handleMeasurmentOpen,
  handleConditionOpen
}) => {
  const history = useHistory();

  const handlePrint = () => {
    printPDFDocumentWithName(name);
  };

  return (
    <>
      <div className='chart__upper-text'>
        <div className='chart__title-wrap'>
          <div
            className='chart__back-button'
            style={{
              marginRight: '16px'
            }}
          >
            <Button
              mode='prev'
              text='BACK'
              color={theme.palette.white}
              textColor={theme.palette['dark-lavender']}
              borderColor={theme.palette['dark-lavender']}
              arrowColor={theme.palette['dark-lavender']}
              hoverArrowColor={theme.palette.white}
              onClick={() => history.push(paths.safmeds())}
            />
          </div>
          <h5
            style={{
              marginRight: '16px'
            }}
          >
            {name}
          </h5>
        </div>
        <div className='chart__buttons-wrap'>
          <Button
            text='Print'
            className='deck-report__print'
            color={theme.palette.white}
            textColor={theme.palette['dark-lavender']}
            borderColor={theme.palette['dark-lavender']}
            arrowColor={theme.palette['dark-lavender']}
            hoverArrowColor={theme.palette.white}
            onClick={handlePrint}
          />
          <Button
            className=''
            text={showTimeline ? 'Hide Timeline' : 'Show Timeline'}
            color={theme.palette.white}
            textColor={theme.palette['dark-lavender']}
            borderColor={theme.palette['dark-lavender']}
            arrowColor={theme.palette['dark-lavender']}
            hoverArrowColor={theme.palette.white}
            onClick={() => handleChangeMode()}
          />
          <Button
            className=''
            text='Measure'
            color={theme.palette.white}
            textColor={theme.palette['dark-lavender']}
            borderColor={theme.palette['dark-lavender']}
            arrowColor={theme.palette['dark-lavender']}
            hoverArrowColor={theme.palette.white}
            onClick={() => handleMeasurmentOpen()}
          />
          <Button
            className=''
            text='Condition'
            color={theme.palette.white}
            textColor={theme.palette['dark-lavender']}
            borderColor={theme.palette['dark-lavender']}
            arrowColor={theme.palette['dark-lavender']}
            hoverArrowColor={theme.palette.white}
            onClick={() => handleConditionOpen()}
          />
        </div>
      </div>
    </>
  );
};

export const ChartPage: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [open, setOpen] = useState<boolean>(false);
  const [showTimeline, setShowTimeline] = useState<boolean>(false);
  const [conditionModal, setConditionModal] = useState<boolean>(false);
  const [chart, setChart] = useState<ChartDTO | null>(null);
  const [measurments, setMeasurments] = useState<Measurement[] | null>(null);
  const [conditions, setConditions] = useState<Condition[] | null>(null);
  const [conditionDelete, setConditionDelete] = useState<Condition | null>(
    null
  );
  const [editCondition, setEditCondition] = useState<Condition | null>(null);
  const [editMeasurement, setEditMeasurement] = useState<Measurement | null>(
    null
  );
  const [deleteMeasurement, setDeleteMeasurement] =
    useState<Measurement | null>(null);
  const [correctList, setCorrectList] = useState<
    Array<{ y: number; x: number }>
  >([]);
  const [incorrectList, setIncorrectList] = useState<
    Array<{ y: number; x: number }>
  >([]);

  const handlemeasurmentUpdate = (values: PutMeasurementAPIParams) => {
    if (editMeasurement) {
      api.measurement
        .putMeasurementApi(editMeasurement.id, values)
        .then((res) => {
          setEditMeasurement(null);
          setMeasurments((prev) => {
            if (prev) {
              return prev.map((item) => {
                if (item.id === res.id) {
                  return res;
                }
                return item;
              });
            }
            return [res];
          });
        });
    }
  };

  const handleConditionUpdate = (values: PutConditionAPIParams) => {
    if (!!editCondition) {
      api.condition.putConditionApi(editCondition.id, values).then((res) => {
        setEditCondition(null);
        setConditions((prev) => {
          if (prev) {
            return prev.map((item) => {
              if (item.id === res.id) {
                return res;
              }
              return item;
            });
          }
          return [res];
        });
      });
    }
  };

  const handleMeasurmentCreate = (values: PostMeasurementAPIParams) => {
    api.measurement.postMeasurementApi(values).then((res) => {
      setOpen(false);
      setMeasurments((prev) => {
        if (prev) {
          return [...prev, res];
        }
        return [res];
      });
    });
  };

  const handleConditionCreate = (values: PostConditionAPIParams) => {
    api.condition.postConditionApi(values).then((res) => {
      setConditionModal(false);
      setConditions((prev) => {
        if (prev) {
          return [...prev, res];
        }
        return [res];
      });
    });
  };

  const handleHideLine = (p0: string, p1: string): boolean => {
    const p0Date = new Date(p0);
    const p1Date = new Date(p1);

    if (conditions) {
      for (let i = 0; i < conditions?.length; i++) {
        if (
          p0Date.getTime() <= new Date(conditions[i].dateTime).getTime() &&
          p1Date.getTime() >= new Date(conditions[i].dateTime).getTime()
        ) {
          return true;
        } else {
          continue;
        }
      }
    }

    return false;
  };

  const measurementByDay = useMemo<Record<string, Measurement>>(() => {
    const attemptsByDay = {} as any;

    measurments?.map((attempt) => {
      attempt.dateTime = new Date(attempt.dateTime).toUTCString();
      if (attemptsByDay[attempt.dateTime]) {
        if (attemptsByDay[attempt.dateTime].correct === attempt.correct) {
          if (attemptsByDay[attempt.dateTime].incorrect === attempt.incorrect) {
            attemptsByDay[attempt.dateTime] = attempt;
          } else if (
            attemptsByDay[attempt.dateTime].incorrect > attempt.incorrect
          ) {
            attemptsByDay[attempt.dateTime] = attempt;
          }
        } else if (attemptsByDay[attempt.dateTime].correct < attempt.correct) {
          attemptsByDay[attempt.dateTime] = attempt;
        }
      } else {
        attemptsByDay[attempt.dateTime] = attempt;
      }

      return null;
    });

    return attemptsByDay;
  }, [measurments]);

  const startDate = useMemo<Date>(() => {
    if (Object.keys(measurementByDay).length === 0) {
      return chart?.details.createdAt
        ? new Date(chart.details.createdAt)
        : new Date();
    }

    const sortedDates = Object.keys(measurementByDay).sort(
      (a, b) => new Date(a).getTime() - new Date(b).getTime()
    );

    const d = new Date(sortedDates[0]);

    // @ts-ignore
    return d.reduceDays(3);
  }, [measurementByDay, chart?.details.createdAt]);

  const getDuration = (seconds: number): number => {
    return 60 / seconds;
  };

  const datasets = useMemo<Array<ChartDataSets>>(() => {
    const measurments = Object.values(measurementByDay).map((attempt) => ({
      ...attempt,
      custom:
        attempt.custom !== null
          ? attempt.custom * (60 / attempt.countingTime)
          : null,
      incorrect: attempt.incorrect * (60 / attempt.countingTime),
      correct: attempt.correct * (60 / attempt.countingTime)
    }));
    measurments.sort(
      (a, b) =>
        new Date(convertTimeToCSTFormat(a.dateTime, true)).getTime() -
        new Date(convertTimeToCSTFormat(b.dateTime, true)).getTime()
    );

    const correctList: { y: number; x: Date }[] = [];
    const incorrectList: { y: number; x: Date }[] = [];
    const customList: { y: number; x: Date }[] = [];
    const countingTimeList: { y: number; x: Date }[] = [];

    measurments.map((attempt) => {
      correctList.push({
        y: attempt.correct === 0 ? 0.0011 : attempt.correct,
        x: new Date(attempt.dateTime)
      });
      incorrectList.push({
        y: attempt.incorrect === 0 ? 0.0011 : attempt.incorrect,
        x: new Date(attempt.dateTime)
      });
      countingTimeList.push({
        y: getDuration(attempt.countingTime),
        x: new Date(attempt.dateTime)
      });

      if (attempt.custom !== null) {
        customList.push({
          y: attempt.custom === 0 ? 0.0011 : attempt.custom,
          x: new Date(attempt.dateTime)
        });
      }

      return attempt;
    });

    setCorrectList(
      correctList.map((item) => ({ y: item.y, x: item.x.getTime() }))
    );
    setIncorrectList(
      incorrectList.map((item) => ({ y: item.y, x: item.x.getTime() }))
    );

    return [
      {
        label: 'Correct',
        data: correctList,
        borderColor: 'rgba(37, 69, 85, 0.24)',
        backgroundColor: 'rgb(26, 181, 160)',
        borderWidth: 1,
        pointRadius: 3,
        segment: {
          borderColor: (ctx: any) => {
            if (ctx.p0 && ctx.p1) {
              return handleHideLine(ctx.p0.raw.x, ctx.p1.raw.x)
                ? 'transparent'
                : 'rgba(37, 69, 85, 0.24)';
            }
          }
        }
      },
      {
        label: 'Incorrect',
        data: incorrectList,
        borderColor: 'rgba(37, 69, 85, 0.24)',
        backgroundColor: 'rgb(248, 128, 120)',
        borderWidth: 1,
        pointStyle: 'crossRot',
        pointRadius: 4,
        pointBorderWidth: 2,
        pointBorderColor: 'rgb(248, 128, 120)',
        z: 10,
        segment: {
          borderColor: (ctx: any) => {
            if (ctx.p0 && ctx.p1) {
              return handleHideLine(ctx.p0.raw.x, ctx.p1.raw.x)
                ? 'transparent'
                : 'rgba(37, 69, 85, 0.24)';
            }
          }
        }
      },
      {
        label: 'Custom',
        data: customList,
        borderColor: 'rgb(0, 0, 0)',
        backgroundColor: 'rgb(0, 0, 0)',
        z: 10,
        pointStyle: 'rect',
        pointRadius: 2,
        pointBorderWidth: 3,
        showLine: false
      },
      {
        label: 'Counting time',
        data: countingTimeList,
        borderColor: 'rgb(51, 51, 51)',
        backgroundColor: 'rgb(51, 51, 51)',
        pointStyle: 'dash',
        showLine: false,
        borderWidth: 3,
        pointRadius: 5,
        yAxisID: 'y2',
        borderDash: [2, 6]
      },
      {
        label: 'Aim Line Min',
        data: [],
        spanGaps: true,
        borderColor: '#EAB305',
        fill: false,
        backgroundColor: '#EAB305',
        pointStyle: 'line',
        showLine: false,
        borderWidth: 3,
        pointRadius: 3
      },
      {
        label: 'Aim Line Max',
        data: [],
        spanGaps: true,
        borderColor: '#F4D678',
        fill: false,
        backgroundColor: '#F4D678',
        pointStyle: 'line',
        showLine: false,
        borderWidth: 1,
        pointRadius: 1
      }
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measurementByDay, conditions, measurments]);

  const handleGetMeasurment = useCallback(
    (date: string) => {
      return measurementByDay[date];
    },
    [measurementByDay]
  );

  useEffect(() => {
    api.charts.getChartByIdApi(id).then((res) => {
      setChart(res);
      setMeasurments(res.measurements);
      setConditions(res.conditions);
    });
  }, [id]);

  const handleOnMeasurmentEdit = (id: string) => {
    setEditMeasurement(measurments?.find((m) => m.id === id) || null);
  };

  const handleMeasurmentDelete = () => {
    if (!deleteMeasurement) return;

    api.measurement.deleteMeasurementApi(deleteMeasurement.id).then(() => {
      setDeleteMeasurement(null);
      setMeasurments((prev) => {
        if (!prev) return null;
        return prev.filter((m) => m.id !== deleteMeasurement.id);
      });
    });
  };

  const handleOnMeasurementDelete = (id: string) => {
    if (measurments?.length) {
      setDeleteMeasurement(measurments.find((m) => m.id === id) || null);
    }
  };

  const handleOnConditionEdit = (id: string) => {
    setEditCondition(conditions?.find((m) => m.id === id) || null);
  };

  const handleOnConditionDelete = (id: string) => {
    if (conditions?.length)
      setConditionDelete(conditions.find((c) => c.id === id) as Condition);
  };

  const handleConditionDelete = (id: string) => {
    api.condition.deleteConditionApi(id).then(() => {
      setConditions((prev) => {
        if (!prev) return null;
        return prev.filter((c) => c.id !== id);
      });
      setConditionDelete(null);
    });
  };

  if (!chart) return null;

  return (
    <ChartPageWrapper>
      <ChartPageHeader
        name={chart.details.name}
        showTimeline={showTimeline}
        handleChangeMode={() => setShowTimeline(!showTimeline)}
        handleMeasurmentOpen={() => setOpen(true)}
        handleConditionOpen={() => setConditionModal(true)}
      />
      {showTimeline ? (
        <MeasurementsTable
          data={measurments}
          conditions={conditions}
          handleMeasurementEdit={handleOnMeasurmentEdit}
          handleMeasurementDelete={handleOnMeasurementDelete}
          handleConditionEdit={handleOnConditionEdit}
          handleConditionDelete={handleOnConditionDelete}
        />
      ) : (
        <Chart
          chart={chart}
          datasets={datasets}
          conditions={conditions || []}
          startDate={startDate}
          correctList={correctList}
          incorrectList={incorrectList}
          handleOnConditionEdit={handleOnConditionEdit}
          handleOnMeasurmentEdit={handleOnMeasurmentEdit}
          handleGetMeasurment={handleGetMeasurment}
          handleOnDeleteMes={handleOnMeasurementDelete}
          handleConditionDelete={handleOnConditionDelete}
        />
      )}
      <Dialog
        fullWidth
        open={!!editMeasurement || open}
        onClose={() => setOpen(false)}
        BackdropProps={{ style: { backgroundColor: 'rgba(37,69,85,0.5)' } }}
        PaperProps={{ style: { maxWidth: '480px' } }}
      >
        <MeasurementForm
          chartId={id}
          measurement={editMeasurement || undefined}
          onUpdate={handlemeasurmentUpdate}
          onSubmit={handleMeasurmentCreate}
          handleClose={() =>
            editMeasurement ? setEditMeasurement(null) : setOpen(false)
          }
        />
      </Dialog>
      <Dialog
        fullWidth
        open={!!editCondition || conditionModal}
        onClose={() =>
          !!editCondition ? setEditCondition(null) : setConditionModal(false)
        }
        BackdropProps={{ style: { backgroundColor: 'rgba(37,69,85,0.5)' } }}
        PaperProps={{ style: { maxWidth: '480px' } }}
      >
        <ConditionForm
          chartId={id}
          chartName={chart.details.name}
          condition={editCondition || undefined}
          onUpdate={handleConditionUpdate}
          onSubmit={handleConditionCreate}
          onCancel={() =>
            !!editCondition ? setEditCondition(null) : setConditionModal(false)
          }
        />
      </Dialog>
      <Modal
        open={!!conditionDelete}
        maxWidth='480px'
        onClose={() => setConditionDelete(null)}
      >
        {conditionDelete && (
          <ConditionDelete
            chartName={chart.details.name}
            title={conditionDelete.title}
            type={conditionDelete.type}
            date={conditionDelete.dateTime}
            onSubmit={() => handleConditionDelete(conditionDelete.id)}
            onCancel={() => setConditionDelete(null)}
          />
        )}
      </Modal>
      <Modal
        open={!!deleteMeasurement}
        maxWidth='480px'
        onClose={() => setDeleteMeasurement(null)}
      >
        {deleteMeasurement && (
          <DeleteMeasurment
            chartName={chart.details.name}
            measurment={deleteMeasurement}
            onSubmit={handleMeasurmentDelete}
            onClose={() => setDeleteMeasurement(null)}
          />
        )}
      </Modal>
    </ChartPageWrapper>
  );
};
