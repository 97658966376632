import { ResponseDeckDTO } from 'interfaces/decks';

import { APIService } from './api';

type GetGraphDeckByIdAPIParams = {
  id: string;
  collectionId: string;
  sectionId: string;
};

export class Deck extends APIService {
  isMockedApi: boolean;

  constructor(isMockedApi: boolean = false) {
    super();
    this.isMockedApi = isMockedApi;
  }

  getGraphDeckById(
    params: GetGraphDeckByIdAPIParams
  ): Promise<ResponseDeckDTO> {
    return this.get<ResponseDeckDTO>(`/api/graphDeck/${params.id}`, {
      collectionId: params.collectionId,
      sectionId: params.sectionId
    });
  }

  getErrorDeckById(params: GetGraphDeckByIdAPIParams): Promise<any> {
    return this.get<any>(`/api/errorDeck/${params.id}`, {
      collectionId: params.collectionId,
      sectionId: params.sectionId
    });
  }
}
