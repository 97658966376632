import styled from 'styled-components';

type Props = {
  open: boolean;
  isEdit: boolean;
};

export const Wrapper = styled.button<Props>`
  background: #fff111;
  cursor: pointer;

  background: #e3e8f7;
  box-shadow: 6px 6px 5px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  padding: 4px 6px 4px 12px;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 8px 10px 8px 16px;
  }

  .chart__item {
    &--head {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &--form {
      width: 100%;
    }

    &__input input {
      cursor: pointer;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 0.15px;
      margin: 0;

      @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        font-size: 20px;
      }
    }

    &__input--edit input {
      cursor: pointer;
      color: #000;
    }

    &__input {
      cursor: pointer;
      width: 100%;

      .MuiInput-underline::after,
      .MuiInput-underline::before {
        display: ${(props) => (props.isEdit ? 'block' : 'none')};
      }
    }

    &__description {
      text-align: start;
    }

    &--info {
      path {
        stroke: ${(props) => (!props.open ? '#000' : 'rgba(37, 69, 85, 0.3);')};
      }
    }
  }
`;
