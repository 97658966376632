import React, { useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useHistory } from 'react-router-dom';
import { api } from 'api/index';
import logo from 'assets/logo.png';
import { Button } from 'components/Button';
import { paths } from 'config/paths';

import { Wrapper } from './style';

export const Error: React.FC<FallbackProps & { questionId?: string }> = ({
  error,
  questionId
}) => {
  const history = useHistory();
  useEffect(() => {
    api.org.postReactLog({
      questionId: questionId || '',
      error: `${error} | stack: ${error?.stack}`,
      email: localStorage.getItem('email') || ''
    });
  }, [error, questionId]);

  return (
    <Wrapper>
      <div className='error'>
        <div className='error__logo'>
          <img src={logo} alt='Knowledge Builder' />
          <span>KnowledgeBuilder</span>
        </div>

        <div className='error__title'>Looks like something went wrong</div>

        <div className='error__description'>
          We are having trouble on this page. Please refresh the page and try
          again or back home
        </div>

        <div className='error__actions'>
          <Button
            text='Refresh page'
            type='button'
            transparent
            onClick={() => document?.location?.reload()}
          />
          <Button
            text='Back Home'
            type='button'
            onClick={() => history.push(paths.home())}
          />
        </div>
      </div>
    </Wrapper>
  );
};
