import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { paths } from 'config/paths';
import { CertificationDTO } from 'interfaces/certification';
import { theme } from 'theme';

import { Wrapper } from './style';

interface Props {
  onClose: () => void;
  attemptNumber?: number;
  certification: CertificationDTO;
}

export const StartExam: React.FC<Props> = ({
  onClose,
  attemptNumber,
  certification
}) => {
  const [isSettingOpen, setIsSettingOpen] = useState<boolean>(false);
  const [customExamTime, setCustomExamTime] = useState<number>(0);
  const [customQuestionNumbers, setCustomQuestionNumbers] = useState<number>(0);

  const {
    certification: certificationName,
    configDefaultNumberOfMockExamQuestions,
    configDefaultTimeForMockExam
  } = certification;

  const defatultExamTime = configDefaultTimeForMockExam / 60;

  const history = useHistory();

  const isStartBtnDisabled =
    isSettingOpen && (!customExamTime || !customQuestionNumbers);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    history.push({
      pathname: paths.examPage(certification?.certificationSlug, '1'),
      state: {
        customExamTime: isSettingOpen
          ? customExamTime || defatultExamTime
          : defatultExamTime,
        customQuestionNumbers: isSettingOpen
          ? customQuestionNumbers || configDefaultNumberOfMockExamQuestions
          : configDefaultNumberOfMockExamQuestions,
        attemptNumber
      }
    });
  };

  return (
    <Wrapper onSubmit={handleSubmit} id='attempt_modal'>
      <span className='title'>Start {certificationName} exam</span>
      <div className='settings'>
        <p>{`The default mock exam has ${configDefaultNumberOfMockExamQuestions} questions and 4 hours to complete.`}</p>
        <Button
          text={isSettingOpen ? 'Reset to default' : 'Change settings'}
          transparent
          color={theme.palette['medium-cr-gray']}
          paddingX={9}
          paddingY={3}
          onClick={() => {
            if (!isSettingOpen) {
              setCustomExamTime(defatultExamTime);
              setCustomQuestionNumbers(configDefaultNumberOfMockExamQuestions);
            }
            setIsSettingOpen((prev) => !prev);
          }}
        />
      </div>
      {isSettingOpen && (
        <div className='custom'>
          <span>
            Choose the number of questions and the amount of time for your exam.
          </span>
          <Input
            label='Number of questions:'
            type='number'
            required
            pattern='[0-9]*'
            defaultValue={String(customQuestionNumbers)}
            maxNumber={175}
            className='custom__input'
            getValue={(value, hasError) =>
              setCustomQuestionNumbers(Number(hasError ? 0 : value))
            }
          />
          <Input
            label='Time (minutes):'
            type='number'
            pattern='[0-9]*'
            required
            maxNumber={500}
            defaultValue={String(customExamTime)}
            className='custom__input'
            getValue={(value, hasError) =>
              setCustomExamTime(Number(hasError ? 0 : value))
            }
          />
        </div>
      )}
      <div className='actions'>
        <Button
          text='Start exam'
          type='submit'
          color={
            isStartBtnDisabled ? theme.palette['light-lavender'] : undefined
          }
          disabled={isStartBtnDisabled}
        />
        <Button text='Cancel' transparent onClick={onClose} />
      </div>
    </Wrapper>
  );
};
