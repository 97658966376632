import React, { useState } from 'react';
import { RouteProps, useHistory } from 'react-router-dom';
import { Dialog, IconButton, Menu, MenuItem } from '@material-ui/core';
import { Button } from 'components/Button';
import { Navigation } from 'components/Navigation';
import { Report } from 'components/Reports';
import { UserHeading } from 'components/UserHeading';

import { Logo, Wrapper } from './style';

export type HeaderProps = {
  isExamPage?: boolean;
  hideNavigation?: boolean;
} & RouteProps;

export const Header: React.FC<HeaderProps> = ({
  isExamPage = false,
  hideNavigation = false,
  ...props
}) => {
  const history = useHistory();

  const [{ open, type }, openModal] = useState<{
    type?: 'report';
    open: boolean;
  }>({ type: undefined, open: false });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const closeModal = () => openModal({ open: false });
  const userType = sessionStorage.getItem('role');

  return (
    <Wrapper>
      <div className='logo-wrapper'>
        <Logo
          className='logo'
          to='/'
          hidetext={isExamPage ? isExamPage : undefined}
        >
          <span className='logo-text'>ABA Knowledge Builder</span>
        </Logo>
        {!isExamPage ? (
          <></>
        ) : (
          <Button
            text='Quit'
            onClick={() => history.goBack()}
            transparent
            paddingX={9}
            paddingY={3}
            className='quit-button'
          />
        )}
      </div>
      {!hideNavigation && <Navigation />}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <UserHeading />
        {userType === 'admin' && (
          <React.Fragment>
            <IconButton
              onClick={handleClick}
              size='medium'
              aria-controls={open ? 'account-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
            >
              <svg
                style={{
                  transform: openMenu ? 'rotate(180deg)' : undefined
                }}
                width='12'
                height='8'
                viewBox='0 0 12 8'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M1.41 0.589844L6 5.16984L10.59 0.589844L12 1.99984L6 7.99984L0 1.99984L1.41 0.589844Z'
                  fill='#2A47B1'
                />
              </svg>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id='account-menu'
              open={openMenu}
              onClose={handleClose}
              onClick={handleClose}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <MenuItem
                onClick={() => {
                  openModal({ type: 'report', open: true });
                }}
              >
                Generate report
              </MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </div>

      {type === 'report' && (
        <Dialog
          fullWidth
          open={open}
          onClose={closeModal}
          BackdropProps={{ style: { backgroundColor: 'rgba(37,69,85,0.5)' } }}
          PaperProps={{ style: { maxWidth: '480px' } }}
        >
          <Report onClose={closeModal} />
        </Dialog>
      )}
    </Wrapper>
  );
};
