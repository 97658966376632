import styled from 'styled-components';

export const Table = styled.table`
  border-spacing: 0;
  vertical-align: middle;
  text-align: left;
  min-width: 568px;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
`;
