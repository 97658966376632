import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  padding: 15px 0 0;
  margin-top: 20px;

  textarea {
    border: 0;
    border: 1px solid ${(props) => props.theme.palette['dark-cr-gray']};
    outline: 0;
    font-size: 16px;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
    border-radius: 4px;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    resize: none;

    &::placeholder {
      color: transparent;
    }

    &:placeholder-shown ~ label {
      font-size: 16px;
      cursor: text;
      top: 25px;
      left: 10px;
    }

    &:focus,
    &:hover {
      border-color: ${(props) => props.theme.palette['dark-lavender']};
    }
  }

  label,
  textarea:focus ~ label {
    position: absolute;
    top: -5px;
    left: 0px;
    display: block;
    transition: 0.2s;
    font-size: 12px;
    color: ${(props) => props.theme.palette['medium-cr-gray']};
  }

  textarea:focus ~ label {
    color: ${(props) => props.theme.palette['dark-lavender']};
  }
`;
