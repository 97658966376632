import { Attempts } from './attempt';
import { Auth } from './auth';
import { Certification } from './certification';
import { Charts } from './chart';
import { Conditions } from './condition';
import { Deck } from './deck';
import { Exam } from './exam';
import { Feedback } from './feedback';
import { Measurements } from './measurement';
import { Organizations } from './organization';
import { Quiz } from './quiz';
import { Reports } from './report';

const isMockeApi: boolean = process.env.REACT_APP_MOCK_API === 'true';

const auth = new Auth(isMockeApi);
const cert = new Certification(isMockeApi);
const attempt = new Attempts(isMockeApi);
const exam = new Exam(isMockeApi);
const report = new Reports(isMockeApi);
const feedback = new Feedback(isMockeApi);
const quiz = new Quiz(isMockeApi);
const org = new Organizations(isMockeApi);
const deck = new Deck(isMockeApi);
const charts = new Charts(isMockeApi);
const measurement = new Measurements(isMockeApi);
const condition = new Conditions(isMockeApi);

export const api = {
  auth,
  cert,
  attempt,
  exam,
  report,
  feedback,
  quiz,
  org,
  deck,
  charts,
  measurement,
  condition
};
