// function isDST(d: any) {
//   const jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
//   const jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
//   return Math.max(jan, jul) !== d.getTimezoneOffset();
// }

export const convertTimeToCSTFormat = (date: string, onlyDate?: boolean) => {
  const formatedDate = new Date(date);

  // if (isDST(formatedDate)) {
  //   formatedDate.setTime(formatedDate.getTime() + 60 * 60 * 1000);
  // }

  return formatedDate
    .toLocaleDateString(
      'en-US',
      onlyDate
        ? undefined
        : {
            timeZoneName: 'short',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
          }
    )
    .replace(',', '')
    .replace('am', 'AM')
    .replace('pm', 'PM');
};
