import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router';
import { BigTag } from 'components/BigTag';
import { Button } from 'components/Button';
import { Preloader } from 'components/Preloader';
import { Tag } from 'components/Tag';
import { paths } from 'config/paths';
import { convertTimeToCSTFormat } from 'helpers/convertTimeToCSTFormat';
import { theme } from 'theme';

import { Wrapper } from './style';

export const ReviewReport: React.FC = () => {
  const history = useHistory();
  const location: any = useLocation();
  const { res, deck } = location.state;
  const cardsCompleted = useMemo(
    () => res.correct + res.incorrect + res.skipped,
    [res.correct, res.incorrect, res.skipped]
  );

  return (
    <>
      {res ? (
        <Wrapper>
          <div className='result__header'>
            <span>Review report</span>
          </div>
          <div className='result__overall'>
            <div className='result__overall__info'>
              <div className='result__overall__info__general'>
                <p className='result__overall__info__general__title'>
                  Your result is:
                </p>
                <p className='result__overall__info__general__data'>
                  <p className='result__overall__bigTags'>
                    <BigTag text={res.correct} color={theme.palette.green} />
                    <span>Correct</span>
                  </p>
                  <p className='result__overall__bigTags'>
                    <BigTag text={res.incorrect} color='#F88078' />
                    <span>Incorrect</span>
                  </p>
                  <p className='result__overall__bigTags'>
                    <BigTag
                      text={res.skipped}
                      color={theme.palette.background}
                    />
                    <span>Skipped</span>
                  </p>
                </p>
              </div>
              <div className='result__overall__info__block'>
                <span className='result__overall__info__block__title'>
                  Duration:
                </span>
                <span className='result__overall__info__block__value'>
                  {`${res.duration} seconds`}
                </span>
              </div>
              <div className='result__overall__info__block'>
                <span className='result__overall__info__block__title'>
                  Time:
                </span>
                <span className='result__overall__info__block__value'>
                  {convertTimeToCSTFormat(res.completedAt)}
                </span>
              </div>
              <div className='result__overall__info__block'>
                <span className='result__overall__info__block__title'>
                  Attempt number:
                </span>
                <span className='result__overall__info__block__value'>
                  {res.dayAttemptNumber}
                </span>
              </div>
              <div className='result__overall__info__block'>
                <span className='result__overall__info__block__title'>
                  Correct:
                </span>
                <Tag text={res.correct} color={theme.palette.green} />
              </div>
              <div className='result__overall__info__block'>
                <span className='result__overall__info__block__title'>
                  Incorrect:
                </span>
                <Tag text={res.incorrect} color='#F88078' />
              </div>
              <div className='result__overall__info__block'>
                <span className='result__overall__info__block__title'>
                  Skipped:
                </span>
                <Tag text={res.skipped} color={theme.palette.background} />
              </div>
              <div className='result__overall__info__block'>
                <span className='result__overall__info__block__title'>
                  Completed:
                </span>
                <span className='result__overall__info__block__value'>
                  {`${cardsCompleted} / ${deck.deckCards.length}`}
                </span>
              </div>
            </div>
            <div className='result__overall__info__buttons'>
              <Button
                text='Repeat this timing'
                onClick={() => history.goBack()}
              />
              <Button
                text='Done'
                onClick={() =>
                  history.push(
                    paths.safmedsCollections(res.collectionId, res.sectionId)
                  )
                }
              ></Button>
            </div>
            <Button
              className='result__overall__info__report'
              transparent
              text='View deck report'
              onClick={() =>
                history.push(paths.deckReport(), {
                  deckId: res.deckId,
                  collectionId: res.collectionId,
                  sectionId: res.sectionId
                })
              }
            />
          </div>
        </Wrapper>
      ) : (
        <Preloader />
      )}
    </>
  );
};
