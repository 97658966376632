import React from 'react';
import { paths } from 'config/paths';

import { SectionStyle } from './style';

interface SectionProps {
  sectionId: string;
  sectionName: string;
  selected: string | null;
  collectionId: string;
  handleCollapse: () => void;
}

export const Section = React.forwardRef<HTMLAnchorElement, SectionProps>(
  ({ sectionId, sectionName, selected, collectionId, handleCollapse }, ref) => {
    return (
      <SectionStyle
        to={paths.safmedsCollections(collectionId, sectionId)}
        className='safmeds__section'
        selected={selected === sectionId}
        ref={selected === sectionId ? ref : null}
        id={sectionId}
        onClick={() => handleCollapse()}
        data-testid='section'
        {...{ name: sectionName }}
      >
        <span className='safmeds__section--name'>{sectionName}</span>
      </SectionStyle>
    );
  }
);
