import { ReactComponent as Plus } from '../../assets/plus.svg';
import { ChartAdd } from './style';

type Props = {
  handleClick: () => void;
};

export const EmptyCard: React.FC<Props> = ({ handleClick }) => {
  return (
    <ChartAdd
      variant='outlined'
      onClick={handleClick}
      aria-label='Add new chart'
    >
      <Plus />
    </ChartAdd>
  );
};
