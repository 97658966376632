import React, { createContext, useState } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { ChildProcessWithoutNullStreams } from 'child_process';
import { Header, HeaderProps } from 'components/Header';
import { CreateSafmedsAttemptDto } from 'interfaces/attempts';

import { Wrapper } from './style';

interface DeckDataContext {
  deck: Partial<CreateSafmedsAttemptDto> | null;
  isStudyMode: boolean | null;
  errorMode: boolean | ChildProcessWithoutNullStreams;
  pause: boolean;
}
interface DeckContextInterface {
  setContext: (data: DeckDataContext) => void;
  context: DeckDataContext;
}

export const DeckCtx = createContext<DeckContextInterface | null>(null);

export const Layout = (props: RouteProps & HeaderProps) => {
  const [context, setContext] = useState<DeckDataContext>({
    deck: null,
    isStudyMode: false,
    errorMode: false,
    pause: false
  });

  return (
    <Wrapper>
      <DeckCtx.Provider value={{ context, setContext }}>
        <Header {...props} />
        <Route {...props} />
      </DeckCtx.Provider>
    </Wrapper>
  );
};
