import { FC, FormEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { paths } from 'config/paths';
import { useFormik } from 'formik';
import { ChartCardDTO } from 'interfaces/chartCard';
import * as yup from 'yup';

import { ReactComponent as Info } from '../../assets/info.svg';
import { ReactComponent as Pen } from '../../assets/pen.svg';
import { Wrapper } from './style';

type Props = {
  handleSubmit: (id: string, value: string) => void;
  editable?: boolean;
} & ChartCardDTO;

const schema = yup.object().shape({
  name: yup.string().required('Name is required').max(50, 'Too long')
});

export const ChartCard: FC<Props> = ({
  _id,
  name,
  editable = false,
  description,
  handleSubmit
}) => {
  const history = useHistory();

  const formik = useFormik({
    initialValues: { name },
    onSubmit: (values) => handleSubmit(_id, values.name),
    validationSchema: schema
  });

  const [open, setOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(editable);
  const input = useRef<HTMLInputElement>(null);

  const handleChage = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    formik.handleChange(e);
    // setValue(e.target.value);
  };

  const handleEdit = (
    e?: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e?.preventDefault();
    const error = formik.errors.name;
    if ((isEdit && !formik.values.name) || !!error) {
      formik.setFieldValue('name', name);
      formik.setFieldError('name', undefined);
      setIsEdit(!isEdit);
      e?.stopPropagation();
      input?.current?.blur();
      return;
    } else if (isEdit && !!formik.values.name && formik.values.name !== name) {
      handleSubmit(_id, formik.values.name);
      console.log('submit', e);
      setIsEdit(false);
      e?.stopPropagation();
      e?.preventDefault();
      input?.current?.blur();
      return;
    } else {
      setIsEdit(!isEdit);
      e?.stopPropagation();
      input?.current?.blur();
    }
  };

  const handleClick = (e: any) => {
    console.log(e.target?.id);
    if (
      (e.target?.id === 'chart-item' && !isEdit) ||
      (e.target?.id === 'outlined-basic' && !isEdit) ||
      (e.target?.id === 'chart__item--head' && !isEdit) ||
      (e.target?.id === undefined && !isEdit)
    ) {
      input?.current?.blur();
      e?.preventDefault();
      history.push(paths.chart(_id));
    }
  };

  const handleInputClick = (e: any) => {
    if (
      (e.target?.id === 'chart__item__input-adornment' && !isEdit) ||
      (e.target?.id === 'icon' && !isEdit) ||
      (e.target?.id === 'edit-button' && !isEdit)
    ) {
      setIsEdit(true);
    } else if (isEdit) {
      return;
    } else {
      input?.current?.blur();
      history.push(paths.chart(_id));
    }
  };

  useEffect(() => {
    if (isEdit) {
      input?.current?.focus();
    }
  }, [isEdit, input]);

  return (
    <Wrapper
      className='chart__item'
      role='button'
      id='chart-item'
      isEdit={isEdit}
      open={open}
      aria-label={name}
      tabIndex={0}
      onClick={(e: any) => handleClick(e)}
    >
      <div className='chart__item--head' id='chart__item--head'>
        <form className='chart__item--form' onSubmit={(e) => handleEdit(e)}>
          <TextField
            id='outlined-basic'
            name='name'
            aria-label='name'
            value={formik.values.name}
            className={`chart__item__input ${
              !isEdit ? 'chart__item__input--edit' : ''
            }`}
            onChange={(e) => handleChage?.(e)}
            onBlur={() => handleEdit()}
            error={!!formik.errors.name}
            onClick={(e) => handleInputClick(e)}
            focused={isEdit}
            inputProps={{
              'data-testid': 'input',
              ref: input
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  className='chart__item__input-adornment'
                  id='chart__item__input-adornment'
                  position='end'
                  onMouseDown={(e) => e.preventDefault()}
                >
                  <IconButton
                    type='button'
                    aria-label='edit'
                    id='edit-button'
                    onClick={(e: any) => handleEdit(e)}
                  >
                    <Pen className='chart__item--pen' id='icon' />
                  </IconButton>
                </InputAdornment>
              )
            }}
            variant='standard'
            size='small'
          />
        </form>
        <div className='chart__item--icons'>
          <IconButton
            type='button'
            aria-label='open info'
            name='open info'
            id='open-info'
            onClick={() => setOpen(!open)}
          >
            <Info className='chart__item--info' id='open-info-icon' />
          </IconButton>
        </div>
      </div>
      {open && (
        <div className='chart__item__description'>
          <p dangerouslySetInnerHTML={{ __html: description.html || '' }}></p>
        </div>
      )}
    </Wrapper>
  );
};
