export const getNumberOfDayFromDate = (
  startDate: Date = new Date(),
  currentDate: Date = new Date()
): number => {
  const start = new Date(startDate);
  const current = new Date(currentDate);

  const startUTC = Date.UTC(
    start.getFullYear(),
    start.getMonth(),
    start.getDate()
  );
  const currentUTC = Date.UTC(
    current.getFullYear(),
    current.getMonth(),
    current.getDate()
  );

  const days = Math.floor(
    // @ts-ignore
    (new Date(currentUTC) - new Date(startUTC)) / (24 * 60 * 60 * 1000)
  );

  return days;
};
