import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import { CircularProgress } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { api } from 'api/index';
import { Button } from 'components/Button';
import { ButtonGroup } from 'components/ButtonGroup';
import { Input } from 'components/Input';
import { Modal } from 'components/Modal';
import { StartExam } from 'components/StartExam';
import { paths } from 'config/paths';
import { useFormik } from 'formik';
import { CertificationDTO } from 'interfaces/certification';
import { Answers, QuizQuestions } from 'interfaces/quizQuestions';
import { theme } from 'theme';
import { lazy, object, string } from 'yup';

import { GlobalStyle, Wrapper } from './style';
const basicQuestion = [
  { type: 'yes', label: 'Yes — I want to help improve this product' },
  { type: 'no', label: 'No — take me directly to my mock exam' }
];

type RouteParams = {
  certificationSlug: string;
};

type FormData = {
  initial: string;
  answers: Answers;
};

const initialSchema = object({
  initial: object({
    value: string().required('Please select an option')
  }).nullable()
});

export const Quiz: React.FC = () => {
  const history = useHistory();
  const params = useParams<RouteParams>();
  const [questions, setQuestions] = useState<Array<QuizQuestions>>([]);
  const [certification, setCertification] = useState<CertificationDTO>();
  const [isStartModalOpen, setIsStartModalOpen] = useState<boolean>(false);

  const handleCloseStartModal = () => setIsStartModalOpen(false);

  useEffect(() => {
    Promise.all([
      api.quiz.quizQuestions(params.certificationSlug),
      api.cert.getCertificationsAPI()
    ])
      .then(([questions, certifications]) => {
        setQuestions(questions);
        setCertification(
          certifications.find(
            (c) => c.certificationSlug === params.certificationSlug
          )
        );
      })
      .catch((error) => console.error(error));
  }, [params]);

  const qShcema = useMemo(
    () =>
      questions.reduce((acc, field) => {
        if (field.required)
          return {
            answers: object({
              ...acc,
              [field.questionId]: object({
                answerId: string()
                  .when({
                    is: field.questionType !== 'input',
                    then: string().required('Please select an option')
                  })
                  .when({
                    is: field.questionType === 'input',
                    then: string().nullable()
                  })
                  .nullable(),
                value: string().when('answerId', {
                  is: (answerId: any) => {
                    return (
                      field.questionType === 'radio' &&
                      answerId ===
                        field.options.find((quest) => quest.value === 'Other')
                          ?.answerId
                    );
                  },
                  then: string()
                    .typeError('It must be string')
                    .required('This field is required'),
                  otherwise: string().nullable()
                })
              })
            })
          };
        return acc;
      }, {}),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [questions]
  );

  const initialV = useMemo(
    () =>
      questions.reduce((acc, { questionId, selected }) => {
        return {
          ...acc,
          [questionId]: {
            answerId: selected.answerId,
            value: selected?.value
          }
        };
      }, {} as Answers),
    [questions]
  );

  const {
    values,
    setFieldValue,
    validateField,
    errors,
    handleSubmit,
    submitCount,
    handleBlur
  } = useFormik<FormData>({
    initialValues: {
      initial: !!questions.length ? 'yes' : 'no',
      answers: { ...initialV }
    },
    validationSchema: () =>
      lazy((values) => {
        return values.initial === 'yes'
          ? object().shape({
              ...qShcema
            })
          : initialSchema;
      }),
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: () => {
      if (values.initial === 'no') {
        return setIsStartModalOpen(true);
      }

      api.quiz
        .quizAnswers({
          answers: values?.answers,
          certificationSlug: params.certificationSlug
        })
        .then(() => setIsStartModalOpen(true))
        .catch((error) => console.error(error));
    }
  });

  return (
    <>
      <GlobalStyle />
      <Wrapper onSubmit={handleSubmit} id='quiz'>
        <article>
          <h1>Consent for Product Improvement Research</h1>
          <h3>
            At CentralReach Institute, our number one goal is to provide you
            with study and professional development materials that truly help
            you learn. In order to continuously improve our test prep products,
            we would appreciate your answers to the following questions.
            Depending on your responses, you may encounter 6-11 questions that
            will take approximately two to five minutes to complete. You may
            skip any questions you do not wish to answer. You may also choose to
            skip the entire questionnaire and proceed directly to your mock
            exam.
          </h3>

          <ButtonGroup
            title='Do you consent to answer the research questions that follow and
                   allow CentralReach to use your data for product improvement and
                   publication? No personally identifiable data will be included in any
                   publications. You may skip any questions you do not wish to answer.'
            onChange={(type) => setFieldValue('initial', type)}
            value={values.initial}
          >
            {basicQuestion.map(({ type, label }) => {
              return (
                <ButtonGroup.Option
                  value={type}
                  key={label}
                  disabled={!questions.length}
                >
                  {label}
                </ButtonGroup.Option>
              );
            })}
          </ButtonGroup>

          {values.initial === 'yes' && (
            <section style={{ display: 'flex', flexDirection: 'column' }}>
              {!!questions.length ? (
                questions?.map(
                  ({
                    title,
                    options,
                    questionType,
                    questionId,
                    type = 'text',
                    description,
                    multiple,
                    required
                  }) => {
                    const err = errors?.answers?.[questionId] as any;

                    if (
                      questionType === 'radio' ||
                      questionType === 'checkbox'
                    ) {
                      return (
                        <ButtonGroup
                          title={
                            required ? (
                              <>
                                {title}
                                <span className='required'>*</span>
                              </>
                            ) : (
                              title
                            )
                          }
                          description={description}
                          key={questionId}
                          multiple={multiple}
                          value={values?.answers?.[questionId]?.answerId}
                          error={
                            errors.answers?.[questionId] && submitCount
                              ? err?.value
                              : ''
                          }
                          onChange={(answerId) => {
                            setFieldValue(`answers.${questionId}`, {
                              answerId
                            });
                            required && validateField(`answers.${questionId}`);
                          }}
                        >
                          {options &&
                            options?.map(({ answerId, value }) => {
                              return (
                                <ButtonGroup.Option
                                  key={answerId}
                                  value={answerId}
                                  type={questionType}
                                  onBlur={handleBlur}
                                  name={answerId}
                                >
                                  {value === 'Other' ? (
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <span
                                        style={{
                                          marginRight: '20px'
                                        }}
                                        className='otherLabel'
                                        onClick={() => {
                                          setTimeout(() =>
                                            document
                                              .getElementById(
                                                `${'Other' + answerId}`
                                              )
                                              ?.focus()
                                          );
                                        }}
                                      >
                                        Other:
                                      </span>
                                      <Input
                                        onBlur={handleBlur}
                                        getValue={(value) => {
                                          const answers = new Set(
                                            new Array<any>(
                                              values?.answers?.[
                                                questionId
                                              ].answerId
                                            )
                                              .concat(answerId)
                                              .flat(Infinity)
                                              .filter(Boolean)
                                          );
                                          setFieldValue(
                                            `answers.${questionId}`,
                                            {
                                              answerId: multiple
                                                ? Array.from(answers)
                                                : answerId,
                                              value: value
                                            }
                                          );
                                        }}
                                        value={
                                          values?.answers?.[questionId]?.value
                                        }
                                        type='text'
                                        placeholder='Your answer'
                                        className='fullWidth'
                                        style={{
                                          marginTop: '0px',
                                          width: '100%'
                                        }}
                                        id={`${'Other' + answerId}`}
                                      />
                                    </div>
                                  ) : (
                                    value
                                  )}
                                </ButtonGroup.Option>
                              );
                            })}
                        </ButtonGroup>
                      );
                    }

                    if (questionType === 'input') {
                      if (type === 'date') {
                        return (
                          <div className='report' key={questionId}>
                            {title && (
                              <p style={{ marginBottom: '15px' }}>{title}</p>
                            )}
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                value={
                                  values?.answers[questionId]?.value
                                    ? new Date(
                                        values.answers[questionId]
                                          .value as string
                                      )
                                    : null
                                }
                                onChange={(e) =>
                                  setFieldValue(`answers.${questionId}`, {
                                    answerId: null,
                                    value: e ?? undefined
                                  })
                                }
                                variant='inline'
                                format='dd MMM yyyy'
                                autoOk
                                color='primary'
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                        );
                      }

                      return (
                        <div className='report' key={questionId}>
                          {title && (
                            <p style={{ marginBottom: '15px' }}>{title}</p>
                          )}
                          <Input
                            onBlur={handleBlur}
                            placeholder='Your answer'
                            type={type}
                            name={questionId}
                            getValue={(value) => {
                              setFieldValue(
                                `answers.${questionId}.value`,
                                value
                              );
                            }}
                            defaultValue={values?.answers?.[questionId]?.value}
                          />
                          {errors?.answers?.questionId && submitCount && (
                            <p className='error'>{err?.answerId}</p>
                          )}
                        </div>
                      );
                    }
                    return null;
                  }
                )
              ) : (
                <CircularProgress style={{ margin: '0 auto' }} />
              )}
            </section>
          )}
        </article>
        <div className='actions'>
          <Button
            text={values.initial === 'yes' ? 'Submit' : 'Start mock exam'}
            type='submit'
            tabIndex={3}
            color={!errors ? theme.palette['light-lavender'] : undefined}
            className='action'
          />
          <Button
            text='Cancel'
            transparent
            onClick={() => {
              history.push(paths.mockExam(certification?.certificationSlug));
            }}
            tabIndex={4}
          />
        </div>
      </Wrapper>
      {!!certification && (
        <Modal
          open={isStartModalOpen && !!certification}
          maxWidth='480px'
          borderRadius={4}
          onClose={handleCloseStartModal}
        >
          <StartExam
            onClose={handleCloseStartModal}
            attemptNumber={Number(sessionStorage.getItem('attempts'))}
            certification={certification}
          />
        </Modal>
      )}
    </>
  );
};
