import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 864px;
  margin: auto;

  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 17px;
    margin-bottom: 24px;
    border-bottom: 1px solid ${(props) => props.theme.palette['soft-gray']};

    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
      flex-direction: column;
      align-items: flex-start;
    }

    &__title {
      display: flex;
      align-items: center;

      @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        justify-content: space-between;
        width: 100%;
        margin-bottom: 20px;
      }

      span {
        font-size: 24px;
      }

      button {
        font-size: 13px;
        line-height: 22px;
        margin-left: 16px;
      }
    }
  }

  .more-toggle {
    display: block;
    margin: auto;
    margin-top: 12px;
    font-size: 13px;
    line-height: 22px;

    &.close-modal {
      margin: 4px auto 24px;
    }
  }

  .finish-action {
    margin: 20px;

    button {
      width: 100%;
    }
  }
`;
