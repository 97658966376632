import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { Button } from 'components/Button';
import { paths } from 'config/paths';
import { CollectionTeaserVideo } from 'interfaces/graphOrganization';
import { theme } from 'theme';

import { Wrapper } from './style';

interface Props {
  title: string;
  backgroundColor?: string;
  id: string;
  disabled?: boolean;
  teaserVideo?: CollectionTeaserVideo;
  teaserDescription?: string;
  currentlyPlaying?: string | null;
  handleSetPlaying?: (id: string) => void;
  onClick?: () => void;
}

export const CollectionAssessment: React.FC<Props> = ({
  title,
  id,
  disabled = false,
  teaserVideo,
  teaserDescription,
  currentlyPlaying,
  handleSetPlaying,
  backgroundColor = theme.palette.white,
  onClick
}) => {
  const history = useHistory();
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleClick = () => {
    if (!disabled) {
      history.push(paths.safmedsCollections(id, ''));
      onClick?.();
    }
  };

  useEffect(() => {
    if (currentlyPlaying !== id) {
      if (videoRef.current) {
        videoRef.current.pause();
      }
    }
  }, [currentlyPlaying, id]);

  return (
    <Wrapper
      collapsed={!teaserDescription && !teaserVideo?.url}
      disabled={disabled}
      style={{ backgroundColor: backgroundColor }}
      data-testid='assessment'
    >
      <div className='assessment__head' onClick={handleClick}>
        <span className='title'>{title}</span>
        <Button
          text='View'
          onClick={handleClick}
          disabled={disabled}
          transparent
          paddingX={9}
          paddingY={3}
          data-testid='assessment-view'
        />
      </div>
      <div className='assessment__description'>
        {teaserDescription && (
          <p
            className='assessment__description--text'
            dangerouslySetInnerHTML={{
              __html: teaserDescription || ''
            }}
          ></p>
        )}
        {teaserVideo && (
          <p className='assessment__description--video'>
            <video
              onPlay={() => handleSetPlaying?.(id)}
              ref={videoRef}
              controls
              src={teaserVideo?.url}
            >
              <source
                src={teaserVideo?.url}
                type={teaserVideo?.mimeType}
                title={teaserVideo?.fileName}
              />
              Sorry, your browser doesn't support embedded videos.
            </video>
          </p>
        )}
      </div>
    </Wrapper>
  );
};
