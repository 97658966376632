import styled from 'styled-components';

export const Wrapper = styled.form`
  padding: 24px 32px 32px 32px;
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2),
    0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 20px;
  }

  .title {
    font-size: 24px;
    line-height: 32px;
  }

  .settings {
    padding: 16px;
    background-color: ${(props) => props.theme.palette['light-yellow']};
    margin-top: 8px;

    p {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      margin-bottom: 10px;
    }

    button {
      font-weight: 500;
      font-size: 13px;
      line-height: 22px;
      letter-spacing: 0.46px;
    }
  }

  .custom {
    margin-top: 18px;
    padding-top: 24px;
    border-top: 1px solid ${(props) => props.theme.palette.background};

    &__input {
      margin-top: 20px;
    }

    span {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
    }
  }

  .actions {
    display: flex;
    align-items: flex-end;

    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
      flex-direction: column;
      align-items: stretch;
    }

    button {
      &:first-child {
        margin-right: 16px;
        margin-top: 40px;

        @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
          margin: 20px 0;
        }
      }
    }
  }
`;
