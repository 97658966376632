import styled from 'styled-components';

export const Wrapper = styled.form`
  position: relative;
  max-height: 611px;
  width: 100%;
  max-width: 866px;
  border-radius: 4px;
  padding: 24px 80px 32px 80px;
  box-sizing: border-box;
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2),
    0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  overflow-y: auto;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 20px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    max-height: 710px;
  }
  .deck-card__popup {
    overflow: hidden;
    &__switcher {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: stretch;
      flex-wrap: wrap;
      margin-top: 32px;
    }
    &__switch {
      cursor: pointer;
      text-align: start;
      display: flex;
      align-items: center;
      background: rgba(255, 255, 255, 0.1);
      border: 2px solid #f3f3f3;
      border-radius: 12px;
      width: 100%;
      max-width: 49%;
      padding: 20px;
      box-sizing: border-box;
      transition: 0.2s;

      @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
        max-width: none;
        &:nth-child(2) {
          margin-top: 10px;
        }
      }
      @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        padding: 10px;
      }
    }
    &__switch--checked {
      background: rgba(42, 71, 177, 0.1);
      border: 2px solid #2a47b1;
    }
    &__switch--icon {
      margin-right: 16px;
      @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        width: 30px;
        height: 33px;
      }
    }
    &__switch--title {
      margin-top: 5px;
      font-size: 16px;
      font-weight: 500;
    }
    &__switch--subtitle {
      margin-top: 5px;
      font-size: 14px;
    }
    &__rail-box {
      width: 95%;
    }
    &__title {
      font-size: 24px;
      line-height: 32px;
      font-weight: normal;

      @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        font-size: 20px;
      }
    }
    &__cards {
      font-size: 14px;
      line-height: 20px;
      font-weight: normal;
    }
    &__err-cards {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 44px;
      background-color: #f7f7f7;
      width: 100%;
      border-radius: 4px;
      padding: 10px;
      box-sizing: border-box;
    }
    &__err-title {
      font-size: 20px;
      font-weight: 500;
      @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        font-size: 16px;
      }
    }
    &__err-text {
      margin-top: 10px;
      @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        font-size: 14px;
      }
    }
    &__duration {
      font-size: 20px;
      line-height: 32px;
      font-weight: bold;
      margin: 16px 0;
    }
    &__slider {
      .MuiSlider-mark {
        margin-top: 1px;
      }
      .MuiSlider-rail {
        width: 101%;
        height: 5px;
      }
      .MuiSlider-thumb {
        width: 20px;
        height: 20px;
        margin-top: -8px;
      }
      .MuiSlider-valueLabel {
        left: -50px;
        top: -250%;
        &::before {
          content: '';
          display: block;
          width: 0;
          height: 0;
          position: absolute;

          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
          border-right: 8px solid #757575;
          left: 45%;
          top: 90%;
          transform: rotate(270deg);
        }
        span:first-child {
          transform: none;
          border-radius: 4px;
          width: 108px;
          padding: 4px 12px;
          height: 30px;
          background: #757575;
          span:first-child {
            transform: none;
            width: 98px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: transparent;
            font-size: 14px;
            color: white;
          }
        }
      }
      .MuiSlider-track {
        height: 4px;
      }
    }

    &__boundaries {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 30%;

      button {
        margin-top: 40px;
        margin-right: 16px;
      }
    }
  }
`;
