import styled from 'styled-components';

export const Wrapper = styled.main<{ collapsed: boolean }>`
  .safmeds {
    // Collection header ---------
    &__upper-text {
      display: flex;
      flex-direction: row;
      height: 50px;
      align-items: center;
      margin-bottom: 16px;
      border-bottom: 1px solid ${(props) => props.theme.palette.background};
      h5 {
        @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
          font-size: 16px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0px;
        text-align: left;
        margin: 0;
      }
      .tag {
        padding: 3px 10px;
        height: 16px;
        margin: 0px 8px;
      }
    }

    &__back-button button {
      @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        font-size: 12px;
        padding: 2px 7px;
        svg {
          height: 10px;
          margin-right: 5px;
        }
      }
    }
    &__collapse {
      display: none;
      float: right;
      align-items: center;
      padding: 7px 2px;
      border-radius: 50%;

      border: none;

      @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
        display: block;
      }
    }
    &__collapse--arrow {
      cursor: pointer;
      transform: rotate(-90deg);
      transition: 0.3s;

      height: 9px;

      &:last-child {
        margin-left: -12px;
      }
    }

    // Collection content wrapper -------
    &__content-wrap {
      position: relative;
      display: flex;
      flex-direction: row-reverse;
      width: 100%;
      justify-content: space-between;
      @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
        flex-direction: row;
      }
    }

    // Collection sidebar
    &__sidebar {
      min-width: 30%;
      width: 30%;
      margin-right: 32px;
      transition: 0.3s;

      @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
        .safmeds__collapse--arrow {
          transform: rotate(-90deg);
        }
        background-color: white;

        position: absolute;
        top: -2px;
        z-index: 2;
        width: 100%;
        margin-right: 0%;
      }
    }
    @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
      &__sidebar--collapsed {
        min-width: 0;
        width: 0;

        .safmeds__collapse {
          position: ${(props) => (props.collapsed ? 'absolute' : 'initial')};
          width: ${(props) => (props.collapsed ? '32px' : 'auto')};
        }
        .safmeds__collapse--arrow {
          transform: rotate(90deg);
        }
        .safmeds__form,
        .safmeds__sections {
          min-width: 0%;
          width: 0%;
          overflow: hidden;
          display: none;

          padding-right: 0;
        }
      }
    }

    // Collection main content
    &__content {
      width: 100%;
      @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
        padding-left: ${(props) => (props.collapsed ? '0px' : '30px')};
      }
    }
    &__content--head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 5px;
      @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
        flex-wrap: wrap;
        flex-direction: column-reverse;
      }
    }
    &__content--header {
      font-style: normal;
      font-weight: 400;
      font-size: 34px;
      letter-spacing: 0.25px;

      @media (max-width: ${(props) => props.theme.breakpoints.xl}) {
        font-size: 27px;
      }
      @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
        width: 100%;
        font-size: 24px;
      }

      color: #254555;
    }
    &__content--buttons {
      display: flex;
      align-items: center;
      margin-left: 10px;
      min-height: 30px;

      .next {
        margin-left: 2px;
      }

      @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
        width: 100%;
        justify-content: end;
        margin-bottom: 10px;
        margin-left: 0;
        button {
          font-size: 10px;
          padding: 2px 5px;
        }
        svg {
          height: 10px;
        }
        .prev svg {
          margin-right: 5px;
        }
        .next svg {
          margin-left: 5px;
        }
      }
    }
    &__content--button {
      font-size: 13px;
      padding: 4px 10px;

      line-height: 22px;
    }
    &____content--body {
      width: 100%;
    }
    &__content--description {
      width: 100%;
      margin-top: 16px;
      font-weight: 400;
      font-size: 16px;
      line-height: 175%;
      margin-bottom: 10px;

      img {
        width: 49% !important;
        margin-left: 5px;
      }

      video {
        width: 100% !important;
        max-height: 212px !important;
      }

      @media (max-width: ${(props) => props.theme.breakpoints.md}) {
        img {
          width: 100% !important;
          margin-left: 0;
        }
      }
    }
    &__content--description-ellipsed {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    &__content--show-more {
      cursor: pointer;
      font-size: 13px;
      letter-spacing: 0.46px;
      text-transform: uppercase;

      color: #2a47b1;
    }
    &__content--decks {
      margin-top: 24px;
      display: grid;
      grid-gap: 20px;
      grid-template-columns: 49% 49%;
      gap: 20px;

      & > div {
        width: 100%;
      }

      .deck-card {
        width: 100%;
        height: 100%;
      }

      .deck-card__upperblock__text {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      @media (max-width: ${(props) => props.theme.breakpoints.xl}) {
        gap: 10px;
      }

      @media (max-width: ${(props) => props.theme.breakpoints.md}) {
        grid-template-columns: 100%;
      }
    }

    // Sections search -----------
    &__input {
      width: 100%;
    }
    &__label {
      display: flex;
      align-items: center;
    }
    &__label--text {
      font-family: 'Roboto';
      color: #92a2aa;
      letter-spacing: 0.15px;
      margin-left: 10px;
      font-weight: 400;
      font-size: 16px;
    }
    &__input-adornment {
      path {
        fill: gray !important;
      }
    }
    &__input fieldset {
      border-color: ${(props) => props.theme.palette.background};
    }
    &__input:hover fieldset {
      border-color: ${(props) => props.theme.palette.aquisition};
    }
    &__input--clear {
      fill: gray;
      padding: 5px;
    }
    &__sections {
      width: 100%;
      margin-top: 16px;

      height: 75vh;
      overflow-y: auto;
      padding: 2px;
      box-sizing: border-box;

      scrollbar-color: #92a2aa transparent;
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #92a2aa;
        border-radius: 4px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px #f3f3f3;
      }
    }
    &__section {
      margin-bottom: 8px;
    }
  }
`;
