import styled from 'styled-components';

export const Wrapper = styled.div`
  .bigTag {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 10px;
    height: 21px;
    border-radius: 16px;
    margin: 0px 8px;
    color: ${(props) => props.theme.palette.white};
    .bigTag__text {
      color: black;
      font-family: Roboto, sans-serif;
      font-weight: 400;
      font-size: 32px;
      line-height: 42px;
    }
  }
`;
