import styled from 'styled-components';

export const Wrap = styled.form`
  padding: 32px;
  font-family: 'Roboto';

  .modal {
    &__title {
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
    }
    &__name {
      font-weight: 400;
      font-size: 12px;
    }
    &__input {
      width: 100%;
    }
    &__input--counter {
      font-size: 12px;
      margin-left: 10px;

      &-error {
        color: #f44336;
      }
    }
    &__subtitle {
      font-weight: 400;
      font-size: 16px;
      margin-bottom: 16px;
    }
    &__radio {
      margin-bottom: 10px;
    }
    &__radio--label {
      display: flex;
      flex-direction: column;
    }
    &__radio--title {
      font-size: 16px;
      color: #254555;
    }
    &__radio--desc {
      font-size: 16px;
      color: #90a1a9;
    }
    &__date {
      width: 100%;
    }
    &__input--textarea {
      margin: 26px 0;
    }
    &__buttons {
      display: flex;
      button:first-child {
        margin-right: 16px;
      }
    }
  }
`;
