import styled from 'styled-components';

export const TRow = styled.tr<{ type: 'condition' | 'measurment' }>`
  border-top: 1px solid rgb(243, 243, 243);
  border-bottom: 1px solid rgb(243, 243, 243);
  background-color: ${({ type }) =>
    type === 'condition' ? '#f7fafa' : '#fafeff'};
  height: 52px;
  width: 100%;
  border-radius: 0px;

  td {
    padding: 5px 0px 5px 15px;
  }
`;
