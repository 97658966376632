import { Button } from '@material-ui/core';
import styled from 'styled-components';

export const ChartAdd = styled(Button)`
  box-shadow: 6px 6px 5px rgba(0, 0, 0, 0.04);

  svg {
    fill: rgba(37, 69, 85, 0.3);

    width: 44px;
    height: 44px;

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
      width: 50px;
      height: 50px;
    }
  }
`;
