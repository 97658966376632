import { Questions } from 'interfaces/questions';
import { Report } from 'interfaces/result';

import { APIService } from './api';

type StartExamAPIParams = {
  overallQuestionsNumber: number;
  testTime: number;
  certificationSlug: string;
};

type AddAnswerAPIParams = {
  attemptId: string;
  answer: {
    questionId: string;
    answerId: string;
    isFlagged: boolean;
    time: number;
  };
};

type ReportGenerationAPIParams = {
  timeInfo: {
    started: string;
    completed: string;
  };
  attemptId: string;
};

export class Exam extends APIService {
  isMockedApi: boolean;

  constructor(isMockedApi: boolean = false) {
    super();
    this.isMockedApi = isMockedApi;
  }

  startExamAPI(body: StartExamAPIParams): Promise<Questions> {
    if (this.isMockedApi) {
      return new Promise((resolve) =>
        setTimeout(resolve, 1000, require('../mocks/qestions.json'))
      );
    }

    return this.post<StartExamAPIParams, Questions>(
      '/api/questions/randomizer',
      body
    );
  }

  addAnswerAPI(body: AddAnswerAPIParams): Promise<boolean> {
    if (this.isMockedApi) {
      return new Promise((resolve) => setTimeout(resolve, 500, true));
    }

    return this.post<AddAnswerAPIParams, boolean>(
      '/api/questions/addAnswerInfo',
      body
    );
  }

  reportGenerationAPI(body: ReportGenerationAPIParams): Promise<Report> {
    if (this.isMockedApi) {
      return new Promise((resolve) =>
        setTimeout(resolve, 2000, require('../mocks/result.json'))
      );
    }

    return this.post<ReportGenerationAPIParams, Report>(
      '/api/questions/reportGeneration',
      body
    );
  }
}
