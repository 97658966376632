export const noop = (): void => {};

export function secondsToDate(seconds: number) {
  if (typeof seconds !== 'number') {
    throw new Error('Input must be a number');
  }

  const date = new Date();

  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor((seconds % 3600) % 60);

  date.setHours(h, m, s);

  return date; // JavaScript uses milliseconds for Date constructor
}

export function secondsToHMS(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

export const logAppVersion = () => {
  const { version } = require('../../package.json');
  console.log(`App version: ${version}`);
};
