import { CollectionAssessment } from './Collection';
import { ExamAssessment } from './Exam';

type AssessmentType = {
  Collection: typeof CollectionAssessment;
  Exam: typeof ExamAssessment;
};

export const Assessment: AssessmentType = {
  Collection: CollectionAssessment,
  Exam: ExamAssessment
};
