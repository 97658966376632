import { FC } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { TextField } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
  // TimePicker
} from '@material-ui/pickers';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
  PostMeasurementAPIParams,
  PutMeasurementAPIParams
} from 'api/measurement';
import { Button } from 'components/Button';
import { useFormik } from 'formik';
import { secondsToDate } from 'helpers/utils';
import { Measurement } from 'interfaces/measurment';
import moment from 'moment';
import * as yup from 'yup';

import 'moment/locale/en-gb';

import { ReactComponent as Circle } from '../../assets/circle.svg';
import { ReactComponent as Cross } from '../../assets/cross.svg';
import { ReactComponent as Rect } from '../../assets/rect.svg';
import { Wrap } from './style';

type Props = {
  chartId: string;
  measurement?: Measurement;
  onUpdate: (measurment: PutMeasurementAPIParams) => void;
  onSubmit: (measurement: PostMeasurementAPIParams) => void;
  handleClose: () => void;
};

const schema = yup.object().shape({
  correct: yup.number().integer().required('Correct is a requiried field'),
  incorrect: yup.number().integer().required('Incorrect is a requiried field'),
  custom: yup.number().integer().nullable(),
  countingTime: yup
    .number()
    .required('Counting time is a requiried field')
    .min(1, 'Counting time must be greater than or equal to 1')
    .typeError('Counting time must be a time'),
  dateTime: yup.string().required()
});

export const MeasurementForm: FC<Props> = ({
  chartId,
  measurement,
  onUpdate,
  onSubmit,
  handleClose
}) => {
  const formik = useFormik<
    Omit<PostMeasurementAPIParams | PutMeasurementAPIParams, 'chartId'>
  >({
    initialValues: {
      correct: measurement?.correct as number,
      incorrect: measurement?.incorrect as number,
      custom: (measurement?.custom as number) || null,
      countingTime: measurement?.countingTime || 0,
      dateTime: measurement?.dateTime
        ? new Date(measurement?.dateTime).toString()
        : new Date().toString()
    },
    onSubmit: (values) =>
      measurement ? onUpdate(values) : onSubmit({ ...values, chartId }),
    validationSchema: schema
  });

  function HHMMSSToSeconds(input: any) {
    const [hh, mm, ss] = input.map(Number);
    return hh * 3600 + mm * 60 + ss;
  }

  const handleChange = (value: any) => {
    const arr = moment(value).format('HH:mm:ss').split(':');
    formik.setFieldValue('countingTime', HHMMSSToSeconds(arr));
  };

  const handleSetDate = (
    type: 'date' | 'time',
    value: Date | null | string
  ) => {
    if (!value) return;
    if (type === 'date') {
      formik.setFieldValue('dateTime', new Date(value));
      return;
    }

    const time = new Date(value as Date).getTime();
    const date = new Date(formik.values.dateTime);
    date.setTime(time);

    formik.setFieldValue('dateTime', date);
  };

  const handleInputKeyDown = (event: any) => {
    if (event.key === 'Backspace') {
      setTimeout(() => {
        const [h, m, s] = event.target.value
          .replace(/hh/g, '00')
          .replace(/mm/g, '00')
          .replace(/ss/g, '00')
          .split(':');
        const date = new Date().setHours(h, m, s);
        handleChange(new Date(date));
      }, 0);
    }
  };

  return (
    <Wrap onSubmit={formik.handleSubmit} className='form'>
      <div className='form__field'>
        <label htmlFor='correct' className='form__field--label'>
          <Circle className='form__field--icon' />
          Correct
        </label>
        <TextField
          error={formik.touched.correct && !!formik.errors.correct}
          type='number'
          name='correct'
          onChange={formik.handleChange}
          value={formik.values.correct}
          variant='standard'
          size='small'
          className='form__field--input'
          placeholder='0'
          inputProps={{ 'data-testid': 'correct', name: 'correct' }}
          helperText={formik.touched.correct && formik.errors.correct}
        />
      </div>
      <div className='form__field'>
        <label htmlFor='incorrect' className='form__field--label'>
          <Cross className='form__field--icon' />
          Incorrect
        </label>
        <TextField
          error={formik.touched.incorrect && !!formik.errors.incorrect}
          type='number'
          name='incorrect'
          onChange={formik.handleChange}
          value={formik.values.incorrect}
          variant='standard'
          size='small'
          className='form__field--input'
          placeholder='0'
          inputProps={{ 'data-testid': 'incorrect', name: 'incorrect' }}
          helperText={formik.touched.incorrect && formik.errors.incorrect}
        />
      </div>
      <div className='form__field'>
        <label htmlFor='incorrect' className='form__field--label'>
          <Rect className='form__field--icon' />
          Custom
        </label>
        <TextField
          type='number'
          name='custom'
          onChange={formik.handleChange}
          value={formik.values.custom}
          variant='standard'
          size='small'
          className='form__field--input'
          placeholder='0'
          inputProps={{ 'data-testid': 'custom', name: 'custom' }}
        />
      </div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className='form__box'>
          <label
            htmlFor='incorrect'
            className='form__field--label form__box--label'
          >
            Counting time
          </label>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <TimePicker
              className='form__box--input'
              ampm={false}
              ampmInClock={false}
              views={['hours', 'minutes', 'seconds']}
              onChange={(value) => handleChange(value)}
              value={moment(secondsToDate(formik.values.countingTime))}
              slotProps={{
                textField: {
                  'data-testid': 'countingTime',
                  error:
                    formik.touched.countingTime && !!formik.errors.countingTime,
                  helperText:
                    formik.touched.countingTime && formik.errors.countingTime,
                  className: 'form__box--input',
                  variant: 'standard',
                  onKeyDown: handleInputKeyDown
                } as any
              }}
            />
          </LocalizationProvider>
        </div>
        <div className='form__field--datetime'>
          <label htmlFor='dateTime' className='form__field--label'>
            Date and Time
          </label>
          <div className='form__field--pickers'>
            <KeyboardDatePicker
              value={formik.values.dateTime}
              onChange={(value) => handleSetDate('date', value)}
              variant='inline'
              format='MMM dd yyyy'
              autoOk
              color='primary'
              className='form__field--date'
              inputProps={{ 'data-testid': 'dateTime' }}
            />
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <TimePicker
                className='form__field--time'
                ampm
                ampmInClock
                views={['hours', 'minutes']}
                onChange={(value) => handleSetDate('time', value as any)}
                defaultValue={moment(formik.values.dateTime)}
                slotProps={{
                  textField: {
                    className: 'form__field--time',
                    variant: 'standard'
                  }
                }}
              />
            </LocalizationProvider>
          </div>
        </div>
      </MuiPickersUtilsProvider>
      <div className='form__buttons'>
        <Button text={!!measurement ? 'Update' : 'Add'} type='submit' />
        <Button text='Cancel' transparent onClick={handleClose} />
      </div>
    </Wrap>
  );
};
