import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { paths } from 'config/paths';

import { Wrapper } from './style';

const safmedsPages = [
  paths.safmeds(),
  paths.safmedsExam(),
  paths.safmedsCollections(),
  paths.deckReport(),
  paths.reviewReport(),
  paths.studyReport(),
  paths.chart()
];
const examPages = [
  paths.mockExam(),
  paths.result(),
  paths.quiz(),
  paths.exam(),
  paths.examPage()
];

export const Navigation: React.FC = () => {
  const isSafmedPage = useRouteMatch(safmedsPages);
  const isExamPage = useRouteMatch(examPages);
  const certificationSlug = localStorage.getItem('certificationSlug') as string;

  return (
    <Wrapper>
      <NavLink
        to={paths.safmeds()}
        id='safmeds'
        activeClassName='selected'
        isActive={() => !!isSafmedPage}
        {...{ name: 'safmeds' }}
      >
        <div className='navigation-link'>SAFMEDS</div>
      </NavLink>

      <NavLink
        to={paths.mockExam(certificationSlug)}
        id='mockexam'
        activeClassName='selected'
        isActive={() => !!isExamPage}
        {...{ name: 'mockexam' }}
      >
        <div className='navigation-link'> Mock exam </div>
      </NavLink>
    </Wrapper>
  );
};
