type TrendLine = {
  color: string;
  data: {
    xMin: string | number;
    xMax: string | number;
    yMin: string | number;
    yMax: string | number;
  };
};

export const getTrendLine = ({ color, data }: TrendLine) => ({
  type: 'line',
  adjustScaleRange: true,
  drawTime: 'afterDatasetsDraw',
  xSclaeID: 'x',
  ySclaeID: 'y',
  borderColor: color,
  ...data
});
