import { FC } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { PostConditionAPIParams, PutConditionAPIParams } from 'api/condition';
import { Button } from 'components/Button';
import { useFormik } from 'formik';
import { Condition } from 'interfaces/condition';
import * as yup from 'yup';

import { Wrap } from './style';

type Props = {
  chartId: string;
  chartName: string;
  condition?: Condition;
  onUpdate: (params: PutConditionAPIParams) => void;
  onSubmit: (params: PostConditionAPIParams) => void;
  onCancel: () => void;
};

const schema = yup.object().shape({
  title: yup
    .string()
    .max(100, 'You have exceeded the maximum number of character in this field')
    .required('Title is required'),
  type: yup
    .string()
    .required()
    .oneOf(['slice', 'intervention'], 'Invalid type'),
  description: yup
    .string()
    .max(
      1000,
      'You have exceeded the maximum number of character in this field'
    ),
  dateTime: yup.string().required()
});

export const ConditionForm: FC<Props> = ({
  chartId,
  chartName,
  condition,
  onUpdate,
  onSubmit,
  onCancel
}) => {
  const formik = useFormik<Omit<PostConditionAPIParams, 'chartId'>>({
    initialValues: {
      title: condition?.title || '',
      type: condition?.type || 'slice',
      description: condition?.description || '',
      dateTime:
        condition?.dateTime ||
        new Date(new Date().setHours(0, 0, 0, 0)).toString()
    },
    onSubmit: (values) =>
      !!condition ? onUpdate(values) : onSubmit({ ...values, chartId }),
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: schema
  });

  return (
    <Wrap onSubmit={formik.handleSubmit} className='modal'>
      <h1 className='modal__title'>Condition</h1>
      <span className='modal__name'>{chartName}</span>
      <TextField
        className='modal__input'
        label='Title'
        name='title'
        variant='standard'
        value={formik.values.title}
        onChange={formik.handleChange}
        error={!!formik.errors.title}
        helperText={formik.errors.title}
        multiline
        InputProps={{
          endAdornment: (
            <span
              className={`modal__input--counter ${
                formik.errors.title ? 'modal__input--counter-error' : ''
              }`}
            >{`${formik.values.title.length}/100`}</span>
          )
        }}
      />
      <h3 className='modal__subtitle'>Condition type</h3>
      <RadioGroup
        name='type'
        value={formik.values.type}
        onChange={formik.handleChange}
      >
        <FormControlLabel
          control={<Radio color='primary' />}
          className='modal__radio'
          value='slice'
          label={
            <div className='modal__radio--label'>
              <span className='modal__radio--title'>Slice</span>
              <span className='modal__radio--desc'>
                Use this to indicate changes in decks/courses. This will create
                a break in the trend line.
              </span>
            </div>
          }
        />
        <FormControlLabel
          control={<Radio color='primary' />}
          className='modal__radio'
          value='intervention'
          label={
            <div className='modal__radio--label'>
              <span className='modal__radio--title'>Intervention</span>
              <span className='modal__radio--desc'>
                Use this when changing an independent variable. This will create
                a break in the trend line.
              </span>
            </div>
          }
        />
      </RadioGroup>
      <h3 className='modal__subtitle'>Date</h3>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          value={new Date(formik.values.dateTime)}
          error={!!formik.errors.dateTime}
          helperText={formik.errors.dateTime}
          onChange={(value) => formik.setFieldValue('dateTime', value)}
          variant='inline'
          format='MMM dd yyyy'
          autoOk
          color='primary'
          className='modal__date'
        />
      </MuiPickersUtilsProvider>
      <TextField
        className='modal__input modal__input--textarea'
        placeholder='Description (optional)'
        name='description'
        variant='standard'
        value={formik.values.description}
        onChange={formik.handleChange}
        error={!!formik.errors.description}
        helperText={formik.errors.description}
        multiline
        InputProps={{
          endAdornment: (
            <span
              className={`modal__input--counter ${
                formik.errors.description ? 'modal__input--counter-error' : ''
              }`}
            >{`${formik.values.description.length}/1000`}</span>
          )
        }}
      />
      <div className='modal__buttons'>
        <Button type='submit' text={!!condition ? 'UPDATE' : 'ADD'} />
        <Button text='CANCEL' transparent onClick={onCancel} />
      </div>
    </Wrap>
  );
};
