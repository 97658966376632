function getAvg(arr: number[]) {
  const total = arr.reduce((acc, c) => acc + c, 0);
  return total / arr.length;
}

function getSum(arr: number[]) {
  return arr.reduce((acc, c) => acc + c, 0);
}

function getYMax(
  slope: number,
  yMin: number,
  xMin: number,
  xMax: number
): number {
  // Get the difference between the max and min X values
  const deltaX = xMax - xMin;
  // Slope to degree to radians to tangent
  const degree = Math.atan(slope) * (180 / Math.PI);
  const slopeRadians = degree * (Math.PI / 180);
  const slopeTangent = Math.tan(slopeRadians);
  const deltaY = deltaX * slopeTangent;

  return yMin + deltaY;
}

export function getTrendData(
  data: Array<{ x: number; y: number }>,
  xMin: number,
  xMax: number,
  xMinPosition: number,
  xMaxPosition: number
): { yMin: number; yMax: number; xMin: number; xMax: number } {
  const xData = data.map((value) => value.x);
  const yData = data.map((value) => value.y);

  const yMinValue = Math.min(...yData);
  const yMaxValue = Math.max(...yData);

  // average of X values and Y values
  const xMean = getAvg(xData);
  const yMean = getAvg(yData);

  // Subtract X or Y mean from corresponding axis value
  const xMinusxMean = xData.map((val) => val - xMean);
  const yMinusyMean = yData.map((val) => val - yMean);

  const xMinusxMeanSq = xMinusxMean.map((val) => Math.pow(val, 2));

  const xy = [];
  for (let x = 0; x < data.length; x++) {
    xy.push(xMinusxMean[x] * yMinusyMean[x]);
  }

  const xySum = getSum(xy);

  // b1 is the slope
  const slope = xySum / getSum(xMinusxMeanSq);
  // b0 is the start of the slope on the Y axis
  const yMin = yMean - slope * xMean;

  const yMax = getYMax(slope, yMin, xMin, xMax);

  const getVlaue = (value: number): number => {
    if (value < yMinValue) {
      return yMinValue;
    } else if (value > yMaxValue) {
      return yMaxValue;
    } else {
      return value;
    }
  };

  if (yMin < 0 || yMax < 0) {
    if (yMin < yMax) {
      const min = yMin + Math.abs(yMin);
      const max = yMax + Math.abs(yMin);
      return {
        yMin: getVlaue(min),
        yMax: getVlaue(max),
        xMin: xMinPosition,
        xMax: xMaxPosition
      };
    } else {
      const min = yMin + Math.abs(yMax);
      const max = yMax + Math.abs(yMax);
      return {
        yMin: getVlaue(min),
        yMax: getVlaue(max),
        xMin: xMinPosition,
        xMax: xMaxPosition
      };
    }
  } else {
    return {
      yMin,
      yMax,
      xMin: xMinPosition,
      xMax: xMaxPosition
    };
  }
}
