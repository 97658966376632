import { ChartEvent } from 'chart.js';
import { EventContext } from 'chartjs-plugin-annotation';

type Condition = {
  positionX: string | number;
  color: string;
  label?: string;
  width?: number;
  onClick?: (
    ctx: EventContext,
    event: ChartEvent & { chart: { canvas: HTMLCanvasElement } },
    id: string
  ) => void;
};

export const getCondiotion = (data: Condition) => {
  const { positionX, color, label, width = 2, onClick } = data;

  return {
    type: 'line',
    scaleID: 'x',
    borderWidth: width,
    borderColor: color,
    borderDash: [],
    borderDashOffset: 0,
    value: positionX,
    click: onClick,
    label: {
      enabled: true,
      rotation: 'auto',
      backgroundColor: 'transparent',
      color: '#000',
      position: 'start',
      xAdjust: 10,
      content: label
    }
  };
};
